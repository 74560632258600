<div class="photo">
  <div class="photo__metadata row">
    <div class="flex">
       {{ photo.file.name }}
    </div>
    <div class="photo__date">
       {{ date }}
    </div>
  </div>
  <div class="photo__preview">
    <img
      [src]="photo.preview"
      [alt]="photo.file.name"
      class="photo__image"
      (click)="preview = true"
    >
  </div>
  <div class="photo__actions">
    <div
      *ngFor="let item of actionItems"
      class="photo__action"
      [ngClass]="{'photo__action--disbaled': progress > 0}"
      (click)="doAction(item.action)"
    >
      <app-icon [icon]="item.icon"></app-icon>
      <div class="photo__action-label">{{ item.label | translate }}</div>
    </div>
    <div
      *ngIf="photo.success !== ''"
      class="photo__action photo__action--success"
      [ngClass]="{'class': true}"
      (click)="successAction()"
    >
      <app-icon icon="check"></app-icon>
      <div class="photo__action-label">{{ photo.success | translate }}</div>
    </div>
    <div
      *ngIf="photo.fail !== ''"
      class="photo__action photo__action--fail"
      (click)="failAction()"
    >
      <app-icon icon="cancel"></app-icon>
      <div class="photo__action-label">{{ photo.fail | translate }}</div>
    </div>

    <div *ngIf="progress > 0" class="photo__upload">
      <div class="photo__progress" [ngStyle]="{'width': progress + '%'}"></div>
      <div class="photo__upload-text">{{ 'editor.uploading' | translate }}</div>
    </div>

  </div>
</div>

<div *ngIf="preview" class="photo__overlay" (click)="preview = false">
  <div class="photo__overlay-background"></div>
  <div class="photo__overlay-content">
    <div class="photo__overlay-image" [ngStyle]="{'background-image': 'url(' + photo.preview + ')'}"></div>
  </div>
</div>
