<div class="avatar">
  <mat-label>{{ 'profileImage.label' | translate }}</mat-label>
  <div
    #dropboxElement
    class="avatar__dropbox"
    [ngClass]="{'avatar__dropbox--highlight': highlighted}"
    [ngStyle]="{'background-image': image}"
    (mouseleave)="highlighted = false"
  >
    <label class="avatar__label" for="avatar-input">
      <app-icon class="avatar__icon" [ngClass]="{'avatar__icon--show': !image}" icon="account-circle-outline"></app-icon>
    </label>
    <input
      type="file"
      id="avatar-input"
      class="avatar__input"
      [(ngModel)]="fileInput"
      (change)="onFileChange($event)"
    />
  </div>
</div>
