import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Role } from '../models/security.model';
import { StateModel } from '../models/store.model';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  private _role: Role = Role.NONE;

    constructor(
        private _router: Router,
        private _store: Store,
    ) {
      this._store.select((state: StateModel) => state.security.role).subscribe((role: Role) => {
        this._role = role;
      });
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      const roles: Role[] = route.data.roles || [];
      if (roles.includes(this._role)) {
        return true;
      } else {
        this._router.navigate(['overview']);
        return false;
      }
    }
}
