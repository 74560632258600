import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SearchQuery, Camera, ResponseStatus } from 'src/app/models/administration.model';
import { UpdateCameras } from 'src/app/store/administration/administration.actions';
import { Helper } from 'src/app/utilities/helper';
import { ApiService } from '../api.service';
import { ConfigService } from '../config.service';
import { SnackbarService } from '../snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class HardwareService {

  private _url = '';

  constructor(
    private _store: Store,
    private _http: HttpClient,
    private _snackbarService: SnackbarService,
    private _configService: ConfigService,
    private _apiService: ApiService,
  ) {
    this._configService.getApi().subscribe((data: any) => {
      this._url = `${data.register.origin}${data.register.port}${data.register.version}/hardware`;
    });
  }

  /* CAMERAS */

  public getCameras(query: SearchQuery): void {
    const request = this._http.get(`${this._url}/listcamera?${query.params()}`);

    request.pipe(
      catchError(() => {
        this._snackbarService.showError();
        return of(null);
      })
    ).subscribe((results: any) => {
      if (results) {
        const items: Camera[] = [];
        let total = 0;
        items.push(...results.cameras.map((data: any) => {
          const camera = new Camera();
          Helper.mergeData(camera, data);
          return camera;
        }));
        total = results.count;
        this._store.dispatch(new UpdateCameras({
          items,
          total
        }));
      }
    });
  }

  public addCamera(camera: Camera): Observable<ResponseStatus> {
    const request = this._http.post(`${this._url}/camera`, camera);

    return this._apiService.handleResponse(request);
  }

  public updateCamera(update: any): Observable<ResponseStatus> {
    const request = this._http.patch(`${this._url}/camera`, update);
    return this._apiService.handleResponse(request);
  }

  public removeCamera(camera: Camera): Observable<ResponseStatus> {
    const request = this._http.delete(`${this._url}/camera?cameraId=${camera.cameraId}&cameraManufacturer=${camera.cameraManufacturer}`);
    return this._apiService.handleResponse(request);
  }

}
