import { Component, Input } from '@angular/core';
import { Studio } from 'src/app/models/administration.model';
import { AutocompleteConfig } from 'src/app/models/autocomplete.model';
import { FileUploadConfig } from 'src/app/models/file-upload.model';
import { InputConfig } from 'src/app/models/input.model';
import { SelectConfig } from 'src/app/models/select.model';

@Component({
  selector: 'app-data-row',
  templateUrl: './data-row.component.html',
  styleUrls: ['./data-row.component.scss']
})
export class DataRowComponent {

  @Input()
  public configs?: (InputConfig | AutocompleteConfig | SelectConfig)[];
  @Input()
  public studio?: [Studio, number];
  @Input()
  public files?: FileUploadConfig;

  constructor() { }

  public getStudioValues(): string {
    if (this.studio) {
      const values: string[] = Object.values(this.studio[0]);
      return `${values[0]}, ${values[1]}, ${values[2]} ${values[3]}`;
    }
    return '';
  }

  public getFileNames(): string[] {
    return this.files?.control?.value ? this.files.control.value.map((value: File) => value.name) : [];
  }

}
