<div class="handout">
  <div *ngIf="handout.token" class="handout__layout">
    <div class="row handout__header">
      <div class="handout__headline flex">
        <app-headline icon="portrait">{{ 'handout.headline' | translate }}</app-headline>
      </div>
      <div>
        <div class="handout__studio">{{ handout.studio }}</div>
        <div class="handout__date">{{ handout.date }}</div>
      </div>
    </div>
    <div class="handout__description">
      {{ 'handout.description' | translate:[handout.expiryDate] }}
    </div>
    <div class="handout__qr">
      <img class="handout__bg" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEXv8fJb0OmLAAAACklEQVQI12NgAAAAAgAB4iG8MwAAAABJRU5ErkJggg==" alt="">
      <div class="handout__qr-data row">
        <qrcode elementType="img" class="handout__qr-code" [qrdata]="qrCode" [width]="144" errorCorrectionLevel="M"></qrcode>
        <div class="handout__token flex">
          {{ handout.secretKey }}
        </div>
      </div>
    </div>
    <div class="handout__url">
      <div class="handout__photo">
        <img [src]="preview" alt="Ihr Foto" />
      </div>
      {{ url }}
    </div>
  </div>
</div>
