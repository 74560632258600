import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { Employee, TableAction, TableActionType, TableConfig, UserStatus } from 'src/app/models/administration.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { StateModel } from 'src/app/models/store.model';
import { PhotostudioService } from 'src/app/services/register/photostudio.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-employees-view',
  templateUrl: './employees-view.component.html',
  styleUrls: ['./employees-view.component.scss']
})
export class EmployeesViewComponent extends GarbageCollectorComponent implements OnInit {

  public searchConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.searchEmployee.label');
  private _employees: Employee[] = [];
  public tableConfig: TableConfig = new TableConfig();
  public showLegend = false;

  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _router: Router,
    private _photostudioService: PhotostudioService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this._store.select((state: StateModel) => state.administration.employees).subscribe((employees: Employee[]) => {
        this._employees = employees.map((employee: Employee) => {
          return {
            ...employee,
            status: employee.status === UserStatus.DEACTIVATED ? UserStatus.BLACKLISTED : employee.status
          };
        });
        this.tableConfig.header = ['firstName', 'lastName', 'email'];
        this.tableConfig.items = this._employees;
        this.tableConfig.actions = [TableActionType.DELETE];
        this.tableConfig = {...this.tableConfig};
        this.showLegend = this.tableConfig.items.find(employee => employee.status === UserStatus.BLACKLISTED) !== undefined;
      })
    );
    this._photostudioService.getEmployees();
  }

  public inviteEmployee(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.ADD_EMPLOYEE,
      'dialog.inviteEmployee.headline',
      ['dialog.inviteEmployee.description'],
      'button.invite'
    );
    this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });
  }

  private _removeEmployee(employee: Employee): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CONFIRM,
      'dialog.removeEmployee.headline',
      ['dialog.removeEmployee.description', employee.email]
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (result === true) {
            return this._photostudioService.removeEmployee(employee);
          }
          return of(result);
        })
      ).subscribe((success) => {
        if (success === false) {
          this._removeFailed(employee);
        } else {
          this._photostudioService.getEmployees();
        }
      })
    );
  }

  private _removeFailed(employee: Employee): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.FAIL,
      'dialog.removeEmployee.headline',
      ['dialog.removeEmployee.failed', `${employee.firstName} ${employee.lastName}`]
    );
    this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });
  }

  public configChanged(config: TableConfig): void {
    console.log(config);
  }

  public onAction(action: TableAction): void {
    const employee: Employee | undefined = this._employees.find((e: Employee) => e.email === action.id);
    switch (action.name) {
      case TableActionType.DELETE:
        if (employee) {
          this._removeEmployee(employee);
        }
        break;
      case TableActionType.EDIT:
        this._router.navigate(['employee', action.id]);
        break;

      default:
        break;
    }
  }

}
