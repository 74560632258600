import { FormControl } from '@angular/forms';

export class CheckboxConfig {
  public control: FormControl;
  public label: string;

  constructor(label: string) {
    this.control = new FormControl(false);
    this.label = label;
  }
}
