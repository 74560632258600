import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PhotoState, PhotoUpload } from 'src/app/models/file-upload.model';
import { PhotosService } from 'src/app/services/photos.service';
import { Canvas } from 'src/app/utilities/canvas';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-photo-set',
  templateUrl: './photo-set.component.html',
  styleUrls: ['./photo-set.component.scss']
})
export class PhotoSetComponent extends GarbageCollectorComponent implements OnInit {

  @ViewChild('canvas')
  public canvasRef!: ElementRef;

  private _dpi = 300;
  private _res = this._dpi / 25.4;
  private _pWidth = 152;
  private _pHeight = 102;

  public selectedSet = 0;
  public photos: PhotoUpload[] = [];
  public set4: number[] = [0, 0, 0, 0, 0];
  public set6: number[] = [0, 0, 0, 0, 0, 0];

  constructor(
    public dialogRef: MatDialogRef<PhotoSetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PhotoUpload,
    private _photosService: PhotosService,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.data.state === PhotoState.ARCHIVED) {
      this.addSubscription(
        this._photosService.getArchive().subscribe((photos: PhotoUpload[]) => {
          this.photos = photos;
          const index = photos.indexOf(this.data);
          this.set4 = this.set4.map(x => index || 0);
          this.set6 = this.set6.map(x => index || 0);
        })
      );
    } else {
      this.addSubscription(
        this._photosService.getPhotos().subscribe((photos: PhotoUpload[]) => {
          this.photos = photos;
          const index = photos.indexOf(this.data);
          this.set4 = this.set4.map(x => index || 0);
          this.set6 = this.set6.map(x => index || 0);
        })
      );
    }
  }

  public changeSet(set: number): void {
    this.selectedSet = set;
  }

  public changePhoto(e: any, set: number[], index: number): void {
    const current = set[index];
    if (e.deltaX && Math.abs(e.deltaX) > 75) {
      if (e.deltaX > 0) {
        set[index] = current + 1 < this.photos.length ? current + 1 : 0;
      } else {
        set[index] = current - 1 >= 0 ? current - 1 : this.photos.length - 1;
      }
    }
  }


  public nextPhoto(e: any, set: number[], index: number): void {
    const current = set[index];
    if (e.pointerType === 'mouse' && e.type === 'tap') {
      set[index] = current + 1 < this.photos.length ? current + 1 : 0;
    }
  }

  public print(): void {
    const width = Math.round(this._res * this._pWidth);
    const height = Math.round(this._res * this._pHeight);

    const smallWidth = Math.round(this._res * 35);
    const smallHeight = Math.round(this._res * 45);
    const bigWidth = Math.round(this._res * 70);
    const bigHeight = Math.round(this._res * this._pHeight);

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      if (this.selectedSet === 0) {
        const c1 = Canvas.createPhotoTile(this.photos[this.set4[0]].image, smallWidth, smallHeight);
        const c2 = Canvas.createPhotoTile(this.photos[this.set4[1]].image, smallWidth, smallHeight);
        const c3 = Canvas.createPhotoTile(this.photos[this.set4[2]].image, smallWidth, smallHeight);
        const c4 = Canvas.createPhotoTile(this.photos[this.set4[3]].image, smallWidth, smallHeight);
        const c5 = Canvas.createPhotoTile(this.photos[this.set4[4]].image, bigWidth, bigHeight);
        ctx.drawImage(c1, Math.round(this._res * 3), Math.round(this._res * 4));
        ctx.drawImage(c2, Math.round(this._res * 3), Math.round(this._res * 53));
        ctx.drawImage(c3, Math.round(this._res * 41), Math.round(this._res * 4));
        ctx.drawImage(c4, Math.round(this._res * 41), Math.round(this._res * 53));
        ctx.drawImage(c5, Math.round(this._res * 79), Math.round(this._res * 0));
      }
      if (this.selectedSet === 1) {
        const c1 = Canvas.createPhotoTile(this.photos[this.set6[0]].image, smallWidth, smallHeight);
        const c2 = Canvas.createPhotoTile(this.photos[this.set6[1]].image, smallWidth, smallHeight);
        const c3 = Canvas.createPhotoTile(this.photos[this.set6[2]].image, smallWidth, smallHeight);
        const c4 = Canvas.createPhotoTile(this.photos[this.set6[3]].image, smallWidth, smallHeight);
        const c5 = Canvas.createPhotoTile(this.photos[this.set6[4]].image, smallWidth, smallHeight);
        const c6 = Canvas.createPhotoTile(this.photos[this.set6[5]].image, smallWidth, smallHeight);
        ctx.drawImage(c1, Math.round(this._res * 19.5), Math.round(this._res * 4));
        ctx.drawImage(c2, Math.round(this._res * 19.5), Math.round(this._res * 53));
        ctx.drawImage(c3, Math.round(this._res * 58.5), Math.round(this._res * 4));
        ctx.drawImage(c4, Math.round(this._res * 58.5), Math.round(this._res * 53));
        ctx.drawImage(c5, Math.round(this._res * 97.5), Math.round(this._res * 4));
        ctx.drawImage(c6, Math.round(this._res * 97.5), Math.round(this._res * 53));
      }

      const oHideFrame = document.createElement('iframe');
      if (oHideFrame) {
        oHideFrame.onload = () => {
          if (oHideFrame.contentWindow) {
            oHideFrame.contentWindow.onbeforeunload = () => {
              document.body.removeChild(oHideFrame);
            };
            oHideFrame.contentWindow.onafterprint = () => {
              document.body.removeChild(oHideFrame);
            };
            oHideFrame.contentWindow.focus();
            oHideFrame.contentWindow.print();
          }

        };
        oHideFrame.style.position = 'fixed';
        oHideFrame.style.right = '0';
        oHideFrame.style.bottom = '0';
        oHideFrame.style.width = '0';
        oHideFrame.style.height = '0';
        oHideFrame.style.border = '0';
        oHideFrame.srcdoc = `<img style="width: ${this._pWidth}mm; height: ${this._pHeight}mm;" src="${canvas.toDataURL()}">`;
        document.body.appendChild(oHideFrame);
      }
    }
  }

}
