import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { ResponseStatus } from 'src/app/models/administration.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { AuthService } from 'src/app/services/auth.service';
import { PhotosService } from 'src/app/services/photos.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';
import { Helper } from 'src/app/utilities/helper';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.scss']
})
export class LoginViewComponent extends GarbageCollectorComponent implements OnInit {

  public locked = false;
  public loginForm: FormGroup = this._fb.group({});
  public emailConfig: InputConfig = new InputConfig(InputType.EMAIL, 'input.email.label');
  public passwordConfig: InputConfig = new InputConfig(InputType.PASSWORD, 'input.password.label');
  public secret = '';
  private tries = 0;

  constructor(
    private _fb: FormBuilder,
    private _authService: AuthService,
    private _router: Router,
    private _photosService: PhotosService,
    private _dialog: MatDialog,
    private _cookieService: CookieService,
    private _route: ActivatedRoute
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loginForm.addControl('email', this.emailConfig.control);
    this.loginForm.addControl('password', this.passwordConfig.control);
    this.addSubscription(
      this.loginForm.valueChanges.subscribe(() => {
        this.locked = this.loginForm.invalid;
      })
    );
    this._photosService.clear();
    if (this._cookieService.check('secret')) {
      this.secret = this._cookieService.get('secret');
      this.enterPin();
    }
    if (this._route.snapshot.queryParams.joined !== undefined) {
      this._successfulJoined();
    }
  }

  public login(): void {
    this.loginForm.updateValueAndValidity();
    if (this.loginForm.valid) {
      this._doLogin();
    }
  }

  private _doLogin(): void {
    this.addSubscription(
      this._authService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe((success: boolean) => {
        if (success) {
          this.loginForm.disable();
          this._showAuthenticator();
        } else {
          this.loginForm.setErrors({invalid: true});
        }
      })
    );
  }

  private _showAuthenticator(error: string = ''): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CODE,
      'dialog.authenticate.headline',
      ['dialog.authenticate.description2'],
    );
    dialogData.values = { error };
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
      disableClose: true
    });

    this.addSubscription(
      dialogRef.afterClosed().pipe(
        switchMap((code: string) => {
          if (code) {
            return this._authService.getAuthentication(code);
          } else {
            return of(ResponseStatus.NONE);
          }
        })
      ).subscribe((response: ResponseStatus) => {
        if (response !== ResponseStatus.NONE) {
          if (response === ResponseStatus.SUCCESS) {
            this._cookieService.delete('secret');
            this._router.navigate(['overview']);
          } else {
            this._showAuthenticator('dialog.authenticate.failed');
          }
        } else {
          this.loginForm.enable();
        }
      })
    );
  }

  public toReset(): void {
    this._router.navigate(['password', 'forgotten']);
  }

  public register(path: string = ''): void {
    if (path) {
      this._router.navigate(['register', path]);
    } else {
      this._router.navigate(['register']);
    }
  }

  public enterPin(error: string = ''): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CODE,
      'dialog.enterPin.headline',
      ['dialog.enterPin.description'],
    );
    dialogData.values = {
      codeSize: 4,
      error,
      icon: 'lock_open'
    };
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData
    });

    this.addSubscription(
      dialogRef.afterClosed().subscribe((code: string) => {
        const token = Helper.decrypt(code, this.secret);
        if (code) {
          if (token !== '') {
            sessionStorage.setItem('refresh_token', token);
            this.addSubscription(
              this._authService.refreshToken().subscribe(success => {
                this._authService.restoreAuth();
                this._router.navigate(['overview']);
              })
            );
          } else {
            this.tries++;
            if (this.tries >= 3) {
              this._cookieService.delete('secret');
              this.secret = '';
              this._tooManyTries();
            } else {
              this.enterPin('dialog.enterPin.failed');
            }
          }
        }
      })
    );
  }

  private _tooManyTries(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.FAIL,
      'dialog.enterPin.headline',
      ['dialog.enterPin.tooManyTries']
    );
    this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });
  }

  private _successfulJoined(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.SUCCESS,
      'dialog.joined.headline',
      ['dialog.joined.description']
    );
    this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });
  }

}
