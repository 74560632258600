import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Handout, ResponseStatus } from 'src/app/models/administration.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { PhotoAction, PhotoState, PhotoUpload, PhotoUploadResponse } from 'src/app/models/file-upload.model';
import { AccountModel, StateModel } from 'src/app/models/store.model';
import { EditorService } from 'src/app/services/editor/editor.service';
import { PhotosService } from 'src/app/services/photos.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';
import { DialogComponent } from '../dialog/dialog.component';
import { PhotoEditorComponent } from '../photo-editor/photo-editor.component';
import { PhotoSetComponent } from '../photo-set/photo-set.component';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent extends GarbageCollectorComponent implements OnInit {

  @Input()
  public photo!: PhotoUpload;

  public date = '';
  public preview = false;
  public progress = 0;
  public actionItems: any[] = [];
  private _actions: PhotoAction[] = [];

  constructor(
    private _photosService: PhotosService,
    private _editorService: EditorService,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _store: Store,
    private _router: Router,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.photo) {
      this.date = moment(this.photo.file.lastModified).format('DD.MM.YYYY HH:mm');
      switch (this.photo.state) {
        case PhotoState.IMPORTED:
          this._actions = [PhotoAction.REMOVE, PhotoAction.UPLOAD];
          break;
        case PhotoState.INVALID:
          this._actions = [PhotoAction.REMOVE];
          break;
        case PhotoState.EDITABLE:
          this._actions = [PhotoAction.EDIT];
          if (Math.floor((this.photo.height / this.photo.width) * 1000) === Math.floor((4.5 / 3.5) * 1000)) {
            this._actions.push(PhotoAction.CONTINUE);
          }
          this.photo.success = '';
          break;
        case PhotoState.EDITED:
          this._actions = [PhotoAction.CHECK, PhotoAction.STORE];
          break;
        case PhotoState.REJECTED:
          this._actions = [PhotoAction.CHECK, PhotoAction.FORCE_SAVE];
          this.photo.fail = 'editor.status.rejected';
          break;
        case PhotoState.APPROVED:
          this._actions = [PhotoAction.SET, PhotoAction.HANDOUT];
          break;
        case PhotoState.ARCHIVED:
          this._actions = [PhotoAction.RESUME];
          if (this.photo.verification.token) {
            this._actions.push(...[PhotoAction.SET, PhotoAction.HANDOUT]);
          }
          break;
        default:
          break;
      }
      this.actionItems = this._actions.map(action => {
        return this._getAction(action);
      });
    }
  }

  private _getAction(action: PhotoAction): any {
    let item: any = {};
    switch (action) {
      case PhotoAction.REMOVE:
        item = {
          label: 'editor.action.remove',
          icon: 'delete',
          action
        };
        break;
      case PhotoAction.UPLOAD:
        item = {
          label: 'editor.action.upload',
          icon: 'upload',
          action
        };
        break;
      case PhotoAction.EDIT:
        item = {
          label: 'editor.action.edit',
          icon: 'edit',
          action
        };
        break;
      case PhotoAction.CHECK:
        item = {
          label: 'editor.action.check',
          icon: 'flaky',
          action
        };
        break;
      case PhotoAction.STORE:
        item = {
          label: 'editor.action.store',
          icon: 'cloud_upload',
          action
        };
        break;
      case PhotoAction.HANDOUT:
        item = {
          label: 'editor.action.handout',
          icon: 'qr_code',
          action
        };
        break;
      case PhotoAction.SET:
        item = {
          label: 'editor.action.set',
          icon: 'auto_awesome_mosaic',
          action
        };
        break;
      case PhotoAction.FORCE_SAVE:
        item = {
          label: 'editor.action.forceSave',
          icon: 'publish',
          action
        };
        break;
      case PhotoAction.RESUME:
        item = {
          label: 'editor.action.resume',
          icon: 'edit',
          action
        };
        break;
      case PhotoAction.CONTINUE:
        item = {
          label: 'editor.action.continue',
          icon: 'thumb_up_alt',
          action
        };
        break;
      default:
        break;
    }
    return item;
  }

  public doAction(action: PhotoAction): void {
    switch (action) {
      case PhotoAction.REMOVE:
        this._remove();
        break;
      case PhotoAction.UPLOAD:
        this._upload();
        break;
      case PhotoAction.EDIT:
        this._edit();
        break;
      case PhotoAction.CHECK:
        this._check();
        break;
      case PhotoAction.STORE:
        this._sendToCognitec();
        break;
      case PhotoAction.HANDOUT:
        this._getHandout();
        break;
      case PhotoAction.SET:
        this._createSet();
        break;
      case PhotoAction.FORCE_SAVE:
        this._forceSave();
        break;
      case PhotoAction.RESUME:
        this._resumeProcessing();
        break;
      case PhotoAction.CONTINUE:
        this._continue();
        break;
      default:
        break;
    }
  }

  public successAction(): void {
    if (this.photo.state === PhotoState.UPLOADED) {
      this._photosService.nextStepTrigger.next();
    }
  }
  public failAction(): void {
    if (this.photo.fail === 'editor.status.rejected') {
      this._showValidationErrors();
    }
  }

  private _upload(): void {
    this.progress = 1;
    this.addSubscription(
      this._editorService.uploadPhotos([this.photo.file]).subscribe({
        next: (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / (event.total || 1)  * 100);
              break;
            case HttpEventType.Response:
              if (event.body) {
                const response: PhotoUploadResponse[] = event.body;
                const uploaded = response.find(success => {
                  return this.photo.file.name.replace(/,/g, ' ') === success.fileName.replace(/,/g, ' ');
                });
                if (uploaded) {
                  this.photo.id = uploaded.imageId,
                  this.photo.state = PhotoState.UPLOADED;
                  this.photo.success = 'editor.status.uploaded';
                  this.photo.fail = '';
                  this.photo.uploaded = true;
                }
              }
              this.progress = 0;
              this._photosService.updatePhoto(this.photo);
              break;
            default:
              break;
          }
        },
        error: (error: any) => {
          this.photo.success = '';
          this.photo.fail = 'editor.status.fail';
          this.actionItems = this.actionItems.filter(item => item.action !== PhotoAction.UPLOAD);
          this.progress = 0;
          if (error instanceof HttpErrorResponse) {
            if (error.status === 422) {
              this.photo.fail = 'editor.status.noSignature';
              this.photo.state = PhotoState.INVALID;
            } else {
              this._snackbarService.showError(['snackbar.uploadError']);
            }
          } else {
            this._snackbarService.showError(['snackbar.uploadError']);
          }
          this._photosService.updatePhoto(this.photo);
        }
      })
    );

  }

  private _remove(): void {
    this._photosService.removePhoto(this.photo);
  }

  private _edit(): void {
    this._dialog.open(PhotoEditorComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      panelClass: 'photo-editor-dialog',
      data: this.photo
    });
  }

  private _showValidationErrors(): void {

    const errors: boolean = this.photo.validationErrors.length > 0;
    const noFace: boolean = this.photo.verification.hasNoFace;
    const multipleFaces: boolean = this.photo.verification.hasMultipleFaces;

    let dialogData: DialogConfig = new DialogConfig(
      DialogType.FAIL,
      'dialog.validationErrors.headline',
      ['dialog.validationErrors.failed']
    );

    if (noFace) {
      dialogData = new DialogConfig(
        DialogType.FAIL,
        'dialog.validationErrors.headline',
        ['dialog.validationErrors.noFace']
      );
    } else if (multipleFaces) {
      dialogData = new DialogConfig(
        DialogType.FAIL,
        'dialog.validationErrors.headline',
        ['dialog.validationErrors.multipleFaces', `${this.photo.verification.faceCount}`]
      );
    } else if (errors) {
      dialogData = new DialogConfig(
        DialogType.VALIDATION_ERRORS,
        'dialog.validationErrors.headline',
        ['dialog.validationErrors.description'],
      );
    }


    dialogData.values = this.photo.validationErrors;
    this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

  }

  private _check(): void {
    let dialogData: DialogConfig = new DialogConfig(
      DialogType.CONFIRM,
      'dialog.photoCheck.headline',
      ['dialog.photoCheck.description'],
    );
    dialogData.values = {
      icon: 'payments'
    };
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData
    });
    this.addSubscription(
      dialogRef.afterClosed().pipe(
        switchMap((result) => {
          if (result === true) {
            this._editorService.processingMessage.next('editor.step.storage.overlay');
            return this._editorService.validatePhoto(this.photo);
          }
          return of(ResponseStatus.NONE);
        })
      ).subscribe((response: ResponseStatus) => {
        this._editorService.processingMessage.next('');
        if (response === ResponseStatus.SUCCESS) {
          if (this.photo.verification.cognitecApproved) {
            dialogData = new DialogConfig(
              DialogType.SUCCESS,
              'dialog.validationSuccess.headline',
              ['dialog.validationSuccess.description'],
            );
            dialogData.values = this.photo.validationResult;
            this._dialog.open(DialogComponent, {
              ...dialogData.layout(),
              data: dialogData,
            });
          } else {
            this._showValidationErrors();
          }
        }
      })
    );

  }

  private _sendToCognitec(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CONFIRM,
      'dialog.cognitecUpload.headline',
      ['dialog.cognitecUpload.description'],
    );
    dialogData.values = {
      icon: 'payments'
    };
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().pipe(
        switchMap((result) => {
          if (result === true) {
            this._editorService.processingMessage.next('editor.step.storage.overlay');
            return this._editorService.sendToCognitec(this.photo);
          }
          return of(ResponseStatus.NONE);
        })
      ).subscribe((response: ResponseStatus) => {
        if (response !== ResponseStatus.NONE) {
          this._photosService.updatePhoto(this.photo);
          if (this.photo.state === PhotoState.APPROVED) {
            this._photosService.nextStepTrigger.next();
          } else {
            this._showValidationErrors();
          }
        }
        this._editorService.processingMessage.next('');
      })
    );
  }

  private _forceSave(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.FORCE_SAVE,
      'dialog.forceSave.headline',
      ['dialog.forceSave.description'],
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().pipe(
        switchMap((result) => {
          if (result === true) {
            this._editorService.processingMessage.next('editor.step.storage.forceSaveOverlay');
            return this._editorService.sendToCognitec(this.photo, true);
          }
          return of(ResponseStatus.NONE);
        })
      ).subscribe((response: ResponseStatus) => {
        if (response !== ResponseStatus.NONE) {
          this._photosService.updatePhoto(this.photo);
          if (this.photo.state === PhotoState.APPROVED) {
            this._photosService.nextStepTrigger.next();
          }
        }
        this._editorService.processingMessage.next('');
      })
    );
  }

  private _createSet(): void {
    this._dialog.open(PhotoSetComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      panelClass: 'photo-editor-dialog',
      data: this.photo
    });
  }

  private _getHandout(): void {
    const account: AccountModel = this._store.selectSnapshot((state: StateModel) => state.account);
    const handout = new Handout();
    handout.studio = `${account.company} ${account.companyLegalForm}`;
    handout.date = moment(this.photo.verification.createdDateTime).format('DD.MM.YYYY');
    handout.expiryDate = moment(this.photo.verification.expiryDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
    handout.token = this.photo.verification.token;
    handout.secretKey = this.photo.verification.secretKey;
    handout.url = this.photo.verification.url;
    handout.id = this.photo.id;
    handout.validationResult = this.photo.validationResult;
    handout.preview = this.photo.preview;
    localStorage.setItem('handout', JSON.stringify(handout));
    const url = `${location.origin}/handout`;

    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private _continue(): void {
    this.photo.state = PhotoState.EDITED;
    this.photo.fail = '';
    this._photosService.updatePhoto(this.photo);
    this._photosService.nextStepTrigger.next();
  }

  private _resumeProcessing(): void {
    this._photosService.addPhoto({
      ...this.photo,
      state: PhotoState.EDITABLE
    });
    this._router.navigate(['editor', this.photo.id]);
  }

}
