import { Component, Input, OnInit } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { LocalizationService } from '@tallence/localization';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { IconService } from 'src/app/services/icon.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent extends GarbageCollectorComponent implements OnInit {

  @Input()
  public config: InputConfig = new InputConfig(InputType.NONE, '');
  @Input()
  public required?: string;
  @Input()
  public readonly?: string;
  @Input()
  public pattern?: string;
  @Input()
  public whitelist?: string;
  @Input()
  public autocomplete = 'off';
  @Input()
  public preIcon?: string;
  @Input()
  public postIcon?: string;

  public hidePassword = true;
  public isPassword = false;

  constructor(
    private _localizationService: LocalizationService,
    private _iconService: IconService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.isPassword = this.config.type === InputType.PASSWORD;
    if (this.whitelist !== undefined) {
      this.addSubscription(
        this.config.control.valueChanges.subscribe((value: string) => {
          const match: any = `${value}`.match(new RegExp(`${this.whitelist}`));
          if (value !== '' && match?.[0] !== value) {
            this.config.control.setValue(match?.[0] || '');
          }
        })
      );
    }
    this._setValidation();
  }

  private _setValidation(): void {
    const validators: ValidatorFn[] = [];

    switch (this.config.type) {
      case InputType.EMAIL:
        validators.push(Validators.pattern('^[a-zA-Z0-9]+[._+\\-a-zA-Z0-9]*@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$'));
        break;
      case InputType.PHONE:
        validators.push(Validators.pattern('^[+0]{1}[1-9]{1}\\d{2,14}$'));
        this.config.hint = 'input.phone.validation'
        break;
      default:
        break;
    }

    if (this.required !== undefined) {
      validators.push(Validators.required);
    }
    if (this.pattern !== undefined) {
      validators.push(Validators.pattern(this.pattern));
    }
    this.config.control.setValidators(validators);
  }

  public getErrorMessage(): string {
    if (this.config.control.hasError('required')) {
      if (this.required) {
        return this._localizationService.getTranslation(this.required);
      } else {
        return this._localizationService.getTranslation('input.error.required', [this.config.label]);
      }
    }
    if (this.config.control.hasError('passwordRules')) {
      return this._localizationService.getTranslation('input.error.password.rules');
    }
    if (this.config.control.hasError('passwordRepeat')) {
      return this._localizationService.getTranslation('input.error.password.repeat');
    }
    if (this.config.control.hasError('incorrectPassword')) {
      return this._localizationService.getTranslation('input.error.password.incorrect');
    }
    return '';
  }

  public toggleVisibility(): void {
    this.hidePassword = !this.hidePassword;
    this.config.type = this.hidePassword ? InputType.PASSWORD : InputType.TEXT;
  }

  public isOwnIcon(icon: string): boolean {
    return this._iconService.isOwnIcon(icon);
  }

}
