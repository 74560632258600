import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input()
  public icon?: string;
  @Input()
  public disabled?: string | boolean;
  @Input()
  public primary?: string;
  @Input()
  public secondary?: string;
  @Input()
  public simple?: string;
  @Input()
  public light?: string;

  @Output()
  public action: EventEmitter<void> = new EventEmitter();

  constructor() { }

  public doAction(): void {
    if (this.disabled === undefined || this.disabled === false) {
      this.action.emit();
    }
  }

}
