<div class="paginator">
  <div class="paginator__left">
    <app-select class="flex" [config]="templateConfig"></app-select>
  </div>
  <div class="paginator__middle">
    <button (click)="changePage(1)" [disabled]="paginator.current === 1 || paginator.disabled" mat-icon-button>
      <mat-icon>first_page</mat-icon>
    </button>
    <button (click)="changePage(paginator.current - 1)" [disabled]="paginator.current === 1 || paginator.disabled" mat-icon-button>
      <mat-icon>chevron_left</mat-icon>
    </button>

    <div class="paginator__pages">
      {{ paginator.current }} - {{ paginator.last }}
    </div>

    <button (click)="changePage(paginator.current + 1)" [disabled]="paginator.current === paginator.last || paginator.disabled" mat-icon-button>
      <mat-icon>chevron_right</mat-icon>
    </button>
    <button (click)="changePage(paginator.last)" [disabled]="paginator.current === paginator.last || paginator.disabled" mat-icon-button>
      <mat-icon>last_page</mat-icon>
    </button>
  </div>
  <div class="paginator__right"></div>
</div>
