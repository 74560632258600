import { Application, Employee, HardwareProvider, Photographer } from 'src/app/models/administration.model';
import { CameraItems, CompanyItems, StudioItems, UserItems } from 'src/app/models/store.model';

const name = 'Administration';
const update = `${name} Update`;
const add = `${name} Add`;
const remove = `${name} Remove`;

export class ResetAdministration {
  public static readonly type: string = `${name} Reset`;
  constructor() { }
}

export class UpdateEmployees {
  public static readonly type: string = `${update} Employees`;
  constructor(public employees: Employee[]) { }
}

export class UpdateHardwareProviders {
  public static readonly type: string = `${update} Hardware Providers`;
  constructor(public hardwareProviders: HardwareProvider[]) { }
}

export class UpdateHardwareProvider {
  public static readonly type: string = `${update} Hardware Provider`;
  constructor(public hardwareProvider: HardwareProvider) { }
}

export class AddHardwareProvider {
  public static readonly type: string = `${add} Hardware Provider`;
  constructor(public hardwareProvider: HardwareProvider) { }
}

export class RemoveHardwareProvider {
  public static readonly type: string = `${remove} Hardware Provider`;
  constructor(public hardwareProvider: HardwareProvider) { }
}

export class UpdatePhotographer {
  public static readonly type: string = `${update} Photographer`;
  constructor(public photographer: Photographer[]) { }
}

export class UpdateCameras {
  public static readonly type: string = `${add} Cameras`;
  constructor(public cameras: CameraItems) { }
}

export class UpdateApplications {
  public static readonly type: string = `${update} Applications`;
  constructor(public applications: Application[]) { }
}

export class UpdateUsers {
  public static readonly type: string = `${update} Users`;
  constructor(public users: UserItems) { }
}

export class UpdateCompanies {
  public static readonly type: string = `${update} Companies`;
  constructor(public companies: CompanyItems) { }
}

export class UpdateStudios {
  public static readonly type: string = `${update} Studios`;
  constructor(public studios: StudioItems) { }
}
