<app-content>
  <div class="companies-view">
    <div class="row">
      <app-headline class="flex" icon="home_work">{{ 'companies.headline' | translate }}</app-headline>
      <div *ngIf="isHardwareProvider" class="headline-action">
        <app-button (action)="searchAddCompany()" icon="home-search-outline">{{ 'button.searchAddCompany' | translate }}</app-button>
      </div>
    </div>
    <app-card>
      <div class="row">
        <app-search class="flex" (action)="search($event)" [config]="searchConfig"></app-search>
      </div>
      <app-table
        *ngIf="tableConfig.items.length > 0"
        paginator="true"
        sortable="true"
        [config]="tableConfig"
        (changed)="configChanged($event)"
        (action)="onAction($event)"
      ></app-table>
      <div *ngIf="tableConfig.items.length === 0">
        {{ 'companies.empty' | translate }}
      </div>
    </app-card>
  </div>
</app-content>
