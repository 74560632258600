<div class="overview row">
  <app-welcome></app-welcome>
  <div class="flex">
    <app-content>
      <app-card *ngIf="cards.length">
        <div *ngIf="applicationCount > 0" class="overview__applications" (click)="goTo('applications')">
          <mat-icon>notifications</mat-icon>
          <span *ngIf="applicationCount === 1" >{{ 'overview.applications.one' | translate }}</span>
          <span *ngIf="applicationCount > 1" >{{ 'overview.applications.many' | translate: [applicationCount] }}</span>
        </div>
        <div class="overview__cards">
          <div class="overview__card" *ngFor="let card of cards" (click)="goTo(card.route)">
            <app-icon class="overview__card-icon" [icon]="card.icon"></app-icon>
            <div class="overview__card-headline">{{'overview.card.' + card.label + '.headline' | translate }}</div>
            <div class="overview__card-description">{{'overview.card.' + card.label + '.description' | translate }}</div>
          </div>
        </div>
      </app-card>
    </app-content>
  </div>
</div>


