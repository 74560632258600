import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { ResponseStatus } from 'src/app/models/administration.model';
import { AutocompleteConfig } from 'src/app/models/autocomplete.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { Role } from 'src/app/models/security.model';
import { AccountModel, StateModel } from 'src/app/models/store.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/register/user.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UpdateAccount } from 'src/app/store/account/account.actions';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';
import { Helper } from 'src/app/utilities/helper';

@Component({
  selector: 'app-account-view',
  templateUrl: './account-view.component.html',
  styleUrls: ['./account-view.component.scss']
})
export class AccountViewComponent extends GarbageCollectorComponent implements OnInit {

  private _account?: AccountModel;
  public accountForm: FormGroup = this._fb.group({});
  private _image: FormControl = this._fb.control({});
  public salutationConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.salutation.label');
  public firstNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.firstName.label');
  public lastNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.lastName.label');
  public nationalityConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.nationality.label');
  public idCardNumberConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.idCardNumber.label');
  public companyConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.company.label');
  public companyLegalFormConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.companyLegalForm.label');
  public emailConfig: InputConfig = new InputConfig(InputType.EMAIL, 'input.email.label');
  public websiteConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.website.label');
  public phone1Config: InputConfig = new InputConfig(InputType.PHONE, 'input.phone.label');
  public phone2Config: InputConfig = new InputConfig(InputType.PHONE, 'input.phone2.label');
  public passwordConfig: InputConfig = new InputConfig(InputType.PASSWORD, 'input.passwordOld.label', '', 'input.passwordOld.hint');
  public addressConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.address.label');
  public zipConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.zip.label');
  public locationConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.location.label');

  public role?: Role;
  public profileLoaded = true;

  public newPassword = '';
  public passwordLocked = true;
  public profileLocked = true;
  public passwordError = '';
  public newPasswordInput = true;

  constructor(
    private _fb: FormBuilder,
    private _store: Store,
    private _dialog: MatDialog,
    private _router: Router,
    private _authService: AuthService,
    private _snackbarService: SnackbarService,
    private _userService: UserService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.role = this._store.selectSnapshot((state: StateModel) => state.security.role);

    // this.accountForm.addControl('image', this._image);
    this.accountForm.addControl('salutation', this.salutationConfig.control);
    this.accountForm.addControl('firstName', this.firstNameConfig.control);
    this.accountForm.addControl('lastName', this.lastNameConfig.control);
    this.accountForm.addControl('email', this.emailConfig.control);
    this.accountForm.addControl('phone1', this.phone1Config.control);

    if (this.role === Role.PHOTOGRAPHER || this.role === Role.EMPLOYEE) {
      this.phone1Config.label = 'input.phone1.label';
      this.accountForm.addControl('nationality', this.nationalityConfig.control);
      this.accountForm.addControl('phone2', this.phone2Config.control);
    }

    if (this.role === Role.PHOTOGRAPHER) {
      this.accountForm.addControl('idCardNumber', this.idCardNumberConfig.control);
      this.accountForm.addControl('company', this.companyConfig.control);
      this.accountForm.addControl('companyLegalForm', this.companyLegalFormConfig.control);
      this.accountForm.addControl('website', this.websiteConfig.control);
    }

    if (this.role === Role.PHOTOGRAPHER || this.role === Role.HARDWARE_PROVIDER) {
      this.accountForm.addControl('company', this.companyConfig.control);
      this.accountForm.addControl('companyLegalForm', this.companyLegalFormConfig.control);
    }

    if (this.role === Role.HARDWARE_PROVIDER) {
      this.accountForm.addControl('address', this.addressConfig.control);
      this.accountForm.addControl('zip', this.zipConfig.control);
      this.accountForm.addControl('location', this.locationConfig.control);
    }

    this.addSubscription(
      this._store.select((state: StateModel) => state.account).subscribe((account: AccountModel | any) => {
        if (account) {
          Object.keys(this.accountForm.value).forEach(key => {
            this.accountForm.controls[key].setValue(account[key]);
          });
          this._image.setValue(account.image);
          this._account = {...account};
          this.profileLoaded = account.loaded;
          this.profileLocked = true;
        }
      })
    );
    this.addSubscription(
      this.passwordConfig.control.valueChanges.subscribe((value: string) => {
        this.passwordLocked = (value || '').trim() === '';
        this.passwordError = '';
      })
    );
    this.addSubscription(
      this.accountForm.valueChanges.subscribe(() => {
        this.profileLocked = this.accountForm.invalid;
      })
    );
  }

  public changeProfile(): void {
    const update: any = Helper.diffData(this._account || {}, this.accountForm.value);
    this.addSubscription(
      this._userService.updateAccount(update).subscribe((response: ResponseStatus) => {
        if (response === ResponseStatus.SUCCESS) {
          this._snackbarService.showSuccess();
          this._store.dispatch(new UpdateAccount({
            ...new AccountModel(),
            ...this.accountForm.value,
            loaded: true
          }));
        }
      })
    );
  }

  public getNewPassword(password: string): void {
    this.newPassword = password;
    this.passwordError = '';
  }

  public changePassword(): void {
    this.addSubscription(
      this._userService.updatePassword(this.passwordConfig.control.value, this.newPassword).subscribe((response: ResponseStatus) => {
        if (response === ResponseStatus.SUCCESS) {
          this.passwordConfig.control.reset();
          this.newPasswordInput = false;
          const dialogData: DialogConfig = new DialogConfig(
            DialogType.SUCCESS,
            'dialog.passwordChanged.headline',
            ['dialog.passwordChanged.description'],
          );
          this._dialog.open(DialogComponent, {
            ...dialogData.layout(),
            data: dialogData,
          });
          setTimeout(() => {
            this.newPasswordInput = true;
          }, 100);
        }
        if (response === ResponseStatus.UNAUTHORIZED) {
          this.passwordError = 'account.password.error.auth';
        }
        if (response === ResponseStatus.ERROR) {
          this.passwordError = 'account.password.error.internal';
        }
      })
    );
  }

  public avatarChanged(image: string): void {
    this._image.setValue(image);
  }

  public deleteAccount(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.DELETE_ACCOUNT,
      'dialog.deleteAccount.headline',
      ['dialog.deleteAccount.description'],
      'button.deleteAccount'
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this._authService.logout();
          this._router.navigate(['login']);
        }
      })
    );
  }

  public reloadProfile(): void {
    this._userService.getAccount();
  }

}
