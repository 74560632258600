import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { Application, ApplicationReply, Attachment, ResponseStatus, TableAction, TableActionType, TableConfig } from 'src/app/models/administration.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { StateModel } from 'src/app/models/store.model';
import { Helper } from 'src/app/utilities/helper';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';
import { LocalizationService } from '@tallence/localization';
import { UserService } from 'src/app/services/register/user.service';

@Component({
  selector: 'app-application-view',
  templateUrl: './application-view.component.html',
  styleUrls: ['./application-view.component.scss']
})
export class ApplicationViewComponent extends GarbageCollectorComponent implements OnInit {

  public application?: Application;
  public _applicationForm: FormGroup = this._fb.group({});
  public name = '';

  public salutationConfig: InputConfig = new InputConfig(InputType.TEXT, 'autocomplete.salutation.label');
  public roleConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.role.label');
  public firstNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.firstName.label');
  public lastNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.lastName.label');
  public nationalityConfig: InputConfig = new InputConfig(InputType.TEXT, 'autocomplete.nationality.label');
  public idCardNumberConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.idCardNumber.label');
  public companyConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.company.label');
  public companyLegalFormConfig: InputConfig = new InputConfig(InputType.TEXT, 'autocomplete.companyLegalForm.label');
  public emailConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.email.label');
  public websiteConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.website.label');
  public phone1Config: InputConfig = new InputConfig(InputType.TEXT, 'input.phone1.label');
  public phone2Config: InputConfig = new InputConfig(InputType.TEXT, 'input.phone2.label');
  public memberConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.member.label');

  public attachmentsConfig: TableConfig = new TableConfig();
  public studiosConfig: TableConfig = new TableConfig();

  constructor(
    private _store: Store,
    private _route: ActivatedRoute,
    private _router: Router,
    private _fb: FormBuilder,
    private _dialog: MatDialog,
    private _localizationService: LocalizationService,
    private _userService: UserService
  ) {
    super();
  }

  public ngOnInit(): void {
    const email: string = this._route.snapshot.params.email;

    this._applicationForm.addControl('salutation', this.salutationConfig.control);
    this._applicationForm.addControl('role', this.roleConfig.control);
    this._applicationForm.addControl('firstName', this.firstNameConfig.control);
    this._applicationForm.addControl('lastName', this.lastNameConfig.control);
    this._applicationForm.addControl('nationality', this.nationalityConfig.control);
    this._applicationForm.addControl('email', this.emailConfig.control);
    this._applicationForm.addControl('phone1', this.phone1Config.control);
    this._applicationForm.addControl('phone2', this.phone2Config.control);
    this._applicationForm.addControl('idCardNumber', this.idCardNumberConfig.control);
    this._applicationForm.addControl('company', this.companyConfig.control);
    this._applicationForm.addControl('companyLegalForm', this.companyLegalFormConfig.control);
    this._applicationForm.addControl('website', this.websiteConfig.control);
    this._applicationForm.addControl('member', this.memberConfig.control);

    this.addSubscription(
      this._store.select((state: StateModel) => state.administration.applications).subscribe((applications: Application[]) => {
        this.application = applications.find((application: Application) => application.email === email);
        if (this.application === undefined) {
          this._router.navigate(['applications']);
        } else {
          Helper.mergeForm(this._applicationForm, {
            ...this.application,
            role: this._localizationService.getTranslation(`role.${this.application.role}`)
          });
          Helper.fillEmpty(this._applicationForm, '-/-');
          this.name = `${this.application.firstName} ${this.application.lastName}`;

          this.attachmentsConfig.header = ['attachment'];
          this.attachmentsConfig.items = this.application.documents.map((attachment) => {
            return {
              attachment: attachment.name,
              id: attachment.url
            };
          });
          this.attachmentsConfig.actions = [TableActionType.DOWNLAOD];
          this.attachmentsConfig.click = TableActionType.DOWNLAOD;
          this.attachmentsConfig.actionId = 'id';

          this.studiosConfig.items = this.application.studios;
          this.studiosConfig.header = ['studioName', 'licenseNumber', 'address', 'zip', 'location'];
        }
      })
    );
  }

  public onAction(action: TableAction): void {
    switch (action.name) {
      case TableActionType.DOWNLAOD:
        const tableItem = this.attachmentsConfig.items.find((item) => item.id === action.id);
        if (tableItem) {
          const attachment: Attachment = {
            name: tableItem.attachment,
            url: tableItem.id
          };
          this._userService.getAttachment(attachment);
        }
        break;
      default:
        break;
    }
  }

  public reject(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.REJECT_APPLICATION,
      'dialog.rejectApplication.headline',
      ['dialog.rejectApplication.description'],
      'button.reject'
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().subscribe(reason => {
        if (reason !== undefined && reason !== '') {
          this._reply(false, reason);
        }
      })
    );
  }

  public accept(): void {
    this._reply(true);
  }

  private _reply(granted: boolean, reason: string = ''): void {
    if (this.application) {
      const reply = new ApplicationReply(
        this.application.role,
        this.application.email,
        this.application.salutation,
        this.application.firstName,
        this.application.lastName,
        (this.companyConfig.control.value || '').replace('-/-', ''),
        granted,
        reason
      );
      this.addSubscription(
        this._userService.replyApplication(reply).subscribe((response: ResponseStatus) => {
          if (response === ResponseStatus.SUCCESS) {
            this.back();
          }
        })
      );
    }
  }

  public back(): void {
    this._router.navigate(['applications']);
  }

}
