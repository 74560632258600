export abstract class Canvas {

  public static checkBoundaries(cw: number, ch: number, rotation: number, x: number, y: number, scale: number = 1): boolean {
    const canvas = document.createElement('canvas');
    canvas.width = cw;
    canvas.height = ch;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(rotation * (Math.PI / 180));
      ctx.scale(scale, scale);
      ctx.rect(-canvas.width / 2, -canvas.height / 2, canvas.width, canvas.height);
      ctx.fill();
      return ctx.getImageData(x, y, 1, 1).data[3] !== 0;
    }
    return false;
  }

  public static drawShadow(canvas: HTMLCanvasElement, overlay: HTMLElement): void {
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.clearRect(overlay.offsetLeft, overlay.offsetTop, overlay.clientWidth, overlay.clientHeight);
    }
  }

  public static getPreview(image: HTMLImageElement): string {
    const canvas = document.createElement('canvas');
    canvas.width = (image.width / image.height) * window.innerHeight;
    canvas.height = window.innerHeight;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      return canvas.toDataURL('image/jpeg', 0.95);
    }
    return '';
  }

  public static createPhotoTile(image: HTMLImageElement, width: number, height: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      if ((canvas.width / canvas.height ) > (image.width / image.height)) {
        const newHeight = (canvas.height / canvas.width) * image.width;
        const offset = (image.height - newHeight) / 2;
        ctx.drawImage(image, 0, offset, image.width, newHeight, 0, 0, canvas.width, canvas.height);
      } else {
        const newWidth = (image.height / canvas.height) * canvas.width;
        const offset = (image.width - newWidth) / 2;
        ctx.drawImage(image, offset, 0, newWidth, image.height, 0, 0, canvas.width, canvas.height);
      }
    }
    return canvas;
  }

}
