import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { PhotoState, PhotoUpload } from 'src/app/models/file-upload.model';
import { ComponentCanDeactivate } from 'src/app/routing/editor.guard';
import { EditorService } from 'src/app/services/editor/editor.service';
import { PhotosService } from 'src/app/services/photos.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-editor-view',
  templateUrl: './editor-view.component.html',
  styleUrls: ['./editor-view.component.scss']
})
export class EditorViewComponent extends GarbageCollectorComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {

  @ViewChild('stepper')
  public stepper!: MatStepper;

  public uploadControl: FormControl = new FormControl(false, Validators.requiredTrue);
  public processingControl: FormControl = new FormControl(false, Validators.requiredTrue);
  public storageControl: FormControl = new FormControl(false, Validators.requiredTrue);
  public handoutControl: FormControl = new FormControl(false, Validators.requiredTrue);
  private _editedCount = 0;
  private _approvededCount = 0;

  public processingStates: PhotoState[] = [PhotoState.EDITABLE, PhotoState.EDITED, PhotoState.REJECTED];
  public storageStates: PhotoState[] = [PhotoState.EDITED, PhotoState.REJECTED];
  public handoutStates: PhotoState[] = [PhotoState.APPROVED];
  public processingMessage = '';
  public maximized = false;

  constructor(
    private _photosService: PhotosService,
    private _editorService: EditorService,
    private _dialog: MatDialog,
    private _router: Router,
    private _route: ActivatedRoute,
    private _location: Location
  ) {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this._photosService.getPhotos().subscribe((photos: PhotoUpload[]) => {
        this.uploadControl.setValue(
          photos.filter(photo => photo.state !== PhotoState.IMPORTED && photo.state !== PhotoState.INVALID).length > 0
        );
        const editedCount = photos.filter(photo => photo.state === PhotoState.EDITED).length;
        const approvedCount = photos.filter(photo => photo.state === PhotoState.APPROVED).length;

        if (this._editedCount < editedCount) {
          this.processingControl.setValue(true);
        } else if (this._approvededCount < approvedCount) {
          this.storageControl.setValue(true);
        } else if (this._approvededCount < approvedCount) {
          this.storageControl.setValue(true);
          this.handoutControl.setValue(true);
        }
        this._editedCount = editedCount;
        this._approvededCount = approvedCount;
        this.maximized = photos.length > 0;
      })
    );
    this.addSubscription(
      this._photosService.nextStepTrigger.subscribe(() => {
        this.stepper.next();
      })
    );
    this.addSubscription(
      this._editorService.processingMessage.subscribe((message) => {
        this.processingMessage = message;
      })
    );
  }

  public ngAfterViewInit(): void {
    const id = this._route.snapshot.params.id;
    if (id) {
      this._location.replaceState('editor');
      setTimeout(() => {
        this.stepper.selectedIndex = 1;
      }, 1);
    }
  }

  public stepChanged(step: StepperSelectionEvent): void {
    if (step.selectedIndex === 1) {
      this._setEditState();
    }
    if (step.previouslySelectedIndex === 1) {
      this._restoreState();
    }
    if (step.selectedIndex === 0 && this.uploadControl.valid) {
      this._confirmLeave(step.previouslySelectedIndex);
    }
  }

  private _restoreState(): void {
    const photos: PhotoUpload[] = this._photosService.getPhotosSnapshot();
    photos.forEach((photo: PhotoUpload) => {
      if (photo.state === PhotoState.EDITABLE && photo.savedState !== PhotoState.NONE) {
        this._photosService.updatePhoto({
          ...photo,
          state: photo.savedState,
          savedState: PhotoState.NONE
        });
      }
    });
  }

  private _setEditState(): void {
    const photos: PhotoUpload[] = this._photosService.getPhotosSnapshot();
    photos.forEach((photo: PhotoUpload) => {
      if (photo.state === PhotoState.EDITED || photo.state === PhotoState.REJECTED) {
        this._photosService.updatePhoto({
          ...photo,
          state: PhotoState.EDITABLE,
          savedState: photo.state,
        });
      }
      if (photo.state === PhotoState.UPLOADED) {
        this._photosService.updatePhoto({
          ...photo,
          state: PhotoState.EDITABLE
        });
      }
    });
  }

  public toArchive(): void {
    this._router.navigate(['archive']);
  }

  private _clearController(): void {
    this.uploadControl.reset();
    this.processingControl.reset();
    this.storageControl.reset();
    this.handoutControl.reset();
  }

  private _confirmLeave(previous: number): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CONFIRM,
      'dialog.discardProcess.headline',
      ['dialog.discardProcess.description'],
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData
    });

    this.addSubscription(
      dialogRef.afterClosed().subscribe((response: boolean) => {
        if (response === true) {
          this._clearController();
          this._photosService.clearState();
        } else {
          this.stepper.selectedIndex = previous;
        }
      })
    );
  }

  public finishProcess(): void {
    this._clearController();
    this._photosService.clearState();
    this.stepper.selectedIndex = 0;
  }

  public canDeactivate(): Observable<boolean> {
    if (this.uploadControl.invalid) {
      return of(true);
    }
    // return false;
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CONFIRM,
      'dialog.discardProcess.headline',
      ['dialog.discardProcess.description'],
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    return dialogRef.afterClosed().pipe(
      map((response: any) => {
        if (response === true) {
          this._photosService.clearState();
          return true;
        }
        return false;
      })
    );
  }

}
