<mat-form-field class="input" appearance="standard" *ngIf="config.type !== 'none'">
  <mat-label>{{ config.label | translate }}</mat-label>
  <mat-icon *ngIf="preIcon !== undefined && !isOwnIcon(preIcon)" matPrefix>{{ preIcon }}</mat-icon>
  <mat-icon *ngIf="preIcon !== undefined && isOwnIcon(preIcon)" matPrefix [svgIcon]="preIcon"></mat-icon>
  <input
    matInput
    [type]="config.type"
    [autocomplete]="autocomplete"
    [readonly]="readonly !== undefined"
    [placeholder]="config.placeholder ? (config.placeholder | translate) : ''"
    [formControl]="config.control"
  >
  <button *ngIf="isPassword" tabindex="-1" mat-icon-button matSuffix (click)="toggleVisibility()">
    <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
  </button>
  <mat-icon *ngIf="postIcon !== undefined && isOwnIcon(postIcon)" matSuffix [svgIcon]="postIcon"></mat-icon>
  <mat-icon *ngIf="postIcon !== undefined && !isOwnIcon(postIcon)" matSuffix>{{ postIcon }}</mat-icon>
  <mat-icon class="input__icon--readonly" *ngIf="readonly !== undefined" matSuffix>locked</mat-icon>
  <mat-hint *ngIf="config.hint">{{ config.hint | translate }}</mat-hint>
  <mat-error *ngIf="config.control.errors">
    {{ getErrorMessage() }}
  </mat-error>
</mat-form-field>
