<div class="new-password">
  <form autocomplete="off" class="row">
    <div class="flex new-password__helper">
      <app-input required [config]="password1Config"></app-input>
      <div class="new-password__conditions">
        <div class="new-password__condition" *ngFor="let rule of rules">
          <mat-icon [ngClass]="rule.valid ? 'color-success' : 'color-error'" class="color-error">{{rule.valid ? 'check_circle' : 'cancel'}}</mat-icon>
          <span>{{ rule.text | translate }}</span>
        </div>
      </div>
    </div>
    <app-input class="flex" required [config]="password2Config"></app-input>
  </form>
</div>
