import { FormControl } from '@angular/forms';
import { Canvas } from '../utilities/canvas';

export class FileUploadConfig {
  public control: FormControl;
  public label: string;

  constructor(label: string) {
    this.control = new FormControl([]);
    this.label = label;
  }
}

export enum PhotoState {
  NONE = 'none',
  IMPORTED = 'imported',
  UPLOADED = 'uploaded',
  INVALID = 'invalid',
  EDITABLE = 'editable',
  EDITED = 'edited',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  ARCHIVED = 'archived',
}

export enum PhotoAction {
  UPLOAD = 'upload',
  DOWNLAOD = 'download',
  REMOVE = 'remove',
  EDIT = 'edit',
  STORE = 'store',
  HANDOUT = 'handout',
  CHECK = 'check',
  SET = 'set',
  FORCE_SAVE = 'forceSave',
  RESUME = 'resume',
  CONTINUE = 'continue',
}

export enum ProcessingActionType {
  CONTRAST = 'CONTRAST',
  BRIGHTNESS = 'BRIGHTNESS',
  ROTATION = 'ROTATION',
  CROP = 'CROP'
}

export class CropValue {
  public origin: { x: number; y: number } = {x: 0, y: 0};
  public size: { w: number; h: number } = {w: 0, h: 0};
  constructor(x: number, y: number, w: number, h: number) {
    this.origin.x = x;
    this.origin.y = y;
    this.size.w = w;
    this.size.h = h;
  }
}

export class ProcessingAction {
  public type: ProcessingActionType;
  public value: number | CropValue;
  constructor(type: ProcessingActionType, value: number | CropValue) {
    this.type = type;
    this.value = value;
  }
}

export interface PhotoUploadResponse {
  imageId: string;
  fileName: string;
}

export interface Movement {
  x: number;
  y: number;
}

export class PhotoVerification {
  public createdDateTime = '';
  public expiryDate = '';
  public token = '';
  public url = '';
  public authorityData: any = {};
  public secretKey = '';

  get hasNoFace(): boolean {
    return this.authorityData.faceCaptureCount === 0;
  }

  get hasMultipleFaces(): boolean {
    return this.authorityData.faceCaptureCount > 1;
  }

  get faceCount(): number {
    return Number(this.authorityData.faceCaptureCount);
  }

  get cognitecApproved(): boolean {
    return this.authorityData.result === "Success";
  }
}

export class ValidationResult {
  public onlyOneFaceVisible: boolean | undefined = false;
  public goodVerticalPosition: boolean | undefined = false;
  public goodHorizontalPosition: boolean | undefined = false;
  public widthOfHead: boolean | undefined = false;
  public lengthOfHead: boolean | undefined = false;
  public resolution: boolean | undefined = false;
  public goodExposure: boolean | undefined = false;
  public goodGrayScaleProfile: boolean | undefined = false;
  public hasNaturalSkinColor: boolean | undefined = false;
  public noHotSpots: boolean | undefined = false;
  public isFrontal: boolean | undefined = false;
  public isLightningUniform: boolean | undefined = false;
  public noTintedGlasses: boolean | undefined = false;
  public isSharp: boolean | undefined = false;
  public imageWidthToHeight: boolean | undefined = false;
  public isBackGroundUniform: boolean | undefined = false;
  public eyesOpen: boolean | undefined = false;
  public eyesGazeFrontal: boolean | undefined = false;
  public eyesNotRed: boolean | undefined = false;
  public mouthClosed: boolean | undefined = false;
}

export interface QualityAssurance {
  identifier: string;
  result: number;
}

export class PhotoUpload {
  public file: File;
  public image: HTMLImageElement;
  public state: PhotoState;
  public id = '';
  public preview: string;
  public actions: ProcessingAction[] = [];
  public width: number;
  public height: number;
  public verification: PhotoVerification = new PhotoVerification();
  public validationResult: ValidationResult = new ValidationResult();
  public validationErrors: string[] = [];
  public success = '';
  public fail = '';
  public uploaded = false;
  public savedState: PhotoState = PhotoState.NONE;
  constructor(file: File, image: HTMLImageElement) {
    this.file = file;
    this.image = image;
    this.preview = Canvas.getPreview(image);
    this.state = PhotoState.IMPORTED;
    this.width = image.width;
    this.height = image.height;
  }
}
