import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SnackbarService } from './snackbar.service';

export class Message {
  public time: string;
  public text: string;
  public subText: string;
  public duration: number;

  constructor(text: string = '', subText: string = '', duration: number) {
    this.time = (new Date()).toLocaleTimeString('de');
    this.text = text;
    this.subText = subText;
    this.duration = duration;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConsoleService {

  public message: Subject<Message> = new Subject();

  constructor(
    private _snackbarService: SnackbarService
  ) { }

  public add(text: string, subText: string = '', duration: number = 30): void {
    if (environment.production) {
      this._snackbarService.showError(['snackbar.consoleError']);
    } else {
      this.message.next(new Message(text, subText, duration));
    }
  }
}
