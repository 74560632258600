import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { AccountModel } from 'src/app/models/store.model';
import { ResetAccount, UpdateAccount, UpdateAccountProperty } from './account.actions';

const initState: AccountModel = new AccountModel();

@State<AccountModel>({
  name: 'account',
  defaults: initState,
})
@Injectable()
export class AccountState {

  @Action(ResetAccount)
  public resetSecurity(ctx: StateContext<AccountModel>, { }: ResetAccount): void {
    ctx.setState(initState);
  }

  @Action(UpdateAccount)
  public updateAccount(ctx: StateContext<AccountModel>, { account }: UpdateAccount): void {
    ctx.setState(account);
  }

  @Action(UpdateAccountProperty)
  public updateAuth(ctx: StateContext<AccountModel>, { property, value }: UpdateAccountProperty): void {
    const state = ctx.getState();
    const patch = {};
    if (Reflect.has(state, property)) {
      Reflect.set(patch, property, value);
      ctx.patchState(patch);
    }
  }

}
