import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InputConfig, InputType } from 'src/app/models/input.model';

@Component({
  selector: 'app-studio',
  templateUrl: './studio.component.html',
  styleUrls: ['./studio.component.scss']
})
export class StudioComponent implements OnInit {

  @Input()
  public form: FormGroup = this._fb.group({});
  @Input()
  public label?: [string, number];

  public studioNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.studioName.label');
  public licenseNumberConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.licenseNumber.label');
  public addressConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.address.label');
  public zipConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.zip.label');
  public locationConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.location.label');

  constructor(
    private _fb: FormBuilder,
  ) { }

  public ngOnInit(): void {
    this.form.addControl('name', this.studioNameConfig.control);
    this.form.addControl('name', this.licenseNumberConfig.control);
    this.form.addControl('address', this.addressConfig.control);
    this.form.addControl('zip', this.zipConfig.control);
    this.form.addControl('location', this.locationConfig.control);
  }

}
