import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { Company, ResponseStatus, Studio, TableAction, TableActionType, TableConfig } from 'src/app/models/administration.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { Role } from 'src/app/models/security.model';
import { AccountModel, StateModel, StudioItems } from 'src/app/models/store.model';
import { PhotostudioService } from 'src/app/services/register/photostudio.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-company-view',
  templateUrl: './company-view.component.html',
  styleUrls: ['./company-view.component.scss']
})
export class CompanyViewComponent extends GarbageCollectorComponent implements OnInit {

  public searchConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.searchStudio.label');
  public tableConfig: TableConfig = new TableConfig();
  private _studios: Studio[] = [];
  public email = '';
  public company = '';
  public isPhographer = false;
  public showSearch = false;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _store: Store,
    private _dialog: MatDialog,
    private _snackbarService: SnackbarService,
    private _photostudioService: PhotostudioService
  ) {
    super();
  }

  public ngOnInit(): void {
    const role: Role = this._store.selectSnapshot((state: StateModel) => state.security.role);
    this.isPhographer = role === Role.PHOTOGRAPHER;
    this.email = this._route.snapshot.params.email;
    if (this.isPhographer) {
      this.email = this._store.selectSnapshot((state: StateModel) => state.account.email);
      this.addSubscription(
        this._store.select((state: StateModel) => state.account).subscribe((account: AccountModel) => {
          this.company = `${account.company} ${account.companyLegalForm}`.trim();
        })
      );
    } else {
      const company: Company = this._store.selectSnapshot((state: StateModel) => state.administration.companies.items)
        .find(c => c.email === this.email) || new Company();
      this.company = `${company.company} ${company.companyLegalForm}`.trim();
    }

    this.addSubscription(
      this._store.select((state: StateModel) => state.administration.studios).subscribe((studios: StudioItems) => {
        this.tableConfig.header = ['studioName', 'address', 'zip', 'location'];
        if (this.isPhographer) {
          this.tableConfig.actions = [];
          if (studios.items.length > 1) {
            this.tableConfig.actions.push(TableActionType.DELETE);
          }
          this.tableConfig.actions.push(TableActionType.UPDATE);
        }
        this.tableConfig.actionId = 'studioName';
        this._studios = studios.items;
        this.tableConfig.items = this._studios;
        this.tableConfig.paginator.current = 1;
        this.tableConfig.paginator.total = this._studios.length;
        this.tableConfig = {...this.tableConfig};
        this.showSearch = this._studios.length > this.tableConfig.paginator.size;
      })
    );

    this._photostudioService.getStudios(this.email);
  }

  public addStudio(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.ADD_STUDIO,
      'dialog.addStudio.headline',
      ['dialog.addStudio.description'],
      'button.add'
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().subscribe((studio: Studio | undefined) => {
        if (studio !== undefined) {
          this._studios = [...this._studios, studio];
          this._updateData();
        }
      })
    );
  }

  public search(value: string): void {
    this.tableConfig.items = this._studios.filter((studio: Studio) => {
      return Object.values(studio).find(studioValue => `${studioValue}`.toLowerCase().includes(value.toLowerCase())) !== undefined;
    });
    this.tableConfig.paginator.current = 1;
    this.tableConfig.paginator.total = this.tableConfig.items.length;
    this.tableConfig.paginator = {...this.tableConfig.paginator};
    this.tableConfig = {...this.tableConfig};
  }

  public back(): void {
    this._router.navigate(['companies']);
  }

  private _deleteStudio(studio: Studio): void {
    const index = this._studios.indexOf(studio);
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CONFIRM,
      'dialog.removeStudio.headline',
      ['dialog.removeStudio.description', `${studio.studioName}`]
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this._studios = this._studios.filter((item, i) => i !== index);
          this._updateData();
        }
      })
    );
  }

  private _updateStudio(studio: Studio): void {
    const index = this._studios.indexOf(studio);
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.UPDATE_STUDIO,
      'dialog.updateStudio.headline',
      ['dialog.updateStudio.description'],
      'button.applyChanges'
    );
    dialogData.values = studio;
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData
    });

    this.addSubscription(
      dialogRef.afterClosed().subscribe((s: Studio | undefined) => {
        if (s !== undefined) {
          this._studios = this._studios.map((item, i) => {
            return index === i ? {...s} : {...item};
          });
          this._updateData();
        }
      })
    );
  }

  private _updateData(): void {
    this._photostudioService.updateStudios(this._studios).subscribe((response: ResponseStatus) => {
      if (response === ResponseStatus.SUCCESS) {
        this._photostudioService.getStudios(this.email);
      } else {
        this._studios = [...this.tableConfig.items];
        this._snackbarService.showError(['company.error']);
      }
    });
  }

  public onAction(action: TableAction): void {
    const studio: Studio | undefined = this._studios.find((s: Studio) => s.studioName === action.id);

    switch (action.name) {
      case TableActionType.DELETE:
        if (studio) {
          this._deleteStudio(studio);
        }
        break;
      case TableActionType.UPDATE:
        if (studio) {
          this._updateStudio(studio);
        }
        break;
      default:
        break;
    }
  }

}
