import { Component, Input, OnInit } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { SliderConfig } from 'src/app/models/slider.model';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent extends GarbageCollectorComponent implements OnInit {

  @Input()
  public config: SliderConfig = new SliderConfig('');

  public current = 0;
  private _initValue = 0;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.current = Number(this.config.control.value);
    this._initValue = Number(this.config.control.value);
    this.addSubscription(
      this.config.control.valueChanges.subscribe(value => {
        const factor: number = 1 / this.config.step;
        if (value !== '' && value !== '-') {
          let val: number = Math.round(parseFloat(value) * factor) / factor;
          if (!isNaN(val)) {
            if (Number(value) === val) {
              if (val < this.config.min) {
                this.config.control.setValue(this.config.min);
                val = this.config.min;
              }
              if (val > this.config.max) {
                this.config.control.setValue(this.config.max);
                val = this.config.max;
              }
              this.current = val;
            } else {
              this.config.control.setValue(val);
            }
          } else {
            this.config.control.setValue(this.current);
          }
        }
      })
    );
  }

  public reset(): void {
    this.config.control.setValue(this._initValue);
  }

  public changeValue(change: MatSliderChange): void {
    this.config.control.setValue(change.value);
  }

  public cleanUpValue(): void {
    this.config.control.setValue(Number(this.config.control.value));
  }

}
