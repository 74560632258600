import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ResponseStatus} from 'src/app/models/administration.model';
import {PhotoState, PhotoUpload, PhotoVerification, QualityAssurance, ValidationResult} from 'src/app/models/file-upload.model';
import {StateModel} from 'src/app/models/store.model';
import {Canvas} from 'src/app/utilities/canvas';
import {Helper} from 'src/app/utilities/helper';
import {ApiService} from '../api.service';
import {ConfigService} from '../config.service';
import {PhotosService} from '../photos.service';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  private _url = '';
  private _licenseHeader: HttpHeaders = new HttpHeaders();
  public processingMessage: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private _configService: ConfigService,
    private _http: HttpClient,
    private _photosService: PhotosService,
    private _apiService: ApiService,
    private _store: Store
  ) {
    this._configService.getApi().subscribe((data: any) => {
      this._url = `${data.editor.origin}${data.editor.port}${data.editor.version}/image`;
    });
    this._store.select((state: StateModel) => state.security.licenseToken).subscribe((licenseToken: string) => {
      this._licenseHeader = new HttpHeaders();
      this._licenseHeader = this._licenseHeader.append('licenseToken', `${licenseToken}`);
    });
  }

  public uploadPhotos(files: File[]): Observable<any> {
    const formData: any = new FormData();
    files.forEach((file: File) => {
      formData.append('uploads', file);
    });

    return this._http.post(`${this._url}/upload`, formData, {
      reportProgress: true,
      observe: 'events',
      headers: this._licenseHeader
    });
  }

  public applyPhotoActions(photo: PhotoUpload): Observable<ResponseStatus> {
    const request = this._http.patch(`${this._url}/${photo.id}`, { actions: photo.actions }, { headers: this._licenseHeader });

    return this._apiService.handleResponse(request);
  }

  public sendToCognitec(photo: PhotoUpload, forceSave: boolean = false): Observable<ResponseStatus> {
    const query = forceSave ? '?forceSave=true' : '';
    const request = this._http.put(`${this._url}/${photo.id}${query}`, {}, { headers: this._licenseHeader }).pipe(
      tap((response: any) => {
        photo.verification = new PhotoVerification();
        Helper.mergeData(photo.verification, response);

        if (response.token && (photo.verification.cognitecApproved || forceSave)) {
          photo.state = PhotoState.APPROVED;
          photo.fail = '';
        } else {
          photo.state = PhotoState.REJECTED;
          photo.validationResult = this._getResultFromValidation(response);
        }
      })
    );
    return this._apiService.handleResponse(request);
  }

  public validatePhoto(photo: PhotoUpload): Observable<ResponseStatus> {
    const request = this._http.get(`${this._url}/verify/${photo.id}`, { headers: this._licenseHeader }).pipe(
      tap((response: any) => {
        photo.verification = new PhotoVerification();
        Helper.mergeData(photo.verification, response);
        if (!photo.verification.cognitecApproved) {
          photo.fail = 'editor.status.rejected';
          photo.validationResult = this._getResultFromValidation(response);
          photo.validationErrors = this._getErrorsFromValidation(response);
        }
      })
    );

    return this._apiService.handleResponse(request);
  }

  public getPhoto(photo: PhotoUpload): Observable<ResponseStatus> {
    const request = this._http.get(`${this._url}/${photo.id}`, {
      responseType: 'blob',
      headers: this._licenseHeader
    }).pipe(
      map((blob: Blob) => {
        photo.file = Helper.blobToFile(blob, photo.file.name);
        const reader = new FileReader();
        reader.onload = (src) => {
          const source = `${src.target?.result}`;
          if (source.startsWith('data:image')) {
            const image = new Image();
            image.onload = () => {
              photo.preview = Canvas.getPreview(image);
              photo.actions = [];
              photo.image = image;
              photo.width = image.width;
              photo.height = image.height;
              photo.state = PhotoState.EDITED;
              photo.fail = '';
              this._photosService.updatePhoto(photo);
              this._photosService.nextStepTrigger.next();
            };
            image.src = source;
          }
        };
        reader.readAsDataURL(photo.file);
        return ResponseStatus.SUCCESS;
      })
    );

    return this._apiService.handleResponse(request);
  }

  public loadArchive(password: string): Observable<ResponseStatus> {
    return of(ResponseStatus.SUCCESS);
  }

  private _getErrorsFromValidation(response: any): string[] {
    const qualityAssurances: QualityAssurance[] = response?.authorityData?.faceQualities[0].qualityAssurances || [];
    const isoTypes: string[] = ['iso197945ComplianceInformation'];

    return qualityAssurances.filter((item: QualityAssurance) => {
      return isoTypes.some((identifier: string) => item.identifier.startsWith(identifier));
    }).filter((item: QualityAssurance) => item.result === 0).map((item: QualityAssurance) => {
      const pattern = new RegExp(isoTypes.map((type: string) => `(${type}).`).join('|'));
      const identifier: string = item.identifier.replace(pattern, '');
      return identifier;
    });
  }

  private _getResultFromValidation(response: any): ValidationResult {
    // we now try to extract the face quality information from the result
    // first check that the necessary information exists, otherwise
    // just return an all-false response.
    const faceQualities: any[] | undefined = response?.authorityData?.faceQualities;
    if (!faceQualities) {
      return new ValidationResult();
    }

    const qualityAssurances: any[] | undefined = faceQualities[0].qualityAssurances;
    if (!qualityAssurances) {
      return new ValidationResult();
    }

    const isoTypes: string[] = ['iso197945ComplianceInformation'];
    const validationResult: any = {};

    qualityAssurances.filter((item: QualityAssurance) => {
      return isoTypes.some((identifier: string) => item.identifier.startsWith(identifier));
    }).forEach((item: QualityAssurance) => {
      const pattern = new RegExp(isoTypes.map((type: string) => `(${type}).`).join('|'));
      const identifier: string = item.identifier.replace(pattern, '');
      const value: boolean = item.result !== 0;
      return Reflect.set(validationResult, identifier, value);
    });

    return {
      ...new ValidationResult(),
      ...validationResult
    };

  }

}
