import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { HardwareProvider } from 'src/app/models/administration.model';
import { AdministrationModel } from 'src/app/models/store.model';
import {
  AddHardwareProvider,
  RemoveHardwareProvider,
  ResetAdministration,
  UpdateApplications,
  UpdateCameras,
  UpdateCompanies,
  UpdateEmployees,
  UpdateHardwareProvider,
  UpdateHardwareProviders,
  UpdatePhotographer,
  UpdateStudios,
  UpdateUsers
} from './administration.actions';

const initState: AdministrationModel = {
  employees: [],
  photographer: [],
  cameras: {
    items: [],
    total: 0
  },
  hardwareProviders: [],
  applications: [],
  users: {
    items: [],
    total: 0
  },
  companies: {
    items: [],
    total: 0
  },
  studios: {
    items: [],
    total: 0
  },
};

@State<AdministrationModel>({
  name: 'administration',
  defaults: initState,
})
@Injectable()
export class AdministrationState {

  @Action(ResetAdministration)
  public resetAdministration(ctx: StateContext<AdministrationModel>, { }: ResetAdministration): void {
    ctx.setState(initState);
  }

  @Action(UpdateEmployees)
  public updateEmployees(ctx: StateContext<AdministrationModel>, { employees }: UpdateEmployees): void {
    ctx.setState({
      ...ctx.getState(),
      employees
    });
  }

  @Action(UpdateHardwareProviders)
  public updateHardwareProviders(ctx: StateContext<AdministrationModel>, { hardwareProviders }: UpdateHardwareProviders): void {
    ctx.setState({
      ...ctx.getState(),
      hardwareProviders
    });
  }

  @Action(UpdateHardwareProvider)
  public updateHardwareProvider(ctx: StateContext<AdministrationModel>, { hardwareProvider }: UpdateHardwareProvider): void {
    const state = ctx.getState();
    ctx.patchState({
      hardwareProviders: state.hardwareProviders.map((e: HardwareProvider) => e.email === hardwareProvider.email ? hardwareProvider : e)
    });
  }

  @Action(AddHardwareProvider)
  public addHardwareProvider(ctx: StateContext<AdministrationModel>, { hardwareProvider }: AddHardwareProvider): void {
    const state = ctx.getState();
    ctx.patchState({
      hardwareProviders: [...state.hardwareProviders, hardwareProvider]
    });
  }

  @Action(RemoveHardwareProvider)
  public removeHardwareProvider(ctx: StateContext<AdministrationModel>, { hardwareProvider }: RemoveHardwareProvider): void {
    const state = ctx.getState();
    ctx.patchState({
      hardwareProviders: state.hardwareProviders.filter(e => e !== hardwareProvider)
    });
  }

  @Action(UpdatePhotographer)
  public updatePhotographer(ctx: StateContext<AdministrationModel>, { photographer }: UpdatePhotographer): void {
    ctx.setState({
      ...ctx.getState(),
      photographer
    });
  }

  @Action(UpdateCameras)
  public updateCameras(ctx: StateContext<AdministrationModel>, { cameras }: UpdateCameras): void {
    const state = ctx.getState();
    ctx.setState({
      ...ctx.getState(),
      cameras
    });
  }

  @Action(UpdateApplications)
  public updateApplications(ctx: StateContext<AdministrationModel>, { applications }: UpdateApplications): void {
    ctx.setState({
      ...ctx.getState(),
      applications
    });
  }

  @Action(UpdateUsers)
  public updateUsers(ctx: StateContext<AdministrationModel>, { users }: UpdateUsers): void {
    ctx.setState({
      ...ctx.getState(),
      users
    });
  }

  @Action(UpdateCompanies)
  public updateCompanies(ctx: StateContext<AdministrationModel>, { companies }: UpdateCompanies): void {
    ctx.setState({
      ...ctx.getState(),
      companies
    });
  }

  @Action(UpdateStudios)
  public updateStudios(ctx: StateContext<AdministrationModel>, { studios }: UpdateStudios): void {
    ctx.setState({
      ...ctx.getState(),
      studios
    });
  }

}
