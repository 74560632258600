import { FormControl } from '@angular/forms';

export class DatePickerConfig {
  public control: FormControl;
  public label: string;

  constructor(label: string) {
    this.control = new FormControl('');
    this.label = label;
  }
}
