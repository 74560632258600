<div
  matRipple
  [matRippleDisabled]="disabled !== undefined && disabled !== false"
  class="button"
  (click)="doAction()"
  [ngClass]="{
    'button--disabled': disabled !== undefined && disabled !== false,
    'button--primary': primary !== undefined,
    'button--error': primary === 'error',
    'button--success': primary === 'success',
    'button--secondary': secondary !== undefined,
    'button--simple': simple !== undefined,
    'button--light': light !== undefined
  }"
  >
  <app-icon class="button__icon" *ngIf="icon" [icon]="icon"></app-icon>
  <span class="button__label">
    <ng-content></ng-content>
  </span>
</div>
