<footer class="footer">

    <div class="footer__item">&copy; {{ 'footer.headline' | translate: [currentYear] }} </div>
    <div class="footer__item">
      {{ 'footer.mail' | translate }}
      <a  class="footer__link" href="mailto:passbild@ringfoto.de">passbild@ringfoto.de</a>
    </div>
    <div class="footer__item">
      {{ 'footer.hotline' | translate }}
      <a class="footer__link" href="tel:+499116585299">+49 911 6585 -299</a>
      {{ 'footer.opening-hours' | translate }}
    </div>
    <div class="footer_item">
      <a class="footer__link" href="https://www.e-passfoto.de/impressum/" target="_blank" rel="noopener noreferrer">{{ 'footer.imprint' | translate }}</a>
    </div>
</footer>
