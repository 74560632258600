import { Component, Input } from '@angular/core';
import { TableActionType } from 'src/app/models/administration.model';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent {

  @Input()
  public actions: TableActionType[] = [];

  constructor() { }

  public getKeyFromType(action: TableActionType): string {
    let key = 'tableAction.';
    switch (action) {
      case TableActionType.EDIT:
          key += 'edit';
          break;
      case TableActionType.UPDATE:
          key += 'update';
          break;
      case TableActionType.DELETE:
          key += 'delete';
          break;
      case TableActionType.DEACTIVATE:
          key += 'deactivate';
          break;
      case TableActionType.REACTIVATE:
          key += 'reactivate';
          break;
      case TableActionType.PUBLIC_KEY:
          key += 'publicKey';
          break;
      case TableActionType.VIEW_APPLICATION:
          key += 'viewApplication';
          break;
      case TableActionType.DOWNLAOD:
          key += 'download';
          break;
      case TableActionType.REMOVE_STUDIO:
          key += 'removeStudio';
          break;
      case TableActionType.STUDIOS:
          key += 'studios';
          break;
      case TableActionType.LICENSE:
          key += 'license';
          break;
      case TableActionType.ADD_COMPANY:
          key += 'addCompany';
          break;
      default:
        break;
    }
    return key;
  }

}
