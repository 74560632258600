import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UigEmployee, User } from 'src/app/models/administration.model';
import { AutocompleteConfig } from 'src/app/models/autocomplete.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { Helper } from 'src/app/utilities/helper';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';
import { CheckboxConfig } from 'src/app/models/checkbox.model';
import { Role } from 'src/app/models/security.model';
import { Store } from '@ngxs/store';
import { StateModel } from 'src/app/models/store.model';
import { UserService } from 'src/app/services/register/user.service';

@Component({
  selector: 'app-uig-employee-view',
  templateUrl: './uig-employee-view.component.html',
  styleUrls: ['./uig-employee-view.component.scss']
})
export class UigEmployeeViewComponent extends GarbageCollectorComponent implements OnInit {

  private _uigEmployee: UigEmployee = new UigEmployee();
  public updateError = '';
  public isAdmin = false;

  public salutationConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.salutation.label');
  public firstNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.firstName.label');
  public lastNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.lastName.label');
  public emailConfig: InputConfig = new InputConfig(InputType.EMAIL, 'input.email.label');
  public phone1Config: InputConfig = new InputConfig(InputType.PHONE, 'input.phone1.label');
  public adminConfig: CheckboxConfig = new CheckboxConfig('checkbox.admin.label');
  public uigForm: FormGroup = this._fb.group({});
  public locked = true;

  constructor(
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    private _router: Router,
    private _store: Store,
    private _userService: UserService
  ) {
    super();
  }

  public ngOnInit(): void {
    const email: string = this._route.snapshot.params.email;
    const uigUser: User = new User();
    uigUser.role = Role.UIG;
    uigUser.email = email;

    this.uigForm.addControl('salutation', this.salutationConfig.control);
    this.uigForm.addControl('firstName', this.firstNameConfig.control);
    this.uigForm.addControl('lastName', this.lastNameConfig.control);
    this.uigForm.addControl('email', this.emailConfig.control);
    this.uigForm.addControl('phone1', this.phone1Config.control);

    this.addSubscription(
      this._userService.getUser(uigUser).subscribe((uigEmployee: any) => {
        if (uigEmployee) {
          this._uigEmployee = {...uigEmployee};
          Helper.mergeForm(this.uigForm, uigEmployee);
        } else {
          this.back();
        }
      })
    );

    const uig = this._store.selectSnapshot((state: StateModel) => state.administration.users)
      .items.find((user: User) => user.email === email);
    if (uig) {
      this.isAdmin = this._store.selectSnapshot((state: StateModel) => state.security.role) === Role.ADMIN;
      this.adminConfig.control.setValue(uig.role === Role.ADMIN);
      this._uigEmployee.role = uig.role;
    }

    this.addSubscription(
      this.uigForm.statusChanges.subscribe(() => {
        this.locked = this.uigForm.invalid;
      })
    );
  }

  public update(): void {
    const update = Helper.diffData(this._uigEmployee, this.uigForm.value);
    const user: User = new User();
    Helper.mergeData(user, this._uigEmployee);
    user.role = Role.UIG;
    this.addSubscription(
      this._userService.updateUser(update, user).subscribe(success => {
        if (!success) {
          this.updateError = 'uig-employee.error.update';
        }
      })
    );
    if (this.isAdmin) {
      const newRole: Role = this.adminConfig.control.value ? Role.ADMIN : Role.UIG;
      if (newRole !== this._uigEmployee.role) {
        this.addSubscription(
          this._userService.updateRole(newRole, this._uigEmployee.email).subscribe(success => {
            if (!success) {
              this.adminConfig.control.setValue(!this.adminConfig.control.value);
            }
          })
        );
      }
    }
  }

  public back(): void {
    this._router.navigate(['uig-employees']);
  }

}
