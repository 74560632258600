import { NgModule } from '@angular/core';
import { InputComponent } from '../components/input/input.component';
import { InputFileComponent } from '../components/input-file/input-file.component';
import { MaterialModule } from '../modules/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LocalizationModule } from '@tallence/localization';
import { SelectComponent } from '../components/select/select.component';
import { AutocompleteComponent } from '../components/autocomplete/autocomplete.component';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { StudioComponent } from '../components/studio/studio.component';
import { IconComponent } from '../components/icon/icon.component';
import { CheckboxComponent } from '../components/checkbox/checkbox.component';
import { DataRowComponent } from '../components/data-row/data-row.component';
import { NewPasswordComponent } from '../components/new-password/new-password.component';
import { DialogComponent } from '../components/dialog/dialog.component';
import { ContentComponent } from '../components/content/content.component';
import { MenuComponent } from '../components/menu/menu.component';
import { ConsoleComponent } from '../components/console/console.component';
import { HeaderComponent } from '../components/header/header.component';
import { FooterComponent } from '../components/footer/footer.component';
import { SidenavComponent } from '../components/sidenav/sidenav.component';
import { PaginatorComponent } from '../components/paginator/paginator.component';
import { TableComponent } from '../components/table/table.component';
import { CardComponent } from '../components/card/card.component';
import { ButtonComponent } from '../components/button/button.component';
import { AvatarComponent } from '../components/avatar/avatar.component';
import { DatePickerComponent } from '../components/date-picker/date-picker.component';
import { HeadlineComponent } from '../components/headline/headline.component';
import { PhotoTileComponent } from '../components/photo-tile/photo-tile.component';
import { SliderComponent } from '../components/slider/slider.component';
import { ToggleComponent } from '../components/toggle/toggle.component';
import { WelcomeComponent } from '../components/welcome/welcome.component';
import { SearchComponent } from '../components/search/search.component';
import { LegendComponent } from '../components/legend/legend.component';
import { QRCodeModule } from 'angularx-qrcode';
import { CodeInputComponent } from '../components/code-input/code-input.component';
import { PhotosUploadComponent } from '../components/photos-upload/photos-upload.component';
import { PhotoComponent } from '../components/photo/photo.component';
import { PhotoEditorComponent } from '../components/photo-editor/photo-editor.component';
import { PhotosOverviewComponent } from '../components/photos-overview/photos-overview.component';
import { PhotoSetComponent } from '../components/photo-set/photo-set.component';

@NgModule({
    declarations: [
        InputComponent,
        InputFileComponent,
        SelectComponent,
        AutocompleteComponent,
        FileUploadComponent,
        StudioComponent,
        IconComponent,
        CheckboxComponent,
        DataRowComponent,
        NewPasswordComponent,
        DialogComponent,
        ContentComponent,
        MenuComponent,
        ConsoleComponent,
        HeaderComponent,
        FooterComponent,
        SidenavComponent,
        PaginatorComponent,
        TableComponent,
        CardComponent,
        ButtonComponent,
        AvatarComponent,
        DatePickerComponent,
        HeadlineComponent,
        PhotoTileComponent,
        SliderComponent,
        ToggleComponent,
        WelcomeComponent,
        SearchComponent,
        LegendComponent,
        CodeInputComponent,
        PhotosUploadComponent,
        PhotoComponent,
        PhotoEditorComponent,
        PhotosOverviewComponent,
        PhotoSetComponent
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        CommonModule,
        LocalizationModule,
        QRCodeModule
    ],
    exports: [
        InputComponent,
        InputFileComponent,
        SelectComponent,
        AutocompleteComponent,
        FileUploadComponent,
        StudioComponent,
        IconComponent,
        CheckboxComponent,
        DataRowComponent,
        NewPasswordComponent,
        DialogComponent,
        ContentComponent,
        ConsoleComponent,
        HeaderComponent,
        FooterComponent,
        SidenavComponent,
        PaginatorComponent,
        TableComponent,
        CardComponent,
        ButtonComponent,
        AvatarComponent,
        DatePickerComponent,
        HeadlineComponent,
        PhotoTileComponent,
        SliderComponent,
        ToggleComponent,
        WelcomeComponent,
        SearchComponent,
        LegendComponent,
        CodeInputComponent,
        PhotosUploadComponent,
        PhotoComponent,
        PhotoEditorComponent,
        PhotosOverviewComponent,
        PhotoSetComponent
    ]
})
export class ComponentsModule { }
