<div class="photo-set">
  <app-headline icon="auto_awesome_mosaic">{{ 'editor.step.set.headline' | translate }}</app-headline>
  <div class="photo-set__content">
    <p>{{ 'editor.step.set.description' | translate }}</p>

    <div class="photo-set__layout">
      <div class="photo-set__size">

        <div *ngIf="photos.length" class="photo-set__sheet">

          <!-- 4x SET -->
          <div *ngIf="selectedSet === 0" class="photo-set__canvas">
            <div class="photo-set__tile--small">
              <div>
                <app-photo-tile (tap)="nextPhoto($event, set4, 0)" (panend)="changePhoto($event, set4, 0)" [src]="photos[set4[0]].preview"></app-photo-tile>
              </div>
              <div>
                <app-photo-tile (tap)="nextPhoto($event, set4, 1)" (panend)="changePhoto($event, set4, 1)" [src]="photos[set4[1]].preview"></app-photo-tile>
              </div>
            </div>
            <div class="photo-set__tile--small">
              <div>
                <app-photo-tile (tap)="nextPhoto($event, set4, 2)" (panend)="changePhoto($event, set4, 2)" [src]="photos[set4[2]].preview"></app-photo-tile>
              </div>
              <div>
                <app-photo-tile (tap)="nextPhoto($event, set4, 3)" (panend)="changePhoto($event, set4, 3)" [src]="photos[set4[3]].preview"></app-photo-tile>
              </div>
            </div>
            <div
              class="photo-set__tile--big"
              [ngStyle]="{'background-image': 'url(' + photos[set4[4]].preview + ')'}"
              (tap)="nextPhoto($event, set4, 4)"
              (panend)="changePhoto($event, set4, 4)"
            ></div>
          </div>

          <!-- 6x SET -->
          <div *ngIf="selectedSet === 1" class="photo-set__canvas">
            <div class="photo-set__tile--small">
              <div>
                <app-photo-tile (tap)="nextPhoto($event, set6, 0)" (panend)="changePhoto($event, set6, 0)" [src]="photos[set6[0]].preview"></app-photo-tile>
              </div>
              <div>
                <app-photo-tile (tap)="nextPhoto($event, set6, 1)" (panend)="changePhoto($event, set6, 1)" [src]="photos[set6[1]].preview"></app-photo-tile>
              </div>
            </div>
            <div class="photo-set__tile--small">
              <div>
                <app-photo-tile (tap)="nextPhoto($event, set6, 2)" (panend)="changePhoto($event, set6, 2)" [src]="photos[set6[2]].preview"></app-photo-tile>
              </div>
              <div>
                <app-photo-tile (tap)="nextPhoto($event, set6, 3)" (panend)="changePhoto($event, set6, 3)" [src]="photos[set6[3]].preview"></app-photo-tile>
              </div>
            </div>
            <div class="photo-set__tile--small">
              <div>
                <app-photo-tile (tap)="nextPhoto($event, set6, 4)" (panend)="changePhoto($event, set6, 4)" [src]="photos[set6[4]].preview"></app-photo-tile>
              </div>
              <div>
                <app-photo-tile (tap)="nextPhoto($event, set6, 5)" (panend)="changePhoto($event, set6, 5)" [src]="photos[set6[5]].preview"></app-photo-tile>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>


  </div>
  <div class="photo-set__actions actions">
    <app-button *ngIf="selectedSet !== 0" simple icon="filter_4" (action)="changeSet(0)">{{'button.set4' | translate}}</app-button>
    <app-button *ngIf="selectedSet !== 1" simple icon="filter_6" (action)="changeSet(1)">{{'button.set6' | translate}}</app-button>
    <div class="flex"></div>
    <app-button secondary [mat-dialog-close]="">{{'button.cancel' | translate}}</app-button>
    <app-button primary (action)="print()">{{'button.print' | translate}}</app-button>
  </div>
</div>
