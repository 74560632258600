<div class="error row">
  <app-welcome></app-welcome>
  <div class="flex">
    <app-content>
      <div class="error__content">
        <app-icon class="error__icon" icon="sentiment_dissatisfied"></app-icon>
      </div>
      <div class="error__text">{{ errorText | translate }}</div>
      <div class="error__action">
        <app-button icon="arrow_back" (action)="goBack()" secondary>{{ 'button.back' | translate }}</app-button>
      </div>
    </app-content>
  </div>
</div>
