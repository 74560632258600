import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { Role } from 'src/app/models/security.model';
import { SecurityModel } from 'src/app/models/store.model';
import { ResetAccount } from '../account/account.actions';
import { ResetAdministration } from '../administration/administration.actions';
import {
  ResetSecurity, UpdateAuth, UpdateLicenseToken, UpdateOtpSecret, UpdateRole, UpdateSmallSidenav, UpdateTimeout,
} from './security.actions';

const initState: SecurityModel = {
  auth: false,
  role: Role.NONE,
  smallSidenav: false,
  timeout: 0,
  licenseToken: '',
  otpSecret: ''
};

@State<SecurityModel>({
  name: 'security',
  defaults: initState,
})
@Injectable()
export class SecurityState {

  @Action(ResetSecurity)
  public resetSecurity(ctx: StateContext<SecurityModel>, { }: ResetSecurity): void {
    ctx.setState(initState);
  }

  @Action(UpdateAuth)
  public updateAuth(ctx: StateContext<SecurityModel>, { auth }: UpdateAuth): void {
    const oldAuth: boolean = ctx.getState().auth;
    if (oldAuth && !auth) {
      ctx.dispatch([
        new ResetSecurity(),
        new ResetAccount(),
        new ResetAdministration()
      ]);
    }
    ctx.setState({
      ...ctx.getState(),
      auth
    });
  }

  @Action(UpdateRole)
  public updateRole(ctx: StateContext<SecurityModel>, { role }: UpdateRole): void {
    ctx.setState({
      ...ctx.getState(),
      role
    });
  }

  @Action(UpdateSmallSidenav)
  public updateSmallSidenav(ctx: StateContext<SecurityModel>, { smallSidenav }: UpdateSmallSidenav): void {
    ctx.setState({
      ...ctx.getState(),
      smallSidenav
    });
  }

  @Action(UpdateTimeout)
  public updateTimeout(ctx: StateContext<SecurityModel>, { timeout }: UpdateTimeout): void {
    ctx.setState({
      ...ctx.getState(),
      timeout
    });
  }

  @Action(UpdateLicenseToken)
  public updateLicenseToken(ctx: StateContext<SecurityModel>, { licenseToken }: UpdateLicenseToken): void {
    ctx.setState({
      ...ctx.getState(),
      licenseToken
    });
  }

  @Action(UpdateOtpSecret)
  public updateOtpSecret(ctx: StateContext<SecurityModel>, { otpSecret }: UpdateOtpSecret): void {
    ctx.setState({
      ...ctx.getState(),
      otpSecret
    });
  }

}
