import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { LocalizationService } from '@tallence/localization';
import { SelectConfig } from 'src/app/models/select.model';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  @Input()
  public config: SelectConfig = new SelectConfig();
  @Input()
  public required?: string;
  @Input()
  public multiple?: string;

  constructor(
    private _localizationService: LocalizationService
  ) { }

  public ngOnInit(): void {
    this.config.multiple = this.multiple !== undefined;
    if (this.required !== undefined) {
      this.config.control.setValidators(Validators.required);
    }
  }

  public getErrorMessage(): string {
    if (this.config.control.hasError('required')) {
      if (this.required) {
        return this._localizationService.getTranslation(this.required);
      } else {
        return this._localizationService.getTranslation('input.error.required', [this.config.label]);
      }
    }
    return '';
  }

}
