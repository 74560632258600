import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { interval } from 'rxjs';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { AccountModel, StateModel } from 'src/app/models/store.model';
import { AuthService } from 'src/app/services/auth.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';
import { Helper } from 'src/app/utilities/helper';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends GarbageCollectorComponent implements OnInit {

  public auth = false;
  public showPrint = false;
  public name?: string;
  public image?: string;
  public timeout = 0;
  public warningTime = 5 * 60 * 1000;
  public formattedTime = '';
  private _hours = 12;

  constructor(
    private _store: Store,
    private _router: Router,
    private _authService: AuthService,
    private _cookieService: CookieService,
    private _dialog: MatDialog
  ) {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this._store.select((state: StateModel) => state.security.auth).subscribe((auth: boolean) => {
        this.auth = auth;
      })
    );
    this.addSubscription(
      this._store.select((state: StateModel) => state.account).subscribe((account: AccountModel) => {
        this.name = `${account.firstName} ${account.lastName}`.trim();
        this.image = account.image ? `url(${account.image})` : '';
      })
    );
    this.addSubscription(
      this._store.select((state: StateModel) => state.security.timeout).subscribe((timeout: number) => {
        if (timeout) {
          this.timeout = timeout;
        }
      })
    );
    this.addSubscription(
      interval(1000).subscribe(() => {
        this.timeout -= 1000;
        this.formattedTime = moment(this.timeout).format('mm:ss');
      })
    );
    this.showPrint = this._router.url.includes('handout');
  }

  public goTo(path: string): void {
    this._router.navigate([path]);
  }

  public extendTime(): void {
    this._authService.resetAutoLogout();
  }

  public lock(): void {
    if (this._cookieService.check('secret')) {
      this.goTo('logout');
    } else {
      const dialogData: DialogConfig = new DialogConfig(
        DialogType.CODE,
        'dialog.createPin.headline',
        ['dialog.createPin.description', `${this._hours}`],
      );
      dialogData.values = {
        codeSize: 4,
        icon: 'lock'
      };
      const dialogRef = this._dialog.open(DialogComponent, {
        ...dialogData.layout(),
        data: dialogData
      });

      this.addSubscription(
        dialogRef.afterClosed().subscribe((code: string) => {
          const token = this._authService.getRefreshToken() || '';
          if (code && token !== '') {
            const encrypted = Helper.encrypt(code, token);
            this._cookieService.set('secret', encrypted, {
              expires: moment().add(this._hours, 'h').toDate(),
              sameSite: 'Strict'
            });
            this.goTo('logout');
          }
        })
      );
    }
  }

  public logout(): void {
    this._cookieService.delete('secret');
    this.goTo('logout');
  }

  public print(): void {
    window.print();
  }

}
