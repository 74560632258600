<div class="slider">
  <label class="slider__label" [for]="config.id">{{config.label | translate}}</label>
  <div class="slider__control">
    <mat-slider
      color="accent"
      [min]="config.min"
      [max]="config.max"
      [step]="config.step"
      [value]="current"
      (input)="changeValue($event)"
      (change)="changeValue($event)"
    ></mat-slider>
    <div class="slider__input">
      <div class="slider__input-unit">
        <span class="slider__input-unit--hidden">{{config.control.value}}</span>
        <span>{{config.unit}}</span>
      </div>
      <input [id]="config.id" class="slider__input-value" type="text" (blur)="cleanUpValue()" [formControl]="config.control">
    </div>
    <app-icon class="slider__reset" (click)="reset()" icon="settings_backup_restore"></app-icon>
  </div>
</div>

