<div class="register-view row">
  <app-welcome></app-welcome>
  <div class="register-view__content">
    <app-content>
      <app-headline icon="badge">{{ 'register.headline' | translate }}</app-headline>
      <app-card>
        <mat-horizontal-stepper [linear]="true" #stepper>

          <!-- STEP: REGISTER -->
          <mat-step [stepControl]="registerForm">
            <ng-template matStepLabel>{{ headline }}</ng-template>
            <p>{{ description }}</p>

            <form>
              <div class="row">
                <app-autocomplete class="flex" required [config]="salutationConfig"></app-autocomplete>
                <div class="flex"></div>
              </div>
              <div class="row">
                <app-input class="flex" required [config]="firstNameConfig"></app-input>
                <app-input class="flex" required [config]="lastNameConfig"></app-input>
              </div>
              <div class="row">
                <app-date-picker class="flex" required [max]="maxDate" [config]="birthDateConfig"></app-date-picker>
                <app-input *ngIf="photographerEmail !== ''" class="flex" required readonly [config]="emailConfig"></app-input>
                <app-input *ngIf="photographerEmail === ''" class="flex" required [config]="emailConfig"></app-input>
              </div>
              <div *ngIf="role === 'PHOTOGRAPHER'" class="row">
                <app-input class="flex" required [config]="companyConfig"></app-input>
                <app-autocomplete class="flex" required [config]="companyLegalFormConfig"></app-autocomplete>
              </div>
              <div *ngIf="role === 'PHOTOGRAPHER'" class="row">
                <app-input class="flex" [config]="websiteConfig"></app-input>
                <div class="flex"></div>
              </div>
              <div class="row">
                <app-input class="flex" required [config]="phone1Config"></app-input>
                <app-input class="flex" [config]="phone2Config"></app-input>
              </div>
            </form>

            <div class="actions">
              <i class="flex">{{ 'required-fields' | translate }}</i>
              <app-button primary [disabled]="!steps.register.valid" (action)="stepper.next()">{{ 'button.next' | translate }}</app-button>
            </div>
          </mat-step>

          <!-- STEP: STUDIOS -->
          <mat-step *ngIf="steps.studios.enabled" [stepControl]="studiosControl">
            <ng-template matStepLabel>{{ 'register.step.studio.headline' | translate }}</ng-template>
            <p>{{ 'register.step.studio.description' | translate }}</p>

            <app-table sortable [config]="studiosConfig" (action)="onAction($event)"></app-table>

            <div class="actions">
              <app-button icon="home-plus-outline" (action)="addStudio()">{{ 'button.addStudio' | translate }}</app-button>
              <div class="flex"></div>
              <app-button secondary (action)="stepper.previous()">{{ 'button.back' | translate }}</app-button>
              <app-button primary [disabled]="studios.length === 0" (action)="stepper.next()">{{ 'button.next' | translate }}</app-button>
            </div>
          </mat-step>

          <!-- STEP: UPLOAD -->
          <mat-step [stepControl]="fileConfig.control">
            <ng-template matStepLabel>{{ 'register.step.upload.headline' | translate }}</ng-template>

            <ng-container *ngIf="role === 'PHOTOGRAPHER'">
              <p>{{ 'register.step.upload.description.member' | translate }}</p>
              <div class="row register-view__member-margin">
                <app-input class="flex" [config]="memberConfig"></app-input>
                <div class="flex"></div>
              </div>
            </ng-container>

            <p *ngIf="role === 'PHOTOGRAPHER'">{{ 'register.step.upload.description.file' | translate }}</p>
            <p *ngIf="role === 'PHOTOGRAPHER'"><b>{{ 'register.step.upload.description.exception' | translate }}</b></p>
            <p *ngIf="role !== 'PHOTOGRAPHER'">{{ 'register.step.upload.description.employee' | translate }}</p>

            <app-file-upload multiple required="input.error.fileUpload" [config]="fileConfig"></app-file-upload>
            <div class="actions">
              <app-button secondary (action)="stepper.previous()">{{ 'button.back' | translate }}</app-button>
              <app-button primary [disabled]="role !== 'PHOTOGRAPHER' && fileConfig.control.value.length === 0" (action)="stepper.next()">{{ 'button.next' | translate }}</app-button>
            </div>
          </mat-step>

          <!-- STEP: CONFIRM -->
          <mat-step>
            <ng-template matStepLabel>{{ 'register.step.confirm.headline' | translate }}</ng-template>
            <p>{{ 'register.step.confirm.description' | translate }}</p>

            <div class="register-view__summary">
              <app-data-row [configs]="[salutationConfig, firstNameConfig, lastNameConfig]"></app-data-row>
              <app-data-row *ngIf="role === 'PHOTOGRAPHER'" [configs]="[companyConfig, companyLegalFormConfig]"></app-data-row>
              <app-data-row *ngIf="role === 'PHOTOGRAPHER'" [configs]="[emailConfig, websiteConfig]"></app-data-row>
              <app-data-row *ngIf="role === 'STUDIO_EMPLOYEE'" [configs]="[emailConfig]"></app-data-row>
              <app-data-row [configs]="[phone1Config, phone2Config]"></app-data-row>
              <ng-container *ngIf="role === 'PHOTOGRAPHER'">
                <ng-container *ngFor="let studio of studios; index as i">
                  <app-data-row [studio]="[studio, (i+1)]"></app-data-row>
                </ng-container>
              </ng-container>
              <app-data-row *ngIf="role === 'PHOTOGRAPHER'" [configs]="[memberConfig]"></app-data-row>
              <app-data-row [files]="fileConfig"></app-data-row>
            </div>
            <div class="register-view__confirm">{{ 'register.step.confirm.explanation' | translate }}</div>
            <app-checkbox required [config]="dsgvoConfirmConfig"></app-checkbox>
            <app-checkbox required [config]="uigConfirmConfig"></app-checkbox>
            <app-checkbox required [config]="bmiConfirmConfig"></app-checkbox>
            <app-checkbox required [config]="legalNoteConfirmConfig"></app-checkbox>
            <div class="register-view__documents">
              <a href="./assets/documents/AGB RINGFOTO.pdf" download>
                <mat-icon>download</mat-icon>
                <span>{{ 'register.terms' | translate }}</span>
              </a>
              <a href="./assets/documents/DSE Registierung.pdf" download>
                <mat-icon>download</mat-icon>
                <span>{{ 'register.privacy' | translate }}</span>
              </a>
            </div>
            <div *ngIf="registerError" class="register-view__error">
              <mat-error>{{ registerError }}</mat-error>
            </div>
            <div class="actions">
              <i class="flex">{{ 'required-fields' | translate }}</i>
              <app-button secondary [disabled]="uploadProgress > 0" (action)="stepper.previous()">{{ 'button.back' | translate }}</app-button>
              <app-button primary *ngIf="uploadProgress === 0" [disabled]="!steps.confirm.valid" (action)="register()">{{ 'button.register' | translate }}</app-button>
              <app-button primary *ngIf="uploadProgress > 0" [disabled]="true">{{ 'button.sendProgress' | translate: [uploadProgress] }}</app-button>
            </div>
          </mat-step>
        </mat-horizontal-stepper>

      </app-card>

    </app-content>
  </div>
</div>
