import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { StateModel } from '../models/store.model';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private _auth = false;
  private _wildcards: string[] = ['/login', '/password', '/register'];

  constructor(
      private _router: Router,
      private _store: Store,
      private _authService: AuthService
  ) {
    this._store.select((state: StateModel) => state.security.auth).subscribe((auth) => {
      this._auth = auth;
    });
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const wildcard: boolean = this._wildcards.find((path: string) => {
      return state.url.includes(path);
    }) !== undefined;
    if (this._auth) {
        if (wildcard) {
          this._router.navigate(['overview']);
        }
        if (state.url.includes('/logout')) {
          this._authService.logout();
        }
        return true;
    }
    if (wildcard) {
      return true;
    } else {
      this._router.navigate(['login']);
      return false;
    }

  }
}
