import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NavigationItem, NavigationType, Role } from 'src/app/models/security.model';
import { StateModel } from 'src/app/models/store.model';
import { navigationItems } from 'src/app/routing/navigation-items';
import { UpdateSmallSidenav } from 'src/app/store/security/security.actions';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends GarbageCollectorComponent implements OnInit {

  public small = true;
  private _route = '';
  public avaibleItems: NavigationItem[] = [];

  constructor(
    private _store: Store,
    private _router: Router,
  ) {
    super();
  }

  public ngOnInit(): void {

    this._route = this._router.url.split('/').find(path => path) || '';
    this.addSubscription(
      this._router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this._route = event.url.split('/').find(path => path) || '';
        }
      })
    );

    this.addSubscription(
      this._store.select((state: StateModel) => state.security.smallSidenav).subscribe((smallSidenav: boolean) => {
        this.small = smallSidenav;
      })
    );
    this.addSubscription(
      this._store.select((state: StateModel) => state.security.role).subscribe((role: Role) => {
        this.avaibleItems = navigationItems.filter((item: NavigationItem) => {
          return item.authorized.includes(role) && item.type.includes(NavigationType.SIDENAV);
        });
        this.avaibleItems.sort((a, b) => {
          return a.order - b.order;
        });
      })
    );
  }

  public toggleSize(): void {
    this._store.dispatch(new UpdateSmallSidenav(!this.small));
  }

  public routeTo(route: string[]): void {
    this._router.navigate(route);
  }

  public isActive(route: string): boolean {
    return route.includes(this._route);
  }

}
