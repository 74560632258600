import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { LocalizationService } from '@tallence/localization';
import { PaginatorConfig, TableAction, TableActionType, TableConfig, UserStatus } from 'src/app/models/administration.model';
import { Role } from 'src/app/models/security.model';
import { IconService } from 'src/app/services/icon.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {

  public tableConfig = new TableConfig();
  private _items: any[] = [];
  private _sortedItems: any[] = [];

  @Input()
  public set config(cfg: TableConfig) {
    this._items = cfg.items.map(item => {
      const role = item.role || Role.NONE;
      const status = item.status || UserStatus.UNKNOWN;
      return {
        ...item,
        userRole: role,
        role: this._localizationService.getTranslation(`role.${role}`),
        userStatus: status,
        status: this._localizationService.getTranslation(`status.${status}`)
      };
    });
    this.tableConfig = {
      ...cfg,
      items: [...this._items]
    };
    this._sortedItems = [...this._items];
    if (cfg.paginator.total === 0) {
      this.tableConfig.paginator.total = this._items.length;
    }
    if (this.paginator !== undefined && this.paginator !== 'true') {
      this.pageChanged(this.tableConfig.paginator);
    }
    if (this.paginator === 'true' && cfg.paginator.total > cfg.paginator.size)  {
      this.tableConfig.items = this._sortedItems.slice(0, cfg.paginator.size);
    }
  }
  @Input()
  public paginator?: string;
  @Input()
  public sortable?: string;

  @Output()
  public changed: EventEmitter<TableConfig> = new EventEmitter();
  @Output()
  public action: EventEmitter<TableAction> = new EventEmitter();

  constructor(
    private _iconService: IconService,
    private _localizationService: LocalizationService,
  ) { }

  public doAction(name: TableActionType, item: any): void {
    if (name !== TableActionType.NONE) {
      this.action.emit(new TableAction(name, item[this.tableConfig.actionId]));
    }
  }

  public sortData(sort: Sort, localSort: boolean = false): void {
    this.tableConfig.sort = sort;
    if (this.sortable === 'true' && !localSort) {
      this.changed.emit(this.tableConfig);
    } else {
      this.tableConfig.items = [...this._items];
      if (this.tableConfig.sort.direction !== '' && this.tableConfig.items.length) {
        this.tableConfig.items.sort((a, b) => {
          const itemA = a[this.tableConfig.sort.active].toUpperCase();
          const itemB = b[this.tableConfig.sort.active].toUpperCase();
          if (this.tableConfig.sort.direction === 'asc') {
            return (itemA < itemB) ? -1 : (itemA > itemB) ? 1 : 0;
          } else {
            return (itemA > itemB) ? -1 : (itemA < itemB) ? 1 : 0;
          }
        });
      }
      this._sortedItems = [...this.tableConfig.items];
      this.tableConfig.paginator.current = 1;
      if (this.paginator !== undefined && this.paginator !== 'true') {
        this.pageChanged(this.tableConfig.paginator);
      }
    }
  }

  public pageChanged(paginator: PaginatorConfig): void {
    this.tableConfig.paginator = paginator;
    if (this.paginator === 'false') {
      this.changed.emit(this.tableConfig);
    } else {
      const from = Math.max(paginator.current - 1, 0) * paginator.size;
      this.tableConfig.items = this._sortedItems.slice(from, from + paginator.size);
    }
  }

  public isOwn(action: string): boolean {
    return this._iconService.isOwnIcon(action);
  }

  public enableAction(item: any, action: TableActionType): boolean {
    if (Reflect.has(item, 'userRole') && Reflect.has(item, 'userStatus')) {
      const isAdmin = Reflect.get(item, 'userRole') === Role.ADMIN;
      const isActive = Reflect.get(item, 'userStatus') === UserStatus.ACTIVE;
      const isDeleted = Reflect.get(item, 'userStatus') === UserStatus.DELETED;
      const isDeactivated = Reflect.get(item, 'userStatus') === UserStatus.DEACTIVATED;
      switch (action) {
        case TableActionType.DELETE:
          if (isDeleted || isDeactivated) {
            return false;
          }
          break;
        case TableActionType.DEACTIVATE:
          if (isAdmin || !isActive) {
            return false;
          }
          break;
        case TableActionType.REACTIVATE:
          if (isAdmin || !isDeleted && !isDeactivated) {
            return false;
          }
          break;
        case TableActionType.EDIT:
          if (!isActive) {
            return false;
          }
          break;
        default:
          break;
      }
    }
    return true;
  }

}
