import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from 'src/app/routing/routes';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from 'src/app/routing/api.interceptor';
import { Store } from '@ngxs/store';

import { ErrorViewComponent } from '../views/error-view/error-view.component';
import { LoginViewComponent } from '../views/login-view/login-view.component';
import { OverviewViewComponent } from '../views/overview-view/overview-view.component';
import { PasswordViewComponent } from '../views/password-view/password-view.component';
import { RegisterViewComponent } from '../views/register-view/register-view.component';
import { ComponentsModule } from './components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { LocalizationModule, LocalizationService } from '@tallence/localization';
import { AccountViewComponent } from '../views/account-view/account-view.component';
import { EmployeesViewComponent } from '../views/employees-view/employees-view.component';
import { UigEmployeesViewComponent } from '../views/uig-employees-view/uig-employees-view.component';
import { HardwareProvidersViewComponent } from '../views/hardware-providers-view/hardware-providers-view.component';
import { UsersViewComponent } from '../views/users-view/users-view.component';
import { UigEmployeeViewComponent } from '../views/uig-employee-view/uig-employee-view.component';
import { HardwareProviderViewComponent } from '../views/hardware-provider-view/hardware-provider-view.component';
import { UserViewComponent } from '../views/user-view/user-view.component';
import { CamerasViewComponent } from '../views/cameras-view/cameras-view.component';
import { ApplicationsViewComponent } from '../views/applications-view/applications-view.component';
import { ApplicationViewComponent } from '../views/application-view/application-view.component';
import { AuthService } from '../services/auth.service';
import { ConsoleService } from '../services/console.service';
import { QRCodeModule } from 'angularx-qrcode';
import { HandoutViewComponent } from '../views/handout-view/handout-view.component';
import { CompaniesViewComponent } from '../views/companies-view/companies-view.component';
import { CompanyViewComponent } from '../views/company-view/company-view.component';
import { ConfigService } from '../services/config.service';
import { EditorViewComponent } from '../views/editor-view/editor-view.component';
import { AnalyticsViewComponent } from '../views/analytics-view/analytics-view.component';
import { ArchiveViewComponent } from '../views/archive-view/archive-view.component';

@NgModule({
  declarations: [
    ErrorViewComponent,
    LoginViewComponent,
    OverviewViewComponent,
    PasswordViewComponent,
    RegisterViewComponent,
    AccountViewComponent,
    EmployeesViewComponent,
    UigEmployeesViewComponent,
    HardwareProvidersViewComponent,
    UsersViewComponent,
    UigEmployeeViewComponent,
    HardwareProviderViewComponent,
    UserViewComponent,
    CamerasViewComponent,
    ApplicationsViewComponent,
    ApplicationViewComponent,
    HandoutViewComponent,
    CompaniesViewComponent,
    CompanyViewComponent,
    EditorViewComponent,
    AnalyticsViewComponent,
    ArchiveViewComponent,
  ],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    LocalizationModule,
    MaterialModule,
    QRCodeModule
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
      deps: [Store, AuthService, ConsoleService, LocalizationService, ConfigService]
    },
  ]
})
export class RoutingModule { }
