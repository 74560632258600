export enum DialogType {
  CONFIRM = 'confirm',
  CONFLICT = 'conflict',
  ADD_EMPLOYEE = 'add_employee',
  ADD_UIG_EMPLOYEE = 'add_uig_employee',
  ADD_HARDWARE_PROVIDER = 'add_hardware_provider',
  ADD_CAMERA = 'add_camera',
  UPDATE_CAMERA = 'update_camera',
  ADD_STUDIO = 'add_studio',
  UPDATE_STUDIO = 'update_studio',
  DELETE_ACCOUNT = 'delete_account',
  ACCOUNT_DELETED = 'account_deleted',
  REJECT_APPLICATION = 'reject_application',
  SUCCESS = 'success',
  FAIL = 'fail',
  SEARCH_COMPANY = 'search_company',
  CODE = 'code',
  FORCE_SAVE = 'force_save',
  VALIDATION_ERRORS = 'validation_errors',
  PASSWORD = 'password',
  BLOCK = 'block'
}

export class DialogConfig {
  public type: DialogType;
  public headline: string;
  public description: string[];
  public okButton: string;
  public cancelButton: string;
  public values: any = {};
  constructor(
    type: DialogType,
    headline: string = '',
    description: string[] = [],
    okButton: string = 'button.ok',
    cancelButton: string = 'button.cancel'
  ) {
    this.type = type;
    this.headline = headline;
    this.description = description;
    this.okButton = okButton;
    this.cancelButton = cancelButton;
  }

  public static smallSize(): string {
    return '500px';
  }
  public static largeSize(): string {
    return '800px';
  }

  public clone(): DialogConfig {
    const config = new DialogConfig(
      this.type,
      this.headline,
      this.description,
      this.okButton,
      this.cancelButton
    );
    return config;
  }

  public layout(): any {
    const layout: any = {
      minWidth: '200px',
      width: '100%',
      maxWidth: '500px',
    };
    switch (this.type) {
      case DialogType.ADD_STUDIO:
      case DialogType.UPDATE_STUDIO:
      case DialogType.ADD_CAMERA:
      case DialogType.ADD_EMPLOYEE:
      case DialogType.ADD_UIG_EMPLOYEE:
      case DialogType.ADD_HARDWARE_PROVIDER:
      case DialogType.REJECT_APPLICATION:
      case DialogType.SEARCH_COMPANY:
        layout.maxWidth = '800px';
        break;
      default:
        break;
    }
    return layout;
  }

}
