export enum Role {
  NONE = 'NONE',
  ADMIN = 'UIG_ADMIN',
  PHOTOGRAPHER = 'PHOTOGRAPHER',
  EMPLOYEE = 'STUDIO_EMPLOYEE',
  HARDWARE_PROVIDER = 'HARDWARE_PROVIDER',
  HARDWARE_PROVIDER_ADMIN = 'HARDWARE_PROVIDER_ADMIN',
  UIG = 'UIG_EMPLOYEE',
}

export enum NavigationType {
  SIDENAV = 'sidenav',
  MENU = 'menu'
}

export interface NewPassword {
  jwToken: string;
  password: string;
}

export interface NavigationItem {
  authorized: Role[];
  type: NavigationType[];
  icon: string;
  label: string;
  route: string[];
  order: number;
}
