import { Component, Input, OnInit } from '@angular/core';
import { CheckboxConfig } from 'src/app/models/checkbox.model';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends GarbageCollectorComponent implements OnInit {

  @Input()
  public config: CheckboxConfig = new CheckboxConfig('');
  @Input()
  public required?: string;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    if (this.required !== undefined) {
      this.config.control.setErrors({missing: true});
      this.addSubscription(
        this.config.control.valueChanges.subscribe((value: boolean) => {
          this.config.control.setErrors(value ? null : {missing: value});
        })
      );
    }
  }

}
