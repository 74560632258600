<div
  class="photos-editor"
  (mouseenter)="mouseover = true"
  (mouseleave)="mouseover = false"
>
  <div class="row">
    <div class="flex photos-editor__main">
      <div
        #canvas
        class="photos-editor__canvas"
        (pan)="moveOnCanvas($event)"
        (panend)="checkMask(); stopDragging();"
        (rotatestart)="startTransformation($event)"
        (rotate)="transform($event)"
        (rotateend)="endTransformation($event)"
      >
        <div class="photos-editor__image-wrapper">
          <div #imageFrame class="photos-editor__image-frame">
            <div
              #image
              *ngIf="photo"
              class="photos-editor__image"
              [ngClass]="{'photos-editor__image--smoothing': mouseover}"
              [ngStyle]="{'background-image': 'url(' + photo.preview + ')'}"
            ></div>
          </div>
        </div>
        <div
          #boundaries
          class="photos-editor__boundaries"
        >
          <canvas #shadowMask class="photos-editor__shadow-mask"></canvas>
          <div
            #overlay
            class="photos-editor__overlay"
          >
            <div class="photos-editor__mask" [ngClass]="{'photos-editor__mask--invalid': invalidMask[0] || invalidMask[1] || invalidMask[2] || invalidMask[3]}">
              <div *ngIf="showTemplate && templateConfig.control.value === 'adult'" class="photos-editor__template photos-editor__template--adult"></div>
              <div *ngIf="showTemplate && templateConfig.control.value === 'child'" class="photos-editor__template photos-editor__template--child"></div>
              <div
                class="photos-editor__drag-area"
                (panstart)="startDragging()"
                (panend)="stopDragging()"
              ></div>
              <div
                class="photos-editor__cap photos-editor__cap-tl"
                [ngClass]="{'photos-editor__cap--invalid': invalidMask[0], 'photos-editor__cap--big': !mouseover}"
                (panstart)="lookCorner('tl')"
                (panend)="unlookCorner();"
              ></div>
              <div
                class="photos-editor__cap photos-editor__cap-tr"
                [ngClass]="{'photos-editor__cap--invalid': invalidMask[1], 'photos-editor__cap--big': !mouseover}"
                (panstart)="lookCorner('tr')"
                (panend)="unlookCorner();"
              ></div>
              <div
                class="photos-editor__cap photos-editor__cap-br"
                [ngClass]="{'photos-editor__cap--invalid': invalidMask[2], 'photos-editor__cap--big': !mouseover}"
                (panstart)="lookCorner('br')"
                (panend)="unlookCorner();"
              ></div>
              <div
                class="photos-editor__cap photos-editor__cap-bl"
                [ngClass]="{'photos-editor__cap--invalid': invalidMask[3], 'photos-editor__cap--big': !mouseover}"
                (panstart)="lookCorner('bl')"
                (panend)="unlookCorner();"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="photos-editor__control">
      <app-slider [config]="zoomConfig"></app-slider>
      <app-slider [config]="rotationConfig"></app-slider>
      <app-slider [config]="brightnessConfig"></app-slider>
      <app-slider [config]="contrastConfig"></app-slider>
      <div class="photos-editor__template-select">
        <app-select class="flex" [config]="templateConfig"></app-select>
        <app-icon class="photos-editor__template-visibility" (click)="showTemplate = !showTemplate" [icon]="showTemplate ? 'visibility' : 'visibility_off'"></app-icon>
      </div>
      <div class="photos-editor__actions">
        <div class="photos-editor__action photos-editor__action--secondary">
          <app-button secondary (action)="goBack()">{{'button.cancel' | translate}}</app-button>
        </div>
        <div class="photos-editor__action">
          <app-button primary [disabled]="locked" (action)="applyChanges()">{{'button.apply' | translate}}</app-button>
        </div>
      </div>
    </div>
  </div>

</div>
