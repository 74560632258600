import { Application, Camera, Company, Employee, HardwareProvider, Photographer, Studio, User } from './administration.model';
import { Role } from './security.model';

export interface SecurityModel {
  auth: boolean;
  role: Role;
  smallSidenav: boolean;
  timeout: number;
  licenseToken: string;
  otpSecret: string;
}

export class AccountModel {
  public loaded = false;
  public image = '';
  public salutation = '';
  public firstName = '';
  public lastName = '';
  public nationality = '';
  public idCardNumber = '';
  public company = '';
  public companyLegalForm = '';
  public email = '';
  public website = '';
  public phone1 = '';
  public phone2 = '';
  public address = '';
  public zip = '';
  public location = '';
}

export interface StateModel {
  security: SecurityModel;
  account: AccountModel;
  administration: AdministrationModel;
}

export interface UserItems {
  items: User[];
  total: number;
}

export interface CameraItems {
  items: Camera[];
  total: number;
}

export interface CompanyItems {
  items: Company[];
  total: number;
}

export interface StudioItems {
  items: Studio[];
  total: number;
}

export interface AdministrationModel {
  employees: Employee[];
  photographer: Photographer[];
  cameras: CameraItems;
  hardwareProviders: HardwareProvider[];
  applications: Application[];
  users: UserItems;
  companies: CompanyItems;
  studios: StudioItems;
}
