import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent extends GarbageCollectorComponent implements OnInit {

  @Input()
  public config: InputConfig = new InputConfig(InputType.NONE, '');

  public searched = false;

  @Output()
  public action: EventEmitter<string> = new EventEmitter();

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this.config.control.valueChanges.subscribe((value) => {
        if (value === null || (this.searched && value === '')) {
          this.searched = false;
        }
      })
    );
  }

  public search(): void {
    this.searched = this.config.control.value !== '' && this.config.control.value !== null;
    if (this.searched) {
      this.action.emit(this.config.control.value || '');
    }
  }
  public reset(): void {
    this.action.emit('');
    this.config.control.reset();
  }

}
