import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { LocalizationService } from '@tallence/localization';
import { fromEvent } from 'rxjs';
import { TableAction, TableActionType, TableConfig } from 'src/app/models/administration.model';
import { FileUploadConfig } from 'src/app/models/file-upload.model';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends GarbageCollectorComponent implements OnInit, AfterViewInit {

  @Input()
  public config: FileUploadConfig = new FileUploadConfig('');
  @Input()
  public required?: string;
  @Input()
  public multiple?: string;
  @Input()
  public accept?: string;

  @ViewChild('inputElement')
  public inputRef!: ElementRef;
  @ViewChild('dropboxElement')
  public dropboxRef!: ElementRef;

  public fileInput = '';
  private _files: File[] = [];
  public highlighted = false;
  private _accept: string[] = ['.jpg', '.JPG', '.jpeg', '.png', '.PNG', '.pdf', '.PDF'];
  public attachmentsConfig: TableConfig = new TableConfig();
  private _maxFileSize = 2 * 1024 * 1024; // MB

  constructor(
    private _localizationService: LocalizationService,
    private _snackbarService: SnackbarService
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.required !== undefined) {
      this.config.control.setValidators(Validators.required);
    }
    if (this.accept !== undefined) {
      this.accept.split(',').forEach((type: string) => {
        this._accept.push(`.${type.replace(/^\./, '')}`);
      });
    }
    this.attachmentsConfig.header = ['attachment'];
    this.attachmentsConfig.actions = [TableActionType.DELETE];
    this.attachmentsConfig.actionId = 'id';
  }

  public ngAfterViewInit(): void {
    if (this.multiple !== undefined) {
      this.inputRef.nativeElement.setAttribute('multiple', 'true');
    }
    if (this.accept !== undefined) {
      this.inputRef.nativeElement.setAttribute('accept', this._accept.join(','));
    }
    this._initDropbox();
  }

  private _initDropbox(): void {
    const dropbox: HTMLElement = this.dropboxRef.nativeElement as HTMLElement;
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.addSubscription(
        fromEvent(dropbox, eventName).subscribe((event) => {
          event.preventDefault();
          event.stopPropagation();
        })
      );
    });
    ['dragenter', 'dragover'].forEach(eventName => {
      this.addSubscription(
        fromEvent(dropbox, eventName).subscribe(() => {
          this.highlighted = true;
        })
      );
    });
    ['dragleave', 'drop'].forEach(eventName => {
      this.addSubscription(
        fromEvent(dropbox, eventName).subscribe((event) => {
          if (eventName === 'drop') {
            const data: DataTransfer | null = (event as DragEvent).dataTransfer;
            const files: FileList | undefined = data?.files;
            this._handleFiles(files);
            this.highlighted = false;
          }
        })
      );
    });
  }

  public onFileChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target?.files?.length) {
      const files: FileList = target.files;
      this._handleFiles(files);
    }
    this.fileInput = '';
  }

  private _handleFiles(files: FileList | undefined): void {
    Array.from(files || []).forEach((file: File) => {
      const match = this._files.find(f => f.name === file.name);
      const type: string = file.name.match(/\.[a-zA-Z0-9]+$/)?.[0] || 'none';
      console.log('type. ', type, file.name.match(/\.[a-zA-Z0-9]+$/)?.[0]);
      const oversize = file.size > this._maxFileSize;
      if (oversize) {
        this._snackbarService.showWarning(['snackbar.oversize', file.name]);
      }
      if (!this._accept.includes(type)) {
        this._snackbarService.showWarning(['snackbar.type']);
      }
      if (!match && !oversize && (this._accept.includes(type) || this._accept.length === 0)) {
        if (this.multiple !== undefined) {
          this._files.push(file);
        } else {
          this._files = [file];
        }
      }
      this._updateControl();
    });
  }

  private _updateControl(): void {
    this.attachmentsConfig.items = this._files.map((file) => {
      return {
        attachment: file.name,
        id: file.name
      };
    });
    this.config.control.setValue(this._files);
    this.attachmentsConfig = {...this.attachmentsConfig};
  }

  public onAction(action: TableAction): void {
    switch (action.name) {
      case TableActionType.DELETE:
        this._files = this._files.filter(f => f.name !== action.id);
        this._updateControl();
        break;
      default:
        break;
    }
  }

  public getErrorMessage(): string {
    if (this.config.control.hasError('required')) {
      if (this.required) {
        return this._localizationService.getTranslation(this.required);
      } else {
        return this._localizationService.getTranslation('input.error.required', [this.config.label]);
      }
    }
    return '';
  }

}
