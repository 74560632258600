<app-content>
  <div class="account-view">
    <app-headline icon="account-edit">{{ 'account.headline' | translate }}</app-headline>
    <app-card *ngIf="!profileLoaded" headline="account.error.notAvailable" icon="warning">
      <div class="actions">
        <app-button secondary (action)="reloadProfile()">{{ 'button.reload' | translate }}</app-button>
      </div>
    </app-card>
    <app-card *ngIf="profileLoaded" headline="account.profile.headline" icon="face">
      <form>
        <!-- <div>
          <app-avatar (changed)="avatarChanged($event)"></app-avatar>
        </div> -->
        <div class="row">
          <app-autocomplete class="flex" required [config]="salutationConfig"></app-autocomplete>
          <div class="flex"></div>
        </div>
        <div class="row">
          <app-input class="flex" required [config]="firstNameConfig"></app-input>
          <app-input class="flex" required [config]="lastNameConfig"></app-input>
        </div>

        <ng-container *ngIf="role === 'UIG_ADMIN' || role === 'UIG_EMPLOYEE'">
          <div class="row">
            <app-input class="flex" required readonly [config]="emailConfig"></app-input>
            <app-input class="flex" [config]="phone1Config"></app-input>
          </div>
        </ng-container>

        <ng-container *ngIf="role === 'PHOTOGRAPHER'">
          <div class="row">
            <app-autocomplete class="flex" required [config]="nationalityConfig"></app-autocomplete>
            <app-input class="flex" required [config]="idCardNumberConfig"></app-input>
          </div>
          <div class="row">
            <app-input class="flex" required [config]="companyConfig"></app-input>
            <app-autocomplete class="flex" required [config]="companyLegalFormConfig"></app-autocomplete>
          </div>
          <div class="row">
            <app-input class="flex" required readonly [config]="emailConfig"></app-input>
            <app-input class="flex" [config]="websiteConfig"></app-input>
          </div>
          <div class="row">
            <app-input class="flex" required [config]="phone1Config"></app-input>
            <app-input class="flex" [config]="phone2Config"></app-input>
          </div>
        </ng-container>

        <ng-container *ngIf="role === 'STUDIO_EMPLOYEE'">
          <div class="row">
            <app-autocomplete class="flex" required [config]="nationalityConfig"></app-autocomplete>
            <app-input class="flex" required readonly [config]="emailConfig"></app-input>
          </div>
          <div class="row">
            <app-input class="flex" required [config]="phone1Config"></app-input>
            <app-input class="flex" [config]="phone2Config"></app-input>
          </div>
        </ng-container>

        <ng-container *ngIf="role === 'HARDWARE_PROVIDER'">
          <div class="row">
            <app-input class="flex" required [config]="companyConfig"></app-input>
            <app-autocomplete class="flex" required [config]="companyLegalFormConfig"></app-autocomplete>
          </div>
          <div class="row">
            <app-input class="flex" required [config]="addressConfig"></app-input>
          </div>
          <div class="row">
            <app-input class="flex" required [config]="zipConfig"></app-input>
            <app-input class="flex" required [config]="locationConfig"></app-input>
          </div>
          <div class="row">
            <app-input class="flex" required readonly [config]="emailConfig"></app-input>
            <app-input class="flex" [config]="phone1Config"></app-input>
          </div>
        </ng-container>

      </form>
      <div class="actions">
        <i class="flex">{{ 'required-fields' | translate }}</i>
        <app-button primary [disabled]="profileLocked" (action)="changeProfile()">{{ 'button.applyChanges' | translate }}</app-button>
      </div>
    </app-card>

    <app-card headline="account.password.headline" icon="lock">
      <form>
        <div class="row">
          <app-input class="flex" required [config]="passwordConfig"></app-input>
          <div class="flex"></div>
        </div>
        <app-new-password *ngIf="newPasswordInput" [disabled]="!passwordConfig.control.value" (password)="getNewPassword($event)"></app-new-password>
      </form>
      <div class="account-view__error">
        <mat-error *ngIf="passwordError !== ''">{{ passwordError | translate }}</mat-error>
      </div>
      <div class="actions">
        <i class="flex">{{ 'required-fields' | translate }}</i>
        <app-button primary [disabled]="passwordLocked || newPassword === ''" (action)="changePassword()">{{ 'button.changePassword' | translate }}</app-button>
      </div>
    </app-card>

    <div *ngIf="role !== 'UIG_ADMIN'" class="account-view__delete">
      <app-button class="account-view__delete-button" simple (action)="deleteAccount()">{{ 'button.deleteAccount' | translate }}</app-button>
    </div>
  </div>
</app-content>
