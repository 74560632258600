import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { IconService } from 'src/app/services/icon.service';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  @Input()
  public icon!: string;

  public isOwn = false;
  public style: any = {};

  constructor(
    private _iconService: IconService,
    private _elRef: ElementRef
  ) { }

  public ngOnInit(): void {
    if (this.icon !== undefined) {
      this.isOwn = this._iconService.isOwnIcon(this.icon);
    }
    const el: HTMLElement = this._elRef.nativeElement as HTMLElement;
    const size = window.getComputedStyle(el).fontSize;
    el.style.height = size;
    el.style.width = size;
    el.style.minWidth = size;
    this.style = {
      fontSize: size,
      width: size,
      height: size
    };
  }

}
