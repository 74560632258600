import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { SearchQuery, TableAction, TableActionType, TableConfig, User } from 'src/app/models/administration.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { Role } from 'src/app/models/security.model';
import { StateModel, UserItems } from 'src/app/models/store.model';
import { UserService } from 'src/app/services/register/user.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-hardware-providers-view',
  templateUrl: './hardware-providers-view.component.html',
  styleUrls: ['./hardware-providers-view.component.scss']
})
export class HardwareProvidersViewComponent extends GarbageCollectorComponent implements OnInit {

  private _hardwareProviders: User[] = [];
  private _searchQuery: SearchQuery = new SearchQuery([Role.HARDWARE_PROVIDER, Role.HARDWARE_PROVIDER_ADMIN]);
  public tableConfig: TableConfig = new TableConfig();
  public searchConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.searchProvider.label');
  private _email: string = '';

  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _router: Router,
    private _userService: UserService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.tableConfig.header = ['role', 'firstName', 'lastName', 'email', 'status'];
    this.tableConfig.actions = [TableActionType.DEACTIVATE, TableActionType.REACTIVATE, TableActionType.EDIT];
    this.addSubscription(
      this._store.select((state: StateModel) => state.administration.users).subscribe((users: UserItems) => {
        this._hardwareProviders = users.items;
        this.tableConfig.items = this._hardwareProviders;
        this.tableConfig.paginator.total = users.total;
        this.tableConfig.paginator.size = this._searchQuery.pageLimit;
        this.tableConfig = {...this.tableConfig};
      })
    );
    const role: Role = this._store.selectSnapshot((state: StateModel) => state.security.role);
    if (role === Role.HARDWARE_PROVIDER_ADMIN) {
      this._email = this._store.selectSnapshot((state: StateModel) => state.account.email);
      this._searchQuery.invitor = this._email;
    }
    this._userService.getUsers(this._searchQuery);
  }

  public addProvider(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.ADD_HARDWARE_PROVIDER,
      'dialog.addHardwareProvider.headline',
      ['dialog.addHardwareProvider.description'],
      'button.add'
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });
    this.addSubscription(
      dialogRef.afterClosed().subscribe(success => {
        if (success === true) {
          this._userService.getUsers(this._searchQuery);
        }
      })
    );
  }

  private _deactivateProvider(provider: User): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CONFIRM,
      'dialog.deactivateHardwareProvider.headline',
      ['dialog.deactivateHardwareProvider.description', `${provider.firstName} ${provider.lastName}`]
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (result === true) {
            return this._userService.deleteUser(provider);
          }
          return of(result);
        })
      ).subscribe(success => {
        if (success === false) {
          this._removeFailed(provider);
        } else {
          this._userService.getUsers(this._searchQuery);
        }
      })
    );
  }

  private _removeFailed(provider: User): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.FAIL,
      'dialog.deactivateHardwareProvider.headline',
      ['dialog.deactivateHardwareProvider.failed', `${provider.firstName} ${provider.lastName}`]
    );
    this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });
  }

  private _reactivateProvider(provider: User): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CONFIRM,
      'dialog.reactivateHardwareProvider.headline',
      ['dialog.reactivateHardwareProvider.description', `${provider.firstName} ${provider.lastName}`]
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().pipe(
        switchMap((result) => {
          if (result === true) {
            return this._userService.reactivateUser(provider.email, this._searchQuery);
          }
          return of(result);
        })
      ).subscribe()
    );
  }

  public configChanged(config: TableConfig): void {
    this.searchConfig.control.reset();
    this._searchQuery.offset = (config.paginator.current - 1) * config.paginator.size;
    this._searchQuery.sort = config.sort;
    this.tableConfig = config;
    this._userService.getUsers(this._searchQuery);
  }

  public search(value: string): void {
    this._searchQuery = new SearchQuery([Role.HARDWARE_PROVIDER, Role.HARDWARE_PROVIDER_ADMIN]);
    this._searchQuery.text = value;
    this._searchQuery.invitor = this._email;
    this._userService.getUsers(this._searchQuery);
  }

  public onAction(action: TableAction): void {
    const hardwareProvider: User | undefined = this._hardwareProviders.find((provider: User) => provider.email === action.id);

    switch (action.name) {
      case TableActionType.DEACTIVATE:
        if (hardwareProvider) {
          this._deactivateProvider(hardwareProvider);
        }
        break;
      case TableActionType.EDIT:
        this._router.navigate(['hardware-provider', action.id]);
        break;
      case TableActionType.REACTIVATE:
        if (hardwareProvider) {
          this._reactivateProvider(hardwareProvider);
        }
        break;
      default:
        break;
    }
  }

}
