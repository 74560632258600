<app-content>
  <div class="employees-view">
    <div class="row">
      <app-headline class="flex" icon="group">{{ 'employees.headline' | translate }}</app-headline>
      <div class="headline-action">
        <app-button (action)="inviteEmployee()" icon="person_add">{{ 'button.inviteEmployee' | translate }}</app-button>
      </div>
    </div>
    <app-card *ngIf="tableConfig.items.length > 0">
      <div class="row">
        <app-input class="flex" postIcon="search" [config]="searchConfig"></app-input>
      </div>
      <app-table [config]="tableConfig" (changed)="configChanged($event)" (action)="onAction($event)"></app-table>
    </app-card>
    <app-card *ngIf="tableConfig.items.length === 0">
      {{ 'employees.empty' | translate }}
    </app-card>
    <div *ngIf="showLegend" class="employees-view__legend">
      <mat-icon svgIcon="account-cancel"></mat-icon> {{ 'employees.blacklisted' | translate }}
    </div>
  </div>
</app-content>
