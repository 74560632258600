import { FormControl } from '@angular/forms';

export enum InputType {
  NONE = 'none',
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
  PHONE = 'tel',
  DATE = 'date',
}

export class InputConfig {
  public control: FormControl;
  public type: InputType;
  public label: string;
  public placeholder: string;
  public hint: string;

  constructor(type: InputType, label: string, placeholder: string = '', hint: string = '') {
    this.control = new FormControl('');
    this.type = type;
    this.label = label;
    this.placeholder = placeholder;
    this.hint = hint;
  }
}
