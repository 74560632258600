<div *ngIf="messages.length !== 0" class="console">
  <div class="console__header">
    <div class="console__header-text">{{ 'console.headline' | translate }}</div>
    <mat-icon class="console__icon" (click)="clearAll()">delete_sweep</mat-icon>
  </div>
  <div class="console__content" #body>
    <div class="console__message" *ngFor="let message of messages">
      <mat-icon class="console__icon">error</mat-icon>
      <div class="console__time">{{ message.time }}</div>
      <div class="console__message-text">
        <div [innerHTML]="message.text"></div>
        <div *ngIf="message.subText" class="console__message-error-text">{{ message.subText }}</div>
      </div>
      <div *ngIf="message.duration" class="console__message-duration">
        <svg width="24" height="24" viewPort="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <circle stroke="#999999" stroke-width="4" r="8" cx="12" cy="12" fill="transparent" stroke-dasharray="50.266" stroke-dashoffset="0"></circle>
          <circle stroke="#E30613" stroke-width="4" r="8" cx="12" cy="12" [ngStyle]="{'animation': 'timeout '+message.duration+'s linear'}" fill="transparent" stroke-dasharray="50.266" stroke-dashoffset="0"></circle>
        </svg>
      </div>
      <mat-icon class="console__icon" (click)="clearMessage(message)">clear</mat-icon>
    </div>
  </div>
</div>
