import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { Camera, PaginatorConfig, ResponseStatus, SearchQuery, TableAction, TableActionType, TableConfig } from 'src/app/models/administration.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { CameraItems, StateModel } from 'src/app/models/store.model';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';
import * as moment from 'moment';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HardwareService } from 'src/app/services/register/hardware.service';

@Component({
  selector: 'app-cameras-view',
  templateUrl: './cameras-view.component.html',
  styleUrls: ['./cameras-view.component.scss']
})
export class CamerasViewComponent extends GarbageCollectorComponent implements OnInit {

  private _cameras: Camera[] = [];
  private _searchQuery: SearchQuery = new SearchQuery();
  public searchConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.searchCamera.label');
  public tableConfig: TableConfig = new TableConfig();

  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _hardwareService: HardwareService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.tableConfig.header = ['cameraManufacturer', 'cameraModel', 'cameraId', 'licenseBeginDate', 'licenseEndDate'];
    this.tableConfig.actions = [TableActionType.PUBLIC_KEY, TableActionType.LICENSE, TableActionType.DELETE];
    this.tableConfig.actionId = 'cameraId';
    this.addSubscription(
      this._store.select((state: StateModel) => state.administration.cameras).subscribe(
        (cameras: CameraItems) =>
      {
        if (cameras) {
          this._cameras = cameras.items.map((camera: Camera) => {
            return {
              ...camera,
              licenseBeginDate: moment(camera.licenseBeginDate).locale('de').format('L'),
              licenseEndDate: moment(camera.licenseEndDate).locale('de').format('L'),
            };
          });
          this.tableConfig.paginator.total = cameras.total;
          this.tableConfig.paginator.size = this._searchQuery.pageLimit;
          this.tableConfig.items = this._cameras;
          this.tableConfig = {...this.tableConfig};
        }
      })
    );
    this._hardwareService.getCameras(this._searchQuery);
  }

  public addCamera(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.ADD_CAMERA,
      'dialog.addCamera.headline',
      ['dialog.addCamera.description'],
      'button.add'
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });
    this.addSubscription(
      dialogRef.afterClosed().subscribe((success) => {
        if (success === true) {
          this.searchConfig.control.reset();
          this._searchQuery = new SearchQuery();
          this.tableConfig.paginator = new PaginatorConfig();
          this.tableConfig.sort = {active: '', direction: ''};
          this._hardwareService.getCameras(this._searchQuery);
        }
      })
    );
  }

  private _showPublicKey(key: string): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CONFIRM,
      'dialog.cameraInfo.headline',
      ['dialog.cameraInfo.description', atob(key)],
      'button.ok',
      ''
    );
    dialogData.values = {
      publicKey: true
    };
    this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });
  }

  private _changeLicense(camera: Camera): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.UPDATE_CAMERA,
      'dialog.updateCamera.headline',
      ['dialog.updateCamera.description', `${camera.cameraManufacturer} ${camera.cameraModel}`],
      'button.applyChanges'
    );
    dialogData.values = camera;
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().subscribe((success) => {
        if (success) {
          this._hardwareService.getCameras(this._searchQuery);
        }
      })
    );
  }

  private _removeCamera(camera: Camera): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CONFIRM,
      'dialog.removeCamera.headline',
      ['dialog.removeCamera.description', `${camera.cameraManufacturer} ${camera.cameraModel}`]
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (result === true) {
            return this._hardwareService.removeCamera(camera).pipe(
              map((response: ResponseStatus) => {
                return response === ResponseStatus.SUCCESS;
              })
            );
          }
          return of(result);
        })
      ).subscribe((success) => {
        if (success === false) {
          this._removeFailed(camera);
        } else {
          this._hardwareService.getCameras(this._searchQuery);
        }
      })
    );
  }

  private _removeFailed(camera: Camera): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.FAIL,
      'dialog.removeCamera.headline',
      ['dialog.removeCamera.failed', `${camera.cameraManufacturer} ${camera.cameraModel}`]
    );
    this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });
  }

  public configChanged(config: TableConfig): void {
    this._searchQuery.offset = (config.paginator.current - 1) * config.paginator.size;
    this._searchQuery.sort = config.sort;
    this.tableConfig = config;
    this._hardwareService.getCameras(this._searchQuery);
  }

  public search(value: string): void {
    this._searchQuery = new SearchQuery();
    this._searchQuery.text = value;
    this.tableConfig.paginator = new PaginatorConfig();
    this.tableConfig.sort = {active: '', direction: ''};
    this._hardwareService.getCameras(this._searchQuery);
  }

  public onAction(action: TableAction): void {
    const selectedCamera = this._cameras.find(camera => camera.cameraId === action.id);
    switch (action.name) {
      case TableActionType.LICENSE:
        if (selectedCamera) {
          this._changeLicense(selectedCamera);
        }
        break;
      case TableActionType.PUBLIC_KEY:
        const publicKey = selectedCamera?.publicKey || '';
        this._showPublicKey(publicKey);
        break;
      case TableActionType.DELETE:
        if (selectedCamera) {
          this._removeCamera(selectedCamera);
        }
        break;
      default:
        break;
    }
  }

}
