import { Component, Input } from '@angular/core';
import { ToggleConfig } from 'src/app/models/toggle.model';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent {

  @Input()
  public config: ToggleConfig = new ToggleConfig('');

  constructor() { }

}
