<div class="code-input">
  <input
    class="code-input__input"
    *ngFor="let name of inputNames"
    [formControl]="getControl(name)"
    (focus)="clearInput(name)"
    (keyup.backspace)="previousInput(name)"
    (paste)="pasteCode($event)"
    type="text"
    [name]="name"
    autocomplete="off"
  >
</div>
