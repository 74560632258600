import { Component, Input, OnInit } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { LocalizationService } from '@tallence/localization';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent extends GarbageCollectorComponent implements OnInit {

  @Input()
  public config: InputConfig = new InputConfig(InputType.NONE, '');
  @Input()
  public required?: string;
  @Input()
  public readonly?: string;
  @Input()
  public pattern?: string;
  @Input()
  public whitelist?: string;
  @Input()
  public autocomplete = 'off';
  public publicKeyInput = '';
  public focusState = false;

  constructor(
    private _localizationService: LocalizationService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this._setValidation();
  }

  private _setValidation(): void {
    const validators: ValidatorFn[] = [];

    if (this.required !== undefined) {
      validators.push(Validators.required);
    }
    if (this.pattern !== undefined) {
      validators.push(Validators.pattern(this.pattern));
    }
    this.config.control.setValidators(validators);
  }

  public getErrorMessage(): string {
    if (this.config.control.hasError('required')) {
      if (this.required) {
        return this._localizationService.getTranslation(this.required);
      } else {
        return this._localizationService.getTranslation('input.error.required', [this.config.label]);
      }
    }
    return '';
  }
  public getHintMessage(): string {
    return this._localizationService.getTranslation('input.hint.publicKey');
  }

  public onPublicKeyChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target?.files?.length) {
      const file: File = target.files[0];
      const reader = new FileReader();
      reader.onload = (src) => {
        const source = `${src.target?.result}`;
        if (source) {
          this.config.control.setValue(source);
        }
      };
      reader.readAsText(file);
    }
    this.publicKeyInput = '';
  }

}
