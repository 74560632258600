<mat-form-field class="autocomplete" appearance="standard">
  <mat-label>{{ config.label | translate }}</mat-label>
  <input type="text"
         [placeholder]="config.placeholder"
         matInput
         [formControl]="config.control"
         [matAutocomplete]="auto">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value" [disabled]="option.disabled">
      {{option.value}}
    </mat-option>
  </mat-autocomplete>
  <mat-hint *ngIf="config.hint">{{ config.hint | translate }}</mat-hint>
  <mat-error *ngIf="config.control.hasError('required')">
    {{ getErrorMessage() }}
  </mat-error>
</mat-form-field>
