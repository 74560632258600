<div class="login row">
  <app-welcome></app-welcome>
  <div class="flex">
    <app-content>
      <app-headline icon="login">{{ 'login.headline' | translate }}</app-headline>
      <app-card class="login__note">{{ 'login.note' | translate }}</app-card>
      <app-card>
        <app-input required [config]="emailConfig"></app-input>
        <app-input required (keyup.enter)="login()" [config]="passwordConfig"></app-input>
        <mat-error *ngIf="loginForm.hasError('invalid')">{{ 'login.error.invalid' | translate }}</mat-error>
        <div class="actions">
          <app-button (action)="toReset()">{{ 'button.passwordForgotten' | translate }}</app-button>
          <div class="flex"></div>
          <app-button primary [disabled]="locked" (action)="login()">{{ 'button.login' | translate }}</app-button>
        </div>
      </app-card>
      <app-card *ngIf="secret">
        <div class="login__restore">
          <app-button primary (action)="enterPin()">{{ 'button.returnPin' | translate }}</app-button>
        </div>
      </app-card>
      <app-card>
        <div class="login__register">
          <p>{{ 'login.register' | translate }}</p>
          <div class="actions">
            <app-button simple icon="text-box-check-outline" (action)="register()">{{ 'button.newApplicationPhotographer' | translate }}</app-button>
            <app-button simple icon="text-box-check-outline" (action)="register('employee')">{{ 'button.newApplicationEmployee' | translate }}</app-button>
          </div>
        </div>
      </app-card>
    </app-content>
  </div>
</div>

