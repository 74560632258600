<div class="file-upload">
  <div class="file-upload__form">
    <div
      #dropboxElement
      class="file-upload__dropbox"
      [ngClass]="{'file-upload__dropbox--highlight': highlighted}"
      (mouseleave)="highlighted = false"
    >
      <label class="file-upload__label" for="file-upload-input">
        <div>
          <app-icon class="file-upload__label-icon" icon="upload"></app-icon>
        </div>
        <div>
          {{ config.label | translate }}
        </div>
      </label>
      <input
        #inputElement
        type="file"
        id="file-upload-input"
        class="file-upload__input"
        [(ngModel)]="fileInput"
        (change)="onFileChange($event)"
      />
    </div>
    <mat-error *ngIf="config.control.hasError('required')">
      {{ getErrorMessage() }}
    </mat-error>
  </div>
  <div class="file-upload__attachments">
    <app-table sortable [config]="attachmentsConfig" (action)="onAction($event)"></app-table>
  </div>
</div>
