import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { LocalizationService } from '@tallence/localization';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { Studio, TableAction, TableActionType, TableConfig } from 'src/app/models/administration.model';
import { AutocompleteConfig } from 'src/app/models/autocomplete.model';
import { CheckboxConfig } from 'src/app/models/checkbox.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { FileUploadConfig } from 'src/app/models/file-upload.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { Role } from 'src/app/models/security.model';
import { PhotostudioService } from 'src/app/services/register/photostudio.service';
import { UpdateRole } from 'src/app/store/security/security.actions';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';
import * as moment from 'moment';

interface Step {
  valid: boolean;
  enabled: boolean;
}
interface Steps {
  register: Step;
  studios: Step;
  files: Step;
  confirm: Step;
}

@Component({
  selector: 'app-register-view',
  templateUrl: './register-view.component.html',
  styleUrls: ['./register-view.component.scss']
})
export class RegisterViewComponent extends GarbageCollectorComponent implements OnInit {

  public registerForm: FormGroup = this._fb.group({});
  public salutationConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.salutation.label');
  public firstNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.firstName.label');
  public lastNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.lastName.label');
  public birthDateConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.birthDate.label');
  // public nationalityConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.nationality.label');
  // public idCardNumberConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.idCardNumber.label');
  public companyConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.company.label');
  public companyLegalFormConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.companyLegalForm.label');
  public emailConfig: InputConfig = new InputConfig(InputType.EMAIL, 'input.email.label');
  public websiteConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.website.label');
  public phone1Config: InputConfig = new InputConfig(InputType.PHONE, 'input.phone1.label');
  public phone2Config: InputConfig = new InputConfig(InputType.PHONE, 'input.phone2.label');

  public studiosConfig: TableConfig = new TableConfig();
  public studios: Studio[] = [];
  public studiosControl: FormControl = new FormControl(this.studios);

  public fileConfig: FileUploadConfig = new FileUploadConfig('fileUpload.label');
  public memberConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.member.label');

  public confirmForm: FormGroup = this._fb.group({});
  public dsgvoConfirmConfig: CheckboxConfig = new CheckboxConfig('checkbox.dsgvo.label');
  public uigConfirmConfig: CheckboxConfig = new CheckboxConfig('checkbox.uig.label');
  public bmiConfirmConfig: CheckboxConfig = new CheckboxConfig('checkbox.bmi.label');
  public legalNoteConfirmConfig: CheckboxConfig = new CheckboxConfig('checkbox.legalNote.label');
  public maxDate?: string;
  public role: Role = Role.NONE;
  public headline = '';
  public description = '';
  public registerError = '';
  public photographerEmail = '';
  public uploadProgress = 0;

  public steps: Steps = {
    register: {
      valid: false,
      enabled: true
    },
    studios: {
      valid: false,
      enabled: true
    },
    files: {
      valid: false,
      enabled: true
    },
    confirm: {
      valid: false,
      enabled: true
    }
  };

  constructor(
    private _fb: FormBuilder,
    private _localizationService: LocalizationService,
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _store: Store,
    private _photostudioService: PhotostudioService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.role = this._route.snapshot.data.role;
    this._store.dispatch(new UpdateRole(this.role));
    this.headline = this._localizationService.getTranslation(`register.step.${this.role.toLowerCase()}.headline`);
    this.description = this._localizationService.getTranslation(`register.step.${this.role.toLowerCase()}.description`);
    switch (this.role) {
      case Role.PHOTOGRAPHER:
        this.studiosConfig.header = ['studioName', 'licenseNumber', 'address', 'zip', 'location'];
        this.studiosConfig.actions = [TableActionType.DELETE];
        this.studiosConfig.actionId = 'id';
        break;
      case Role.EMPLOYEE:
        this.steps.studios.enabled = false;
        this.steps.studios.valid = true;
        let token: string = this._route.snapshot.params.token;
        if (token) {
          token = decodeURIComponent(token);
          token = atob(token);
          const invite = token.split(':');
          this.photographerEmail = invite[0] || '';
          this.emailConfig.control.setValue(invite[1] || '');
          this.description = this._localizationService.getTranslation(`register.step.${this.role.toLowerCase()}.description`, [`${invite[2]} ${invite[3]}`]);
        } else {
          this.headline = this._localizationService.getTranslation(`register.step.employee.headline`);
          this.description = this._localizationService.getTranslation(`register.step.employee.description`);
        }
        break;
      default:
        break;
    }

    this._setupRegisterForm();
    this._setupConfirmForm();
  }

  private _setupRegisterForm(): void {
    this.registerForm.addControl('salutation', this.salutationConfig.control);
    this.registerForm.addControl('firstName', this.firstNameConfig.control);
    this.registerForm.addControl('lastName', this.lastNameConfig.control);
    this.registerForm.addControl('birthDate', this.birthDateConfig.control);
    // this.registerForm.addControl('nationality', this.nationalityConfig.control);
    this.registerForm.addControl('email', this.emailConfig.control);
    this.registerForm.addControl('phone1', this.phone1Config.control);
    this.registerForm.addControl('phone2', this.phone2Config.control);
    this.maxDate = moment().subtract(15, 'years').format('YYYY-MM-DD');

    if (this.role === Role.PHOTOGRAPHER) {
      // this.registerForm.addControl('idCardNumber', this.idCardNumberConfig.control);
      this.registerForm.addControl('company', this.companyConfig.control);
      this.registerForm.addControl('companyLegalForm', this.companyLegalFormConfig.control);
      this.registerForm.addControl('website', this.websiteConfig.control);
      this.registerForm.addControl('member', this.memberConfig.control);
      this.studiosControl.setValidators(Validators.required);
    }

    this.addSubscription(
      this.registerForm.statusChanges.subscribe(() => {
        this.steps.register.valid = this.registerForm.valid;
        this.registerError = '';
      })
    );
  }

  private _setupConfirmForm(): void {
    this.confirmForm.addControl('dsvgo', this.dsgvoConfirmConfig.control);
    this.confirmForm.addControl('uig', this.uigConfirmConfig.control);
    this.confirmForm.addControl('bmi', this.bmiConfirmConfig.control);
    this.confirmForm.addControl('legalNote', this.legalNoteConfirmConfig.control);
    this.addSubscription(
      this.confirmForm.statusChanges.subscribe(() => {
        if (this.steps.register.valid) {
          const values: boolean[] = Object.values(this.confirmForm.value);
          this.steps.confirm.valid = values.filter(((v: boolean) => !v)).length === 0 && this.steps.register.valid;
          this.registerError = '';
        }
      })
    );
  }

  public addStudio(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.ADD_STUDIO,
      'dialog.addStudio.headline',
      ['dialog.addStudio.description'],
      'button.add'
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().subscribe((studio: Studio | undefined) => {
        if (studio !== undefined) {
          this.studios = [...this.studios, studio];
          this.studiosControl.setValue(this.studios);
          this.studiosConfig.items = [...this.studiosConfig.items, {
            ...studio,
            id: +new Date()
          }];
          this.studiosConfig = {...this.studiosConfig};
        }
      })
    );
  }

  public onAction(action: TableAction): void {
    switch (action.name) {
      case TableActionType.DELETE:
        this.studiosConfig.items = this.studiosConfig.items.filter(studio => studio.id !== action.id);
        this.studios = this.studiosConfig.items.map(studio => {
          return {
            studioName: studio.studioName,
            licenseNumber: studio.licenseNumber,
            address: studio.address,
            zip: studio.zip,
            location: studio.location
          };
        });
        this.studiosControl.setValue(this.studios);
        this.studiosConfig = {...this.studiosConfig};
        break;
      default:
        break;
    }
  }

  public register(): void {
    this.steps.confirm.valid = false;
    const formData = new FormData();
    const salutation =
      this.registerForm.value.salutation === this._localizationService.getTranslation('autocomplete.salutation.options.2') ?
      '' : this.registerForm.value.salutation;
    const data = {
      ...this.registerForm.value,
      salutation,
    };
    if (this.role === Role.PHOTOGRAPHER) {
      this.studios.forEach(s => {
        const studio = {
          name: s.studioName,
          licenseNumber: s.licenseNumber,
          address: s.address,
          zip: s.zip,
          location: s.location
        };
        formData.append('studios', JSON.stringify(studio));
      });
    }
    if (this.role === Role.EMPLOYEE && this.photographerEmail !== '') {
      formData.append('photographerEmail', this.photographerEmail);
    }
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
    this.fileConfig.control.value.forEach((file: File) => {
      formData.append('upload', file);
    });
    this.addSubscription(
      this._photostudioService.sendApplication(formData).pipe(
        catchError((error: any) => {
          this.uploadProgress = 0;
          this.steps.confirm.valid = true;
          switch (error.status) {
            case 400:
              this.registerError = this._localizationService.getTranslation('register.error.400');
              break;
            case 409:
              this.registerError = this._localizationService.getTranslation('register.error.409', [this.emailConfig.control.value]);
              break;
            default:
              this.registerError = this._localizationService.getTranslation('register.error.unknown');
              break;
          }
          return throwError(error);
        })
      ).subscribe((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round((event.loaded || 1) / (event.total || 1) * 100);
        }
        if (event.type === HttpEventType.Response) {
          this.uploadProgress = 0;
          const dialogData: DialogConfig = new DialogConfig(
            DialogType.SUCCESS,
            'dialog.registerDone.headline',
            ['dialog.registerDone.description']
          );
          const dialogRef = this._dialog.open(DialogComponent, {
            ...dialogData.layout(),
            data: dialogData,
            disableClose: true
          });
          this.addSubscription(
            dialogRef.afterClosed().subscribe(() => {
              location.href = 'https://www.e-passfoto.de/';
            })
          );
        }
      })
    );
  }

}
