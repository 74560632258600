import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { User } from 'src/app/models/administration.model';
import { AutocompleteConfig } from 'src/app/models/autocomplete.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { Role } from 'src/app/models/security.model';
import { StateModel, UserItems } from 'src/app/models/store.model';
import { Helper } from 'src/app/utilities/helper';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserService } from 'src/app/services/register/user.service';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent extends GarbageCollectorComponent implements OnInit {

  private _user: any = {};
  public updateError = '';

  public salutationConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.salutation.label');
  public firstNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.firstName.label');
  public lastNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.lastName.label');
  public nationalityConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.nationality.label');
  public idCardNumberConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.idCardNumber.label');
  public companyConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.company.label');
  public companyLegalFormConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.companyLegalForm.label');
  public emailConfig: InputConfig = new InputConfig(InputType.EMAIL, 'input.email.label');
  public websiteConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.website.label');
  public phone1Config: InputConfig = new InputConfig(InputType.PHONE, 'input.phone1.label');
  public phone2Config: InputConfig = new InputConfig(InputType.PHONE, 'input.phone2.label');
  public memberConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.member.label');

  public userForm: FormGroup = this._fb.group({});
  public locked = true;
  public role?: Role;

  constructor(
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    private _store: Store,
    private _router: Router,
    private _userService: UserService
  ) {
    super();
  }

  public ngOnInit(): void {
    const email: string = this._route.snapshot.params.email;

    this.userForm.addControl('salutation', this.salutationConfig.control);
    this.userForm.addControl('firstName', this.firstNameConfig.control);
    this.userForm.addControl('lastName', this.lastNameConfig.control);
    this.userForm.addControl('nationality', this.nationalityConfig.control);
    this.userForm.addControl('email', this.emailConfig.control);
    this.userForm.addControl('phone1', this.phone1Config.control);
    this.userForm.addControl('phone2', this.phone2Config.control);

    this.addSubscription(
      this._store.select((state: StateModel) => state.administration.users).pipe(
        switchMap((users: UserItems) => {
          const user: User | undefined = users.items.find(u => u.email === email);
          if (user === undefined) {
            this.back();
          } else {
            this.role = user.role;
            if (this.role === Role.PHOTOGRAPHER) {
              this.userForm.addControl('idCardNumber', this.idCardNumberConfig.control);
              this.userForm.addControl('company', this.companyConfig.control);
              this.userForm.addControl('companyLegalForm', this.companyLegalFormConfig.control);
              this.userForm.addControl('website', this.websiteConfig.control);
              this.userForm.addControl('member', this.memberConfig.control);
            }
            return this._userService.getUser(user);
          }
          return of(null);
        })
      ).subscribe((user: any) => {
        if (user) {
          Helper.mergeForm(this.userForm, user);
          this._user = {
            ...this.userForm.value,
            role: this.role
          };
        } else {
          this.back();
        }
      })
    );
    this.addSubscription(
      this.userForm.statusChanges.subscribe(() => {
        this.locked = this.userForm.invalid;
      })
    );
  }

  public update(): void {
    const update = Helper.diffData(this._user, this.userForm.value);
    const user: User = new User();
    Helper.mergeData(user, this._user);
    this.addSubscription(
      this._userService.updateUser(update, user).subscribe(success => {
        if (!success) {
          this.updateError = 'user.error.update';
        }
      })
    );
  }

  public back(): void {
    this._router.navigate(['users']);
  }

}
