<app-content>
  <div class="comapany-view">
    <div class="row">
      <app-headline *ngIf="company" class="flex" icon="holiday_village">{{ company }}</app-headline>
      <app-headline *ngIf="!company" class="flex" icon="holiday_village">{{ 'company.headline' | translate }}</app-headline>
      <div *ngIf="isPhographer" class="headline-action">
        <app-button (action)="addStudio()" icon="home-plus-outline">{{ 'button.addStudio' | translate }}</app-button>
      </div>
    </div>
    <app-card>
      <div *ngIf="showSearch" class="row">
        <app-search class="flex" (action)="search($event)" [config]="searchConfig"></app-search>
      </div>
      <app-table
        *ngIf="tableConfig.items.length > 0"
        paginator
        sortable
        [config]="tableConfig"
        (action)="onAction($event)"
      ></app-table>
      <div *ngIf="tableConfig.items.length === 0">
        {{ 'company.empty' | translate }}
      </div>
      <div *ngIf="!isPhographer" class="actions">
        <app-button secondary (action)="back()">{{ 'button.back' | translate }}</app-button>
      </div>
    </app-card>
  </div>
</app-content>
