<div class="dialog">
  <div class="headline">{{ data.headline | translate }}</div>

  <div mat-dialog-content>
    <div *ngIf="data.type === 'success'" class="dialog__success">
      <mat-icon>done_outline</mat-icon>
    </div>
    <div *ngIf="data.type === 'fail'" class="dialog__fail">
      <mat-icon>cancel</mat-icon>
    </div>
    <div *ngIf="data.type === 'conflict'" class="dialog__fail">
      <mat-icon>warning_amber</mat-icon>
    </div>
    <div *ngIf="data.values.icon" class="dialog__success">
      <mat-icon>{{ data.values.icon }}</mat-icon>
    </div>
    <p class="dialog__description" [ngClass]="{'dialog__description--textblock': data.values.publicKey === true}">{{ description }}</p>

    <div *ngIf="data.type === 'add_employee'">
      <div class="row">
        <app-input class="flex" [config]="firstNameConfig"></app-input>
        <app-input class="flex" [config]="lastNameConfig"></app-input>
      </div>
      <app-input required [config]="emailConfig"></app-input>
    </div>

    <div *ngIf="data.type === 'add_uig_employee'">
      <div class="row">
        <app-autocomplete class="flex" required [config]="salutationConfig"></app-autocomplete>
        <div class="flex"></div>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="firstNameConfig"></app-input>
        <app-input class="flex" required [config]="lastNameConfig"></app-input>
      </div>
      <div class="row">
        <app-date-picker class="flex" required [max]="maxDate" [config]="birthDateConfig"></app-date-picker>
        <app-input class="flex" required [config]="emailConfig"></app-input>
      </div>
      <div class="row">
        <app-input class="flex" [config]="phone1Config"></app-input>
        <div class="flex"></div>
      </div>
    </div>

    <div *ngIf="data.type === 'add_hardware_provider'">
      <div class="row">
        <app-autocomplete class="flex" required [config]="salutationConfig"></app-autocomplete>
        <div class="flex"></div>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="firstNameConfig"></app-input>
        <app-input class="flex" required [config]="lastNameConfig"></app-input>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="companyConfig"></app-input>
        <app-autocomplete class="flex" required [config]="companyLegalFormConfig"></app-autocomplete>
      </div>
      <div class="row">
        <app-date-picker class="flex" required [max]="maxDate" [config]="birthDateConfig"></app-date-picker>
        <app-input class="flex" required [config]="addressConfig"></app-input>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="zipConfig"></app-input>
        <app-input class="flex" required [config]="locationConfig"></app-input>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="emailConfig"></app-input>
        <app-input class="flex" [config]="phone1Config"></app-input>
      </div>
    </div>

    <div *ngIf="data.type === 'delete_account'">
      <app-input required [config]="passwordConfig"></app-input>
    </div>

    <div *ngIf="data.type === 'add_camera'">
      <div class="row">
        <app-input class="flex" required [config]="cameraManufacturerConfig"></app-input>
        <app-input class="flex" required [config]="cameraModelConfig"></app-input>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="cameraIdConfig"></app-input>
      </div>
      <div class="row">
        <app-input-file class="flex" required [config]="publicKeyConfig"></app-input-file>
      </div>
      <div class="row">
        <app-date-picker class="flex" required [config]="licenseBeginDateConfig"></app-date-picker>
        <app-date-picker class="flex" required [min]="minDate" [config]="licenseEndDateConfig"></app-date-picker>
      </div>
    </div>

    <div *ngIf="data.type === 'update_camera'">
      <div class="row">
        <app-date-picker class="flex" required [config]="licenseBeginDateConfig"></app-date-picker>
      </div>
      <div class="row">
        <app-date-picker class="flex" required [min]="minDate" [config]="licenseEndDateConfig"></app-date-picker>
      </div>
    </div>

    <div *ngIf="data.type === 'add_studio' || data.type === 'update_studio'">
      <div class="row">
        <app-input class="flex" required [config]="studioNameConfig"></app-input>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="addressConfig"></app-input>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="zipConfig"></app-input>
        <app-input class="flex" required [config]="locationConfig"></app-input>
      </div>
    </div>

    <div *ngIf="data.type === 'reject_application'">
      <mat-form-field class="dialog__reject-reason">
        <textarea matInput [formControl]="reasonControl" [placeholder]="'input.rejectReason.placeholder' | translate"></textarea>
      </mat-form-field>
    </div>

    <div *ngIf="data.type === 'search_company'">
      <div class="row">
        <app-input class="dialog__search flex" required (keyup.enter)="searchCompany()" [config]="searchConfig" postIcon="home-search-outline"></app-input>
      </div>
      <div *ngIf="tableConfig.items.length > 0">
        <app-table
          paginator
          sortable
          [config]="tableConfig"
          (action)="addCompany($event)"
        ></app-table>
      </div>
      <div *ngIf="searched && tableConfig.items.length === 0">
        {{ 'dialog.searchAddCompany.empty' | translate }}
      </div>
    </div>

    <div *ngIf="data.type === 'code'">
      <div *ngIf="authentificator" class="row dialog__code">
        <qrcode elementType="img" class="dialog__qr-code" [qrdata]="data.values.secret" [width]="288" errorCorrectionLevel="M"></qrcode>
      </div>
      <div *ngIf="!authentificator"  class="row dialog__code">
        <app-code-input *ngIf="!data.values.codeSize" (enter)="codeEntered($event)"></app-code-input>
        <app-code-input *ngIf="data.values.codeSize" [size]="data.values.codeSize" (enter)="codeEntered($event)"></app-code-input>
      </div>
    </div>

    <div *ngIf="data.type === 'force_save'" class="dialog__checkbox">
      <app-checkbox required [config]="forceSaveConfig"></app-checkbox>
    </div>

    <div *ngIf="data.type === 'validation_errors'" class="dialog__validation-errors">
      <div class="dialog__validation-error" *ngFor="let validationError of validationErrors">
        <app-icon icon="error" class="dialog__validation-error-icon"></app-icon>
        <span>{{ validationError | translate }}</span>
      </div>
    </div>

    <div *ngIf="data.type === 'password'">
      <div class="row">
        <app-input class="flex" required (keyup.enter)="submitPassword()" [config]="passwordConfig"></app-input>
      </div>
    </div>

    <mat-error *ngIf="error">{{ error | translate }}</mat-error>

  </div>

  <div class="actions">
    <i *ngIf="showRequiredHint()" class="flex">{{ 'required-fields' | translate }}</i>
    <ng-container *ngIf="data.type === 'confirm'">
      <app-button primary (action)="confirm()">{{ data.okButton | translate }}</app-button>
      <app-button *ngIf="data.cancelButton" secondary [mat-dialog-close]>{{ data.cancelButton | translate }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'add_employee'">
      <app-button secondary [mat-dialog-close]>{{ data.cancelButton | translate }}</app-button>
      <app-button primary [disabled]="confirmLocked" (action)="inviteEmployee()">{{ data.okButton | translate }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'add_uig_employee'">
      <app-button secondary [mat-dialog-close]>{{ data.cancelButton | translate }}</app-button>
      <app-button primary [disabled]="confirmLocked" (action)="addUigEmployee()">{{ data.okButton | translate }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'add_hardware_provider'">
      <app-button secondary [mat-dialog-close]>{{ data.cancelButton | translate }}</app-button>
      <app-button primary [disabled]="confirmLocked" (action)="addHardwareProvider()">{{ data.okButton | translate }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'delete_account'">
      <app-button primary [disabled]="confirmLocked" (action)="deleteAccount()">{{ data.okButton | translate }}</app-button>
      <app-button secondary [mat-dialog-close]>{{ data.cancelButton | translate }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'account_deleted' || data.type === 'success' || data.type === 'fail'">
      <app-button primary [mat-dialog-close]="true">{{ data.okButton | translate }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'conflict'">
      <app-button primary (action)="backToForm()">{{ data.okButton | translate }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'add_camera'">
      <app-button secondary [mat-dialog-close]>{{ data.cancelButton | translate }}</app-button>
      <app-button primary [disabled]="confirmLocked" (action)="addCamera()">{{ data.okButton | translate }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'update_camera'">
      <app-button secondary [mat-dialog-close]>{{ data.cancelButton | translate }}</app-button>
      <app-button primary [disabled]="confirmLocked" (action)="updateCamera()">{{ data.okButton | translate }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'add_studio'">
      <app-button secondary [mat-dialog-close]>{{ data.cancelButton | translate }}</app-button>
      <app-button primary [disabled]="confirmLocked" (action)="addStudio()">{{ data.okButton | translate }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'update_studio'">
      <app-button secondary [mat-dialog-close]>{{ data.cancelButton | translate }}</app-button>
      <app-button primary [disabled]="confirmLocked" (action)="updateStudio()">{{ data.okButton | translate }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'reject_application'">
      <app-button secondary [mat-dialog-close]>{{ data.cancelButton | translate }}</app-button>
      <app-button primary [disabled]="confirmLocked" (action)="rejectApplication()">{{ data.okButton | translate }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'search_company'">
      <app-button secondary [mat-dialog-close]>{{ data.cancelButton | translate }}</app-button>
      <app-button primary [disabled]="confirmLocked" (action)="searchCompany()">{{ data.okButton | translate }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'code'">
      <app-button *ngIf="!authentificator" secondary [mat-dialog-close]="">{{ data.cancelButton | translate }}</app-button>
      <app-button *ngIf="authentificator" [disabled]="clickDelay !== ''" primary [mat-dialog-close]>{{ data.okButton | translate }}{{ clickDelay }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'force_save'">
      <app-button secondary [mat-dialog-close]="false">{{ data.cancelButton | translate }}</app-button>
      <app-button [disabled]="confirmLocked" primary [mat-dialog-close]="true">{{ data.okButton | translate }}{{ clickDelay }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'validation_errors'">
      <app-button primary [mat-dialog-close]="true">{{ data.okButton | translate }}{{ clickDelay }}</app-button>
    </ng-container>
    <ng-container *ngIf="data.type === 'password'">
      <app-button secondary [mat-dialog-close]>{{ data.cancelButton | translate }}</app-button>
      <app-button primary [disabled]="confirmLocked" (action)="submitPassword()">{{ data.okButton | translate }}</app-button>
    </ng-container>
  </div>

  <div *ngIf="loading" class="dialog__overlay">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</div>
