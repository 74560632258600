import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { StateModel } from '../models/store.model';
import { UpdateAuth, UpdateRole, UpdateTimeout } from '../store/security/security.actions';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from '../models/security.model';
import { UpdateAccountProperty } from '../store/account/account.actions';
import { Router } from '@angular/router';
import { PhotosService } from './photos.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfig, DialogType } from '../models/dialog.model';
import { DialogComponent } from '../components/dialog/dialog.component';
import { ConfigService } from './config.service';
import { UserService } from './register/user.service';
import { ResponseStatus } from '../models/administration.model';
import { Helper } from '../utilities/helper';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _api: any = {};
  private _url: any = {};
  private _jwt: JwtHelperService = new JwtHelperService();
  private _timeout = 0;
  private _auth = false;
  private _time = 30 * 60 * 1000;
  private _secondFactorToken = '';
  private _basicLogin = '';

  constructor(
    private _store: Store,
    private _http: HttpClient,
    private _router: Router,
    private _photosService: PhotosService,
    private _dialog: MatDialog,
    private _configService: ConfigService,
    private _userService: UserService,
    private _apiService: ApiService
  ) {
    this._configService.getApi().subscribe((data: any) => {
      this._api = data;
      this._url = `${this._api.register.origin}${this._api.register.port}${this._api.register.version}`;
    });
    this._store.select((state: StateModel) => state.security.auth).subscribe((auth: boolean) => {
      this._auth = auth;
    });
    if (sessionStorage.getItem('forceLogout')) {
      this.logout();
    }
  }

  public restoreAuth(): void {
    if (this.getAccessToken() && this.getRefreshToken()) {
      if (this.checkToken()) {
        this._store.dispatch(new UpdateAuth(true));
        this.getDataFromToken();
        this._userService.getAccount();
        this._userService.getHardwareLicenseToken();
      } else {
        this.refreshToken().subscribe((success: boolean) => {
          if (success) {
            this._router.navigate(['overview']);
          }
        });
      }
    }
  }

  public login(email: string, password: string): Observable<boolean> {
    this._basicLogin = btoa(`${email}:${password}`);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', `Basic ${this._basicLogin}`);

    return this._http.get(`${this._url}/login`, { headers }).pipe(
      map((auth: any) => {
        if (auth && auth.secondFactorToken) {
          this._secondFactorToken = auth.secondFactorToken;
          return true;
        }
        return false;
      }),
      catchError(error => {
        console.log(error);
        return of(false);
      })
    );
  }

  public getAuthentication(code: string): Observable<ResponseStatus> {
    const request = this._http.post(`${this._url}/login`, {
      secondFactorToken: this._secondFactorToken,
      otpCode: code
    }).pipe(
      tap((auth: any) => {
        if (auth && auth.accessToken && auth.refreshToken) {
          sessionStorage.setItem('access_token', auth.accessToken);
          sessionStorage.setItem('refresh_token', auth.refreshToken);
          this._store.dispatch(new UpdateAuth(true));
          this.getDataFromToken();
          const role: Role = this._store.selectSnapshot((state: StateModel) => state.security.role);
          if (role === Role.ADMIN || Role.UIG) {
            const basic = Helper.encrypt('auth', this._basicLogin);
            sessionStorage.setItem('basic', basic);
          }
          this._userService.getAccount();
          this._userService.getHardwareLicenseToken();
          return true;
        }
        return false;
      }),
    );

    return this._apiService.handleResponse(request);

  }

  public refreshToken(): Observable<boolean> {
    const refreshToken = this.getRefreshToken();
    if (refreshToken) {
      return this._http.post(`${this._url}/token`, { refreshToken }).pipe(
        map((response: any) => {
          if (Reflect.has(response, 'accessToken')) {
            sessionStorage.setItem('access_token', response.accessToken);
            if (this.checkToken()) {
              this._store.dispatch(new UpdateAuth(true));
              this.getDataFromToken();
            }
            return true;
          }
          this.logout();
          return false;
        }),
        catchError(() => {
          this.logout();
          return of(false);
        })
      );
    }
    return of(false);
  }

  public getAccessToken(): string | null {
    return sessionStorage.getItem('access_token');
  }

  public getRefreshToken(): string | null {
    return sessionStorage.getItem('refresh_token');
  }

  public checkToken(token: string = ''): boolean {
    const jwt = this.getAccessToken() || token;
    try {
      if (jwt) {
        return !this._jwt.isTokenExpired(jwt);
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  public getDataFromToken(token: string = ''): void {
    const jwt = this.getAccessToken() || token;
    if (jwt) {
      const decodedToken = this._jwt.decodeToken(jwt);
      const email: string = decodedToken.email || decodedToken.username;
      const role: Role = decodedToken.role;
      this._store.dispatch(new UpdateAccountProperty('email', email));
      if (role) {
        this._store.dispatch(new UpdateRole(role));
      }
    }
  }

  public logout(): void {
    this._photosService.clear();
    sessionStorage.clear();
    this._store.dispatch(new UpdateAuth(false));
  }

  public resetAutoLogout(): void {
    sessionStorage.removeItem('forceLogout');
    if (this._auth) {
      this._store.dispatch([new UpdateTimeout(0), new UpdateTimeout(this._time)]);
      window.clearTimeout(this._timeout);
      this._timeout = window.setTimeout(() => {
        if (this._dialog.openDialogs.length === 0) {
          const dialogData: DialogConfig = new DialogConfig(
            DialogType.CONFIRM,
            'dialog.autoLogout.headline',
            ['dialog.autoLogout.description'],
            'button.ok',
            ''
          );
          const dialogRef = this._dialog.open(DialogComponent, {
            ...dialogData.layout(),
            data: dialogData,
            disableClose: true
          });
          sessionStorage.setItem('forceLogout', 'true');
          dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
              this.logout();
            }
          });
        }
      }, this._time);
    }
  }

}
