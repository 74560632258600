import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { Company, PaginatorConfig, SearchQuery, TableAction, TableActionType, TableConfig } from 'src/app/models/administration.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { Role } from 'src/app/models/security.model';
import { CompanyItems, StateModel } from 'src/app/models/store.model';
import { PhotostudioService } from 'src/app/services/register/photostudio.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-companies-view',
  templateUrl: './companies-view.component.html',
  styleUrls: ['./companies-view.component.scss']
})
export class CompaniesViewComponent extends GarbageCollectorComponent implements OnInit {

  private _companies: Company[] = [];
  private _searchQuery: SearchQuery = new SearchQuery([]);
  public searchConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.searchStudio.label');
  public tableConfig: TableConfig = new TableConfig();
  public isHardwareProvider = false;

  constructor(
    private _store: Store,
    private _router: Router,
    private _dialog: MatDialog,
    private _photostudioService: PhotostudioService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.isHardwareProvider = this._store.selectSnapshot((state: StateModel) => state.security.role) === Role.HARDWARE_PROVIDER;
    this.tableConfig.header = ['company', 'companyLegalForm'];
    this.tableConfig.actionId = 'email';
    if (!this.isHardwareProvider) {
      this.tableConfig.click = TableActionType.STUDIOS;
    } else {
      this.tableConfig.actions.push(TableActionType.DELETE);
    }
    this.tableConfig.actions.push(TableActionType.STUDIOS);
    this.addSubscription(
      this._store.select((state: StateModel) => state.administration.companies).subscribe(
        (companies: CompanyItems) =>
      {
        this._companies = companies.items;
        this.tableConfig.items = this._companies;
        this.tableConfig.paginator.size = this._searchQuery.pageLimit;
        this.tableConfig.paginator.total = companies.total;
        this.tableConfig = {...this.tableConfig};
      })
    );
    this._photostudioService.getCompanies(this._searchQuery);
  }

  public configChanged(config: TableConfig): void {
    this._searchQuery.offset = (config.paginator.current - 1) * config.paginator.size;
    this._searchQuery.sort = config.sort;
    this.tableConfig = config;
    this._photostudioService.getCompanies(this._searchQuery);
  }

  public search(value: string): void {
    this._searchQuery = new SearchQuery();
    this._searchQuery.text = value;
    this.tableConfig.paginator = new PaginatorConfig();
    this.tableConfig.sort = {active: '', direction: ''};
    this._photostudioService.getCompanies(this._searchQuery);
  }

  public searchAddCompany(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.SEARCH_COMPANY,
      'dialog.searchAddCompany.headline',
      ['dialog.searchAddCompany.description'],
      'button.searchCompanies'
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().subscribe((added: boolean | undefined) => {
        if (added === true) {
          this._photostudioService.getCompanies(this._searchQuery);
        }
      })
    );
  }

  private _removeCompany(company: Company): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CONFIRM,
      'dialog.removeCompany.headline',
      ['dialog.removeCompany.description', `${company.company} ${company.companyLegalForm}`]
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (result === true) {
            return this._photostudioService.removeCompany(company);
          }
          return of(result);
        })
      ).subscribe((success) => {
        if (success === false) {
          this._removeFailed(company);
        } else {
          this._photostudioService.getCompanies(this._searchQuery);
        }
      })
    );
  }

  private _removeFailed(company: Company): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.FAIL,
      'dialog.removeCompany.headline',
      ['dialog.removeCompany.failed', `${company.company} ${company.companyLegalForm}`]
    );
    this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });
  }

  public onAction(action: TableAction): void {
    const company: Company | undefined = this._companies.find((comp: Company) => comp.email === action.id);

    switch (action.name) {
      case TableActionType.STUDIOS:
        if (company) {
          this._router.navigate(['company', company.email]);
        }
        break;
      case TableActionType.DELETE:
        if (company) {
          this._removeCompany(company);
        }
        break;
      default:
        break;
    }
  }

}
