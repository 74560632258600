import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company, Employee, ResponseStatus, SearchQuery, Studio } from 'src/app/models/administration.model';
import { Role } from 'src/app/models/security.model';
import { StateModel } from 'src/app/models/store.model';
import { UpdateCompanies, UpdateEmployees, UpdateStudios } from 'src/app/store/administration/administration.actions';
import { Helper } from 'src/app/utilities/helper';
import { ApiService } from '../api.service';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class PhotostudioService {

  private _url = '';
  private _endpoint = '';
  private _role: Role = Role.NONE;

  constructor(
    private _apiService: ApiService,
    private _configService: ConfigService,
    private _http: HttpClient,
    private _store: Store,
  ) {
    this._configService.getApi().subscribe((data: any) => {
      this._url = `${data.register.origin}${data.register.port}${data.register.version}`;
    });
    this._store.select((state: StateModel) => state.security.role).subscribe((role: Role) => {
      this._role = role;
      switch (role) {
        case Role.PHOTOGRAPHER:
          this._endpoint = '/photostudio/photographer';
          break;
        case Role.EMPLOYEE:
          this._endpoint = '/photostudio/employee';
          break;
        case Role.HARDWARE_PROVIDER:
          this._endpoint = '/hardware/provider/studios';
          break;
        default:
          break;
      }
    });
  }

  /* APPLICATION */

  public sendApplication(formData: FormData): Observable<any> {
    const request = this._http.post(`${this._url}${this._endpoint}`, formData, {
      reportProgress: true,
      observe: 'events'
    });
    return request;
  }

  /* PHOTOSTUDIO EMPLOYEES */

  public getEmployees(): void {
    const request = this._http.get(`${this._url}${this._endpoint}/employees`);

    request.subscribe((employees: any) => {
      this._store.dispatch(new UpdateEmployees(employees.map((data: any) => {
        const employee = new Employee();
        Helper.mergeData(employee, data);
        return employee;
      })));
    });
  }

  public inviteEmployee(employee: any): Observable<ResponseStatus> {
    const request = this._http.post(`${this._url}${this._endpoint}/employee`, employee);

    return this._apiService.handleResponse(request);
  }

  public removeEmployee(employee: Employee): Observable<boolean> {
    const email: string = encodeURIComponent(employee.email);
    const request = this._http.delete(`${this._url}${this._endpoint}/employee/${email}`);

    return this._apiService.handleResponseSimple(request);
  }

  /* COMPANY AND STUDIOS */

  public getCompanies(query: SearchQuery): void {
    let request = this._http.get(`${this._url}/photostudio?${query.params()}`);
    if (this._role === Role.HARDWARE_PROVIDER) {
      request = this._http.get(`${this._url}${this._endpoint}?${query.params()}`);
    }

    request.subscribe((companies: any) => {
      const items: Company[] = [];
      let total = 0;
      items.push(...companies.studios.map((data: any) => {
        const company = new Company();
        Helper.mergeData(company, data);
        return company;
      }));
      total = companies.count;
      this._store.dispatch(new UpdateCompanies({
        items,
        total
      }));
    });
  }

  public searchCompany(query: SearchQuery): Observable<any[]> {
    const request = this._http.get(`${this._url}/photostudio?${query.params()}`);
    return request.pipe(
      map((response: any) => {
        return response.studios || [];
      })
    );
  }

  public addCompany(email: string): Observable<ResponseStatus> {
    const request = this._http.post(`${this._url}${this._endpoint}`, {
      studiosEmails: [
        email
      ]
    });
    return this._apiService.handleResponse(request);
  }

  public removeCompany(company: Company): Observable<boolean> {
    const email: string = encodeURIComponent(company.email);
    const request = this._http.delete(`${this._url}${this._endpoint}/${email}`);

    return this._apiService.handleResponseSimple(request);
  }

  public getStudios(email: string): void {
    email = encodeURIComponent(email || '');
    const request = this._http.get(`${this._url}/photostudio/stores/${email}`);

    request.subscribe((data: any) => {
        const studios: Studio[] = data.map((studio: Studio) => {
          const s = new Studio();
          Helper.mergeData(s, studio);
          return s;
        });
        this._store.dispatch(new UpdateStudios({
          items: studios,
          total: studios.length
        }));
      }
    );
  }

  public updateStudios(studios: Studio[]): Observable<ResponseStatus> {
    let email = this._store.selectSnapshot((state: StateModel) => state.account.email);
    email = encodeURIComponent(email || '');
    const request = this._http.patch(`${this._url}/photostudio/stores/${email}`, {studios});
    return this._apiService.handleResponse(request);
  }

}
