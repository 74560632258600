import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { SearchQuery, TableAction, TableActionType, TableConfig, User } from 'src/app/models/administration.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { Role } from 'src/app/models/security.model';
import { StateModel, UserItems } from 'src/app/models/store.model';
import { UserService } from 'src/app/services/register/user.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-uig-employees-view',
  templateUrl: './uig-employees-view.component.html',
  styleUrls: ['./uig-employees-view.component.scss']
})
export class UigEmployeesViewComponent extends GarbageCollectorComponent implements OnInit {

  private _uigEmployees: User[] = [];
  private _searchQuery: SearchQuery = new SearchQuery([Role.ADMIN, Role.UIG]);
  public tableConfig: TableConfig = new TableConfig();
  public searchConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.searchEmployee.label');

  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _router: Router,
    private _userService: UserService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.tableConfig.header = ['role', 'firstName', 'lastName', 'email', 'status'];
    this.tableConfig.actions = [TableActionType.DEACTIVATE, TableActionType.REACTIVATE, TableActionType.EDIT];
    this.addSubscription(
      this._store.select((state: StateModel) => state.administration.users).subscribe((users: UserItems) => {
        this._uigEmployees = users.items;
        this.tableConfig.items = this._uigEmployees;
        this.tableConfig.paginator.total = users.total;
        this.tableConfig.paginator.size = this._searchQuery.pageLimit;
        this.tableConfig = {...this.tableConfig};
      })
    );
    this._userService.getUsers(this._searchQuery);
  }

  public addEmployee(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.ADD_UIG_EMPLOYEE,
      'dialog.addUigEmployee.headline',
      ['dialog.addUigEmployee.description'],
      'button.add'
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          const params = new SearchQuery([Role.ADMIN, Role.UIG]);
          this._userService.getUsers(params);
        }
      })
    );
  }

  private _deactivateUser(employee: User): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CONFIRM,
      'dialog.deactivateEmployee.headline',
      ['dialog.deactivateEmployee.description', employee.email]
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().pipe(
        switchMap((result: any) => {
          if (result === true) {
            return this._userService.deleteUser(employee);
          }
          return of(result);
        })
      ).subscribe(success => {
        if (success === false) {
          this._removeFailed(employee);
        } else {
          const params = new SearchQuery([Role.ADMIN, Role.UIG]);
          this._userService.getUsers(params);
        }
      })
    );
  }

  private _reactivateEmployee(employee: User): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CONFIRM,
      'dialog.reactivateEmployee.headline',
      ['dialog.reactivateEmployee.description', employee.email]
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });

    this.addSubscription(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (result === true) {
            const params = new SearchQuery([Role.ADMIN, Role.UIG]);
            return this._userService.reactivateUser(employee.email, params);
          }
          return of(result);
        })
      ).subscribe()
    );
  }

  private _removeFailed(employee: User): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.FAIL,
      'dialog.removeEmployee.headline',
      ['dialog.removeEmployee.failed', `${employee.firstName} ${employee.lastName}`]
    );
    this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
    });
  }

  public configChanged(config: TableConfig): void {
    this.searchConfig.control.reset();
    this._searchQuery.offset = (config.paginator.current - 1) * config.paginator.size;
    this._searchQuery.sort = config.sort;
    this.tableConfig = config;
    this._userService.getUsers(this._searchQuery);
  }

  public search(value: string): void {
    this._searchQuery = new SearchQuery([Role.ADMIN, Role.UIG]);
    this._searchQuery.text = value;
    this._userService.getUsers(this._searchQuery);
  }

  public onAction(action: TableAction): void {
    const uigEmployee: User | undefined = this._uigEmployees.find((employee: User) => employee.email === action.id);
    switch (action.name) {
      case TableActionType.DEACTIVATE:
        if (uigEmployee) {
          this._deactivateUser(uigEmployee);
        }
        break;
      case TableActionType.EDIT:
        this._router.navigate(['uig-employee', action.id]);
        break;
      case TableActionType.REACTIVATE:
        if (uigEmployee) {
          this._reactivateEmployee(uigEmployee);
        }
        break;
      default:
        break;
    }
  }

}
