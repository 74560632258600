import { FormControl } from '@angular/forms';

export class SliderConfig {
  public control: FormControl;
  public label: string;
  public unit: string;
  public min = -100;
  public max = 100;
  public step = 1;
  public id: string;

  constructor(label: string, unit: string = '') {
    this.control = new FormControl('0');
    this.label = label;
    this.unit = unit;
    this.id = `id${label.replace(/\./g, '')}${Math.round(+new Date() * Math.random())}`;
  }
}
