<div class="password-view row">
  <app-welcome></app-welcome>
  <div class="flex">
    <app-content>
      <div *ngIf="view === 'new'" class="password-view__new">
        <app-headline icon="lock">{{ 'password.new.headline' | translate }}</app-headline>
        <app-card>
          <app-new-password empty (password)="getNewPassword($event)"></app-new-password>
          <mat-error *ngIf="passwordError !== ''">{{ passwordError | translate }}</mat-error>
          <div class="actions">
            <i class="flex">{{ 'required-fields' | translate }}</i>
            <app-button primary [disabled]="newPassword.password === ''" (action)="setPassword()">{{ 'button.confirm' | translate }}</app-button>
          </div>
        </app-card>
      </div>
      <div *ngIf="view === 'forgotten'" class="password-view__forgotten">
        <app-headline icon="lock">{{ 'password.forgotten.headline' | translate }}</app-headline>
        <app-card>
          {{ 'password.forgotten.description' | translate }}
          <app-input required [config]="emailConfig"></app-input>
          <mat-error *ngIf="passwordError !== ''">{{ passwordError | translate }}</mat-error>
          <div class="actions">
            <i class="flex">{{ 'required-fields' | translate }}</i>
            <app-button primary [disabled]="invalidEmail" (action)="requestNewPassword()">{{ 'button.resetPassword' | translate }}</app-button>
          </div>
        </app-card>
      </div>
    </app-content>
  </div>
</div>
