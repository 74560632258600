<mat-form-field class="select" appearance="standard" *ngIf="config.options.length">
  <mat-label>{{ config.label | translate }}</mat-label>
  <mat-select [formControl]="config.control" [multiple]="config.multiple">
    <mat-option *ngFor="let option of config.options"  [disabled]="option.disabled" [value]="option.value">{{ option.name | translate }}</mat-option>
  </mat-select>
  <mat-hint *ngIf="config.hint">{{ config.hint | translate }}</mat-hint>
  <mat-error *ngIf="config.control.hasError('required')">
    {{ getErrorMessage() }}
  </mat-error>
</mat-form-field>
