import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NavigationItem, NavigationType, Role } from 'src/app/models/security.model';
import { AccountModel, StateModel } from 'src/app/models/store.model';
import { navigationItems } from 'src/app/routing/navigation-items';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends GarbageCollectorComponent implements OnInit {

  public avaibleItems: NavigationItem[] = [];
  public name?: string;
  public image?: string;

  constructor(
    private _store: Store,
    private _router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this._store.select((state: StateModel) => state.security.role).subscribe((role: Role) => {
        this.avaibleItems = navigationItems.filter((item: NavigationItem) => {
          return item.authorized.includes(role) && item.type.includes(NavigationType.MENU);
        });
        this.avaibleItems.sort((a, b) => {
          return a.order - b.order;
        });
      })
    );
    this.addSubscription(
      this._store.select((state: StateModel) => state.account).subscribe((account: AccountModel) => {
        this.name = `${account.firstName} ${account.lastName}`.trim();
        this.image = account.image ? `url(${account.image})` : '';
      })
    );
  }

  public routeTo(route: string[]): void {
    this._router.navigate(route);
  }

}
