import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { PhotoState, PhotoUpload } from 'src/app/models/file-upload.model';
import { EditorService } from 'src/app/services/editor/editor.service';
import { PhotosService } from 'src/app/services/photos.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-photos-upload',
  templateUrl: './photos-upload.component.html',
  styleUrls: ['./photos-upload.component.scss']
})
export class PhotosUploadComponent extends GarbageCollectorComponent implements OnInit {

  @ViewChild('dropboxElement')
  public overlayRef!: ElementRef;
  public dragging = false;
  public photos: PhotoUpload[] = [];
  public allPhotos: PhotoUpload[] = [];
  public preview: PhotoUpload | undefined;
  public verifiedCount = 0;
  public uploadStates: PhotoState[] = [PhotoState.IMPORTED, PhotoState.INVALID, PhotoState.UPLOADED];

  public fileInput = '';
  private _accept: string[] = ['.jpg', '.JPG', '.jepg'];
  private _maxFileSize = 16 * 1024 * 1024; // MB
  private _count = 0;

  constructor(
    private _photosService: PhotosService,
    private _snackbarService: SnackbarService,
    private _editorService: EditorService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this._photosService.getPhotos().subscribe((photos: PhotoUpload[]) => {
        this.allPhotos = photos;
        this.photos = photos.filter((photo: PhotoUpload) => {
          return [PhotoState.IMPORTED, PhotoState.INVALID, PhotoState.UPLOADED].includes(photo.state);
        });
      })
    );
    this._initOverlay();
  }

  private _initOverlay(): void {
    const document: Document = window.document;

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.addSubscription(
        fromEvent(document, eventName).subscribe((event) => {
          this.preview = undefined;
          event.preventDefault();
          event.stopPropagation();
        })
      );
    });
    ['dragenter', 'dragover'].forEach(eventName => {
      this.addSubscription(
        fromEvent(document, eventName).subscribe(() => {
          this.dragging = true;
        })
      );
    });
    this.addSubscription(
      fromEvent(document, 'mouseleave').subscribe(() => {
        this.dragging = false;
      })
    );
    ['dragleave', 'drop'].forEach(eventName => {
      this.addSubscription(
        fromEvent(document, eventName).subscribe((event) => {
          if (eventName === 'drop') {
            const data: DataTransfer | null = (event as DragEvent).dataTransfer;
            const files: FileList | undefined = data?.files;
            this._handleFiles(files);
            this.dragging = false;
          }
        })
      );
    });
  }

  public onFileChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target?.files?.length) {
      const files: FileList = target.files;
      this._handleFiles(files);
    }
    this.fileInput = '';
  }

  private _handleFiles(files: FileList | undefined): void {
    if (files && files.length) {
      this._count = files.length;
      this._editorService.processingMessage.next('editor.step.upload.overlay.importing');
    }
    Array.from(files || []).forEach((file: File) => {
      const match = this.allPhotos.find(f => f.file.name === file.name);
      const type: string = file.name.match(/\.[a-zA-Z0-9]+$/)?.[0] || 'none';
      const oversize = file.size > this._maxFileSize;
      if (match) {
        this._snackbarService.showWarning(['snackbar.alreadyExists', file.name]);
      }
      if (oversize) {
        this._snackbarService.showWarning(['snackbar.oversize', file.name]);
      }
      if (!match && !oversize && (this._accept.includes(type) || this._accept.length === 0)) {
        this._createPreview(file);
      } else {
        this._count--;
        if (this._count <= 0) {
          this._editorService.processingMessage.next('');
        }
      }
    });
  }

  private _createPreview(file: File): void {
    const reader = new FileReader();
    reader.onload = (src) => {
      const source = `${src.target?.result}`;
      if (source.startsWith('data:image')) {
        const image = new Image();
        image.onload = () => {
          this._photosService.addPhoto(new PhotoUpload(file, image));
          this._count--;
          if (this._count <= 0) {
            this._editorService.processingMessage.next('');
          }
        };
        image.src = source;
      }
    };
    reader.readAsDataURL(file);
  }

}
