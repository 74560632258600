<app-content>
  <div class="user-view">
    <app-headline icon="person">{{ 'user.headline' | translate }}</app-headline>
    <app-card>
      <div class="row">
        <app-autocomplete class="flex" required [config]="salutationConfig"></app-autocomplete>
        <div class="flex"></div>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="firstNameConfig"></app-input>
        <app-input class="flex" required [config]="lastNameConfig"></app-input>
      </div>
      <div *ngIf="role === 'PHOTOGRAPHER'" class="row">
        <app-input class="flex" required [config]="companyConfig"></app-input>
        <app-autocomplete class="flex" required [config]="companyLegalFormConfig"></app-autocomplete>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="emailConfig"></app-input>
        <app-input *ngIf="role === 'PHOTOGRAPHER'" class="flex" [config]="websiteConfig"></app-input>
        <div *ngIf="role !== 'PHOTOGRAPHER'" class="flex"></div>
      </div>
      <div *ngIf="role === 'PHOTOGRAPHER'" class="row">
        <app-input class="flex" [config]="memberConfig"></app-input>
        <div class="flex"></div>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="phone1Config"></app-input>
        <app-input class="flex" [config]="phone2Config"></app-input>
      </div>
      <mat-error *ngIf="updateError !== ''">{{ updateError | translate }}</mat-error>
      <div class="actions">
        <i class="flex">{{ 'required-fields' | translate }}</i>
        <app-button secondary (action)="back()">{{ 'button.back' | translate }}</app-button>
        <app-button primary [disabled]="locked" (action)="update()">{{ 'button.applyChanges' | translate }}</app-button>
      </div>
    </app-card>
  </div>
</app-content>
