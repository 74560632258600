import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent extends GarbageCollectorComponent implements OnInit {

  public password1Config: InputConfig = new InputConfig(InputType.PASSWORD, 'input.password1.label', '', 'input.password1.hint');
  public password2Config: InputConfig = new InputConfig(InputType.PASSWORD, 'input.password2.label');

  @Input()
  public set disabled(state: boolean) {
    if (state === true && this.password1Config.control.enabled && this.password2Config.control.enabled) {
      this.password1Config.control.disable();
      this.password2Config.control.disable();
    } else {
      this.password1Config.control.enable();
      this.password2Config.control.enable();
    }
  }

  @Input()
  public empty?: string;

  @Output()
  public password: EventEmitter<string> = new EventEmitter();

  public rules = [
    {
      text: 'password.rules.length',
      pattern: '.{8,}',
      valid: false
    },
    {
      text: 'password.rules.capital',
      pattern: '[A-ZÄÖÜ]+',
      valid: false
    },
    {
      text: 'password.rules.number',
      pattern: '\\d+',
      valid: false
    },
    {
      text: 'password.rules.special',
      pattern: '[!"§$%&/()=?]+',
      valid: false
    }
  ];
  private _passwordForm: FormGroup = this._fb.group({});

  constructor(
    private _fb: FormBuilder,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this.password1Config.control.valueChanges.subscribe((password: string) => {
        this.rules.forEach((rule: any) => {
          const regex = new RegExp(rule.pattern);
          rule.valid = regex.test(password);
        });
        this.password1Config.control.setErrors(this.rules.filter((rule => !rule.valid)).length ? {passwordRules: true} : null);
      })
    );
    this.addSubscription(
      this.password2Config.control.valueChanges.subscribe((password: string) => {
        this.password2Config.control.setErrors(this.password1Config.control.value === password ? null : {passwordRepeat: true});
      })
    );
    this._passwordForm.addControl('password1', this.password1Config.control);
    this._passwordForm.addControl('password2', this.password2Config.control);
    this.addSubscription(
      this._passwordForm.valueChanges.subscribe(() => {
        const newPassword: boolean = this._passwordForm.valid && this.password1Config.control.value === this.password2Config.control.value;
        this.password.emit(newPassword ? this.password1Config.control.value : '');
      })
    );
    if (this.empty !== undefined) {
      this.password1Config.control.setValue('***');
      this.password2Config.control.setValue('***');
      setTimeout(() => {
        this.password1Config.control.reset();
        this.password2Config.control.reset();
      }, 500);
    }
  }

}
