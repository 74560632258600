<div class="archive-view">
  <div class="archive-view__size" [ngClass]="{'archive-view__size--maximized': available && !locked}">
    <div class="row">
      <app-headline class="flex" icon="photo_album">{{ 'archive.headline' | translate }}</app-headline>
      <div class="headline-action">
        <app-button *ngIf="locked" (action)="unlock()" icon="lock_open">{{ 'button.unlock' | translate }}</app-button>
      </div>
    </div>
    <app-card>

      <p *ngIf="locked">{{ 'archive.locked' | translate }}</p>
      <p *ngIf="!locked && available">{{ 'archive.description' | translate }}</p>
      <p *ngIf="!locked && !available">{{ 'archive.empty' | translate }}</p>

      <app-photos-overview *ngIf="!locked" [states]="archiveStates"></app-photos-overview>

    </app-card>
  </div>

</div>
