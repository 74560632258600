<app-content>
  <div class="analytics-view">
    <app-headline icon="analytics">{{ 'analytics.headline' | translate }}</app-headline>
    <app-card>
      <iframe
        *ngIf="url"
        [src]="url"
        class="analytics-view__iframe"
      ></iframe>
    </app-card>
  </div>
</app-content>
