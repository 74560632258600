import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from '../app.component';
import { RoutingModule } from './routing.module';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from 'src/environments/environment';
import { SecurityState } from '../store/security/security.state';
import { LocalizationModule } from '@tallence/localization';
import { AccountState } from '../store/account/account.state';
import { AdministrationState } from '../store/administration/administration.state';
import { MaterialModule } from './material.module';
import { ComponentsModule } from './components.module';
import { ConfigService } from '../services/config.service';
import { CookieService } from 'ngx-cookie-service';
import * as Hammer from 'hammerjs';

export function appInit(configService: ConfigService): any {
  return () => configService.loadApi();
}

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    rotate: {
      direction: Hammer.DIRECTION_ALL,
      enable: true,
    },
    pinch: {
        direction: Hammer.DIRECTION_ALL,
        enable: true,
    },
    pan: {
        direction: Hammer.DIRECTION_ALL,
        requireFailure: ['rotate, pinch']
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RoutingModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([
      SecurityState,
      AccountState,
      AdministrationState
    ], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    HttpClientModule,
    MaterialModule,
    ComponentsModule,
    LocalizationModule,
    HammerModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER, useFactory: appInit, deps: [ConfigService], multi: true
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
