import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Application, TableAction, TableActionType, TableConfig } from 'src/app/models/administration.model';
import { StateModel } from 'src/app/models/store.model';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/register/user.service';

@Component({
  selector: 'app-applications-view',
  templateUrl: './applications-view.component.html',
  styleUrls: ['./applications-view.component.scss']
})
export class ApplicationsViewComponent extends GarbageCollectorComponent implements OnInit {

  constructor(
    private _store: Store,
    private _router: Router,
    private _userService: UserService
  ) {
    super();
  }

  public _applications: Application[] = [];
  public tableConfig: TableConfig = new TableConfig();

  public ngOnInit(): void {
    this.addSubscription(
      this._store.select((state: StateModel) => state.administration.applications).subscribe((applications: Application[]) => {
        this._applications = applications.map((application: Application) => {
          return {
            ...application,
            created: moment(application.created).locale('de').format('L')
          };
        });
        this.tableConfig.header = ['created', 'role', 'firstName', 'lastName', 'email'];
        this.tableConfig.items = this._applications;
        this.tableConfig.actions = [TableActionType.VIEW_APPLICATION];
        this.tableConfig.click = TableActionType.VIEW_APPLICATION;
        this.tableConfig = {...this.tableConfig};
      })
    );
    this._userService.getApplications();
  }

  public configChanged(config: TableConfig): void {
    console.log(config);
  }

  public onAction(action: TableAction): void {
    switch (action.name) {
      case TableActionType.VIEW_APPLICATION:
        this._router.navigate(['application', action.id]);
        break;
      default:
        break;
    }
  }

}
