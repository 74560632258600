import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  private _svgPath = '../assets/icons/';

  private _icons: string[] = [
    'account-star',
    'edit-outline',
    'update-outline',
    'delete-outline',
    'camera-account',
    'card-account-details',
    'account-circle-outline',
    'home-plus-outline',
    'home-minus-outline',
    'account-off-outline',
    'account-reactivate-outline',
    'shield-key-outline',
    'text-box-check-outline',
    'card-search-outline',
    'download-circle-outline',
    'arrow-up-bold',
    'account-box',
    'account-edit',
    'drag-and-drop',
    'account-cancel',
    'calendar-edit',
    'home-search-outline'
  ];

  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {}

  public init(): void {
    this._icons.forEach((icon) => {
      this._matIconRegistry.addSvgIcon(
        icon,
        this._domSanitizer.bypassSecurityTrustResourceUrl(`${this._svgPath}${icon}.svg`)
      );
    });
  }

  public isOwnIcon(icon: string): boolean {
    return this._icons.includes(icon);
  }

}
