import {
  Component, OnInit, ViewChild, ElementRef
} from '@angular/core';
import { ConsoleService, Message } from 'src/app/services/console.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.scss']
})
export class ConsoleComponent extends GarbageCollectorComponent implements OnInit {

  public messages: Message[] = [];
  @ViewChild('body')
  public bodyRef!: ElementRef;

  constructor(
    private _consoleService: ConsoleService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this._consoleService.message.subscribe(message => {
        const matches: RegExpMatchArray | null = message.text.match(/\*\w*\*/g);
        if (matches) {
          matches.forEach((match: string) => {
            const bold = match.replace(/\*/g, '');
            message.text = message.text.replace(match, `<b>${bold}</b>`);
          });
        }

        this.messages.push(message);

        if (message.duration) {
          setTimeout(() => {
            this.clearMessage(message);
          }, message.duration * 1000);
        }

        if (this.bodyRef) {
          setTimeout(() => {
            const el = this.bodyRef.nativeElement as HTMLElement;
            if (el && el.children.length) {
              el.children[el.childElementCount - 1].scrollIntoView({ block: 'start', behavior: 'smooth' });
            }
          }, 1);
        }
      })
    );
  }

  public clearMessage(message: Message): void {
    const index = this.messages.indexOf(message);
    if (index !== -1) {
      this.messages.splice(this.messages.indexOf(message), 1);
    }
  }

  public clearAll(): void {
    this.messages = [];
  }

}
