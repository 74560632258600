<div class="menu">
  <button class="menu__trigger" mat-icon-button [matMenuTriggerFor]="menu" [ngStyle]="{'background-image': image}">
    <mat-icon *ngIf="!image" svgIcon="account-star"></mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <div *ngIf="name" class="menu__user">{{ name }}</div>
    <button *ngFor="let item of avaibleItems" mat-menu-item (click)="routeTo(item.route)">
      <mat-icon>{{ item.icon }}</mat-icon>
      <span>{{ item.label | translate }}</span>
    </button>
  </mat-menu>
</div>
