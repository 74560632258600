import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizationService } from '@tallence/localization';
import { combineLatest, fromEvent, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ResponseStatus } from 'src/app/models/administration.model';
import { PhotoUpload, ProcessingAction, ProcessingActionType, CropValue, Movement } from 'src/app/models/file-upload.model';
import { SelectConfig, SelectOption } from 'src/app/models/select.model';
import { SliderConfig } from 'src/app/models/slider.model';
import { EditorService } from 'src/app/services/editor/editor.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Canvas } from 'src/app/utilities/canvas';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-photo-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.scss']
})
export class PhotoEditorComponent extends GarbageCollectorComponent implements OnInit, OnDestroy {

  @ViewChild('canvas')
  public canvasRef!: ElementRef;
  @ViewChild('image')
  public imageRef!: ElementRef;
  @ViewChild('imageFrame')
  public imageFrameRef!: ElementRef;
  @ViewChild('boundaries')
  public boundariesRef!: ElementRef;
  @ViewChild('overlay')
  public overlayRef!: ElementRef;
  @ViewChild('shadowMask')
  public shadowMaskRef!: ElementRef;

  public photo?: PhotoUpload;

  public rotationConfig: SliderConfig = new SliderConfig('slider.rotation.label', '°');
  public zoomConfig: SliderConfig = new SliderConfig('slider.zoom.label', 'x');
  public brightnessConfig: SliderConfig = new SliderConfig('slider.brightness.label', '%');
  public contrastConfig: SliderConfig = new SliderConfig('slider.contrast.label', '%');
  public templateConfig: SelectConfig = new SelectConfig('select.template.label');

  public invalidMask: boolean[] = [false, false, false, false];
  public locked = false;
  public showTemplate = false;
  public mouseover = false;

  private _movement: Movement | null = null;
  private _rotation = 0;
  private _rotationStartValue = 0;
  private _rotationValue = 0;
  private _zoomValue = 0;
  private _tranformationEnabled = false;
  private _transformAction = '';

  constructor(
    public dialogRef: MatDialogRef<PhotoEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PhotoUpload,
    private _localizationService: LocalizationService,
    private _snackbarService: SnackbarService,
    private _editorService: EditorService,
  ) {
    super();
  }

  public ngOnInit(): void {

    this.rotationConfig.step = 0.1;
    this.rotationConfig.min = -15;
    this.rotationConfig.max = 15;
    this.zoomConfig.step = 0.01;
    this.zoomConfig.min = 1;
    this.zoomConfig.max = 2;
    this.brightnessConfig.step = 1;
    this.brightnessConfig.min = -25;
    this.brightnessConfig.max = 25;
    this.contrastConfig.step = 1;
    this.contrastConfig.min = -25;
    this.contrastConfig.max = 25;

    this.addSubscription(
      this.dialogRef.afterOpened().subscribe(() => {
        this._initEditor();
      })
    );

    this.photo = this.data;

    this.addSubscription(
      combineLatest([
        this.zoomConfig.control.valueChanges,
        this.rotationConfig.control.valueChanges,
      ]).subscribe(values => {
        if (this.imageRef) {
          const image: HTMLElement = this.imageRef.nativeElement as HTMLElement;
          const zoom = parseFloat(`${values[0]}`.replace(',', '.'));
          const rotation = parseFloat(`${values[1]}`.replace(',', '.'));
          if (image && !isNaN(rotation)) {
            image.style.transform = `rotate(${rotation}deg) scale(${zoom})`;
            this.checkMask();
          }
        }
      })
    );
    this.addSubscription(
      combineLatest([
        this.brightnessConfig.control.valueChanges,
        this.contrastConfig.control.valueChanges
      ]).subscribe(values => {
        if (this.imageRef) {
          const image: HTMLElement = this.imageRef.nativeElement as HTMLElement;
          let brightness = parseFloat(`${values[0]}`.replace(',', '.'));
          brightness = !isNaN(brightness) ? brightness : 0;
          let contrast = parseFloat(`${values[1]}`.replace(',', '.'));
          contrast = !isNaN(contrast) ? contrast : 0;
          image.style.filter = `brightness(${100 + brightness}%) contrast(${100  + contrast}%)`;
        }
      })
    );
    this._localizationService.getArrayFromKey('select.template.options').forEach(key => {
      this.templateConfig.options.push(new SelectOption(`select.template.options.${key}`, key));
    });
    this.templateConfig.control.setValue('adult');
  }

  private _initEditor(): void {
    if (this.overlayRef && this.canvasRef) {
      const overlay: HTMLElement = this.overlayRef.nativeElement as HTMLElement;
      const canvas: HTMLElement = this.canvasRef.nativeElement as HTMLElement;

      this.zoomConfig.control.updateValueAndValidity();
      this.rotationConfig.control.updateValueAndValidity();
      this.brightnessConfig.control.updateValueAndValidity();
      this.contrastConfig.control.updateValueAndValidity();
      canvas.style.height = `${window.innerHeight - (2 * canvas.offsetTop)}px`;
      overlay.style.top = '10%';
      overlay.style.left = '15%';

      if (this.photo) {
        if (this.photo.width < this.photo.height) {
          overlay.style.width = '70%';
        } else {
          overlay.style.width = `${(80 / (4.5 / 3.5)) * (this.photo.height / this.photo.width)}%`;
        }
      }

      this.addSubscription(
        fromEvent(window, 'resize').subscribe(() => {
          canvas.style.height = `${window.innerHeight - (2 * canvas.offsetTop)}px`;
          this._fitBoundaries();
          this.checkMask();
        })
      );
      this._fitBoundaries();
    }
  }

  public moveOnCanvas(e: any): void {
    if (this._movement) {
      const move: Movement = {
        x: e.srcEvent.pageX - this._movement.x,
        y: e.srcEvent.pageY - this._movement.y
      };
      if (this._transformAction === 'drag') {
        this._drag(move);
      }
      if (['tl', 'tr', 'bl', 'br'].includes(this._transformAction)) {
        this._resize(move);
      }
    }
    this._movement = {x: e.srcEvent.pageX, y: e.srcEvent.pageY};
  }

  public startTransformation(e: any): void {
    this._rotation = e.rotation;
    this._rotationStartValue = this.rotationConfig.control.value;
    this._tranformationEnabled = true;
  }

  public endTransformation(e: any): void {
    const rotation = Math.round(this._rotationValue * 10) / 10;
    const zoom = Math.round(this._zoomValue * 100) / 100;
    this.rotationConfig.control.setValue(rotation);
    this.zoomConfig.control.setValue(zoom);
    this._tranformationEnabled = false;
  }

  public transform(e: any): void {
    if (this._tranformationEnabled) {
      let rotation = this._rotationStartValue + ((e.rotation - this._rotation) * 0.5);
      rotation = Math.min(Math.max(rotation, this.rotationConfig.min), this.rotationConfig.max);
      let zoom = this.zoomConfig.control.value;
      if (e.scale < 1) {
        zoom = Math.max(zoom - ((1 - e.scale) * 0.4), this.zoomConfig.min);
      } else {
        zoom = Math.min(zoom + ((e.scale - 1) * 0.3), this.zoomConfig.max);
      }
      this._zoomValue = zoom;

      if (this.imageRef) {
        const image: HTMLElement = this.imageRef.nativeElement as HTMLElement;
        if (image && !isNaN(rotation)) {
          this._rotationValue = rotation;
          image.style.transform = `rotate(${rotation}deg) scale(${zoom})`;
        }
      }
    }
  }

  private _fitBoundaries(): void {
    if (this.photo) {
      const canvas: HTMLElement = this.canvasRef.nativeElement as HTMLElement;
      const image: HTMLElement = this.imageRef.nativeElement as HTMLElement;
      const boundaries: HTMLElement = this.boundariesRef.nativeElement as HTMLElement;
      const imageFrame: HTMLElement = this.imageFrameRef.nativeElement as HTMLElement;
      imageFrame.style.width = '';
      imageFrame.style.height = '';

      const pRatio = this.photo.height / this.photo.width;
      const cRatio = image.clientHeight / image.clientWidth;

      if (pRatio > cRatio) {
        const bWidth = image.clientHeight / pRatio;
        boundaries.style.height = `${(100 / canvas.clientHeight) * image.clientHeight}%`;
        boundaries.style.width = `${(100 / canvas.clientWidth) * bWidth}%`;
        boundaries.style.left = `${(100 / canvas.clientWidth) * ((canvas.clientWidth - bWidth) / 2)}%`;
        boundaries.style.top = `${(100 / canvas.clientHeight) * ((canvas.clientHeight - image.clientHeight) / 2)}%`;
      } else {
        const bHeight = image.clientWidth * pRatio;
        boundaries.style.width = `${(100 / canvas.clientWidth) * image.clientWidth}%`;
        boundaries.style.height = `${(100 / canvas.clientHeight) * bHeight}%`;
        boundaries.style.left = `${(100 / canvas.clientWidth) * ((canvas.clientWidth - image.clientWidth) / 2)}%`;
        boundaries.style.top = `${(100 / canvas.clientHeight) * ((canvas.clientHeight - bHeight) / 2)}%`;
      }
      imageFrame.style.width = `${boundaries.offsetWidth}px`;
      imageFrame.style.height = `${boundaries.offsetHeight}px`;
      this._checkBoundaries();
    }
  }

  private _drag(move: Movement): void {
    const overlay: HTMLElement = this.overlayRef.nativeElement as HTMLElement;
    overlay.style.top = `${overlay.offsetTop + move.y}px`;
    overlay.style.left = `${overlay.offsetLeft + move.x}px`;
    this._checkBoundaries();
  }

  private _resize(move: Movement): void {
    const overlay: HTMLElement = this.overlayRef.nativeElement as HTMLElement;
    const boundaries: HTMLElement = this.boundariesRef.nativeElement as HTMLElement;

    switch (this._transformAction) {
      case 'tl':
        if (overlay.offsetLeft + move.x > 0) {
          overlay.style.width = `${overlay.clientWidth - move.x}px`;
        }
        break;
      case 'tr':
        if ((overlay.offsetLeft + overlay.clientWidth + move.x) < boundaries.clientWidth) {
          overlay.style.width = `${overlay.clientWidth + move.x}px`;
        }
        break;
      case 'br':
        if ((overlay.offsetLeft + overlay.clientWidth + move.x) < boundaries.clientWidth) {
          overlay.style.width = `${overlay.clientWidth + move.x}px`;
        }
        break;
      case 'bl':
        if (overlay.offsetLeft + move.x > 0) {
          overlay.style.width = `${overlay.clientWidth - move.x}px`;
        }
        break;
      default:
        break;
    }
    this._checkBoundaries();
  }

  public lookCorner(corner: string): void {
    const overlay: HTMLElement = this.overlayRef.nativeElement as HTMLElement;
    const boundaries: HTMLElement = this.boundariesRef.nativeElement as HTMLElement;

    const top = `${overlay.offsetTop}px`;
    const left = `${overlay.offsetLeft}px`;
    const right = `${boundaries.clientWidth - (overlay.offsetLeft + overlay.clientWidth)}px`;
    const bottom = `${boundaries.clientHeight - (overlay.offsetTop + overlay.clientHeight)}px`;

    switch (corner) {
      case 'tl':
        overlay.style.top = 'auto';
        overlay.style.right = right;
        overlay.style.bottom = bottom;
        overlay.style.left = 'auto';
        break;
      case 'tr':
        overlay.style.top = 'auto';
        overlay.style.bottom = bottom;
        overlay.style.right = 'auto';
        overlay.style.left = left;
        break;
      case 'br':
        overlay.style.top = top;
        overlay.style.right = 'auto';
        overlay.style.bottom = 'auto';
        overlay.style.left = left;
        break;
      case 'bl':
        overlay.style.top = top;
        overlay.style.right = right;
        overlay.style.bottom = 'auto';
        overlay.style.left = 'auto';
        break;
      default:
        break;
    }
    this._transformAction = corner;

  }

  public unlookCorner(): void {
    const overlay: HTMLElement = this.overlayRef.nativeElement as HTMLElement;
    const boundaries: HTMLElement = this.boundariesRef.nativeElement as HTMLElement;

    overlay.style.top = `${(100 / boundaries.clientHeight) * overlay.offsetTop}%`;
    overlay.style.left = `${(100 / boundaries.clientWidth) * overlay.offsetLeft}%`;
    overlay.style.right = 'auto';
    overlay.style.bottom = 'auto';
    overlay.style.width = `${(100 / boundaries.clientWidth) * overlay.offsetWidth}%`;

    this._transformAction = '';
  }

  public startDragging(): void {
    this._transformAction = 'drag';
  }

  public stopDragging(): void {
    this._transformAction = '';
  }

  public checkMask(): void {
    const overlay: HTMLElement = this.overlayRef.nativeElement as HTMLElement;
    const boundaries: HTMLElement = this.boundariesRef.nativeElement as HTMLElement;

    this.invalidMask = [
      {x: overlay.offsetLeft, y: overlay.offsetTop},
      {x: overlay.offsetLeft + overlay.clientWidth - 1, y: overlay.offsetTop},
      {x: overlay.offsetLeft + overlay.clientWidth - 1, y: overlay.offsetTop + overlay.clientHeight - 1},
      {x: overlay.offsetLeft, y: overlay.offsetTop + overlay.clientHeight - 1},
    ].map(cap => {
      return !Canvas.checkBoundaries(
        boundaries.clientWidth,
        boundaries.clientHeight,
        this.rotationConfig.control.value,
        cap.x,
        cap.y,
        this.zoomConfig.control.value
      );
    });
    this.locked = this.invalidMask.includes(true);
  }

  private _checkBoundaries(): void {
    const overlay: HTMLElement = this.overlayRef.nativeElement as HTMLElement;
    const boundaries: HTMLElement = this.boundariesRef.nativeElement as HTMLElement;
    const shadowMask: HTMLCanvasElement = this.shadowMaskRef.nativeElement as HTMLCanvasElement;

    if (overlay.offsetTop < 0) {
      overlay.style.top = '0px';
    }
    if (overlay.offsetLeft < 0) {
      overlay.style.left = '0px';
    }
    if (overlay.offsetTop > (boundaries.clientHeight - overlay.clientHeight)) {
      overlay.style.top = `${boundaries.clientHeight - overlay.clientHeight}px`;
    }
    if (overlay.offsetLeft > (boundaries.clientWidth - overlay.clientWidth)) {
      overlay.style.left = `${boundaries.clientWidth - overlay.clientWidth}px`;
    }
    Canvas.drawShadow(shadowMask, overlay);
  }

  public goBack(): void {
    this.dialogRef.close();
  }

  public applyChanges(): void {
    const overlay: HTMLElement = this.overlayRef.nativeElement as HTMLElement;
    if (this.photo) {
      this.photo.actions = [];
      if (`${this.rotationConfig.control.value}` !== '0') {
        const value = this.rotationConfig.control.value
          + (Number(this.rotationConfig.control.value) !== parseInt(this.rotationConfig.control.value, 10) ? 0 : 0.0001);
        this.photo.actions.push(
          new ProcessingAction(ProcessingActionType.ROTATION, value)
        );
      }
      if (`${this.brightnessConfig.control.value}` !== '0') {
        const value = 1 + (this.brightnessConfig.control.value / 100);
        this.photo.actions.push(new ProcessingAction(
          ProcessingActionType.BRIGHTNESS, value)
        );
      }
      if (`${this.contrastConfig.control.value}` !== '0') {
        const value = 1 + (this.contrastConfig.control.value / 100);
        this.photo.actions.push(new ProcessingAction(
          ProcessingActionType.CONTRAST, value)
        );
      }

      this.unlookCorner();

      const scale = parseFloat(this.zoomConfig.control.value);
      const offX = (this.photo.width * scale - this.photo.width) / 2 / scale;
      const offY = (this.photo.height * scale - this.photo.height) / 2 / scale;
      const x = Math.round((this.photo.width / 100 * parseFloat(overlay.style.left) / scale) + offX);
      const y = Math.round((this.photo.height / 100 * parseFloat(overlay.style.top) / scale) + offY);
      const w = Math.floor(this.photo.width / 100 * (parseFloat(overlay.style.width) / scale));
      const h = Math.floor((w / 3.5) * 4.5);
      this.photo.actions.push(new ProcessingAction(ProcessingActionType.CROP, new CropValue(x, y, w, h)));

      this._editorService.processingMessage.next('editor.step.processing.overlay');

      this.addSubscription(
        this._editorService.applyPhotoActions(this.photo).pipe(
          switchMap((response: ResponseStatus) => {
            if (response === ResponseStatus.SUCCESS && this.photo) {
              return this._editorService.getPhoto(this.photo);
            }
            return of(response);
          })
        ).subscribe((response: ResponseStatus) => {
          if (response === ResponseStatus.SUCCESS) {
            this.dialogRef.close();
          } else {
            this._snackbarService.showError(['snackbar.modifyError']);
          }
          this._editorService.processingMessage.next('');
        })
      );
    }
  }

}
