<div class="table" *ngIf="tableConfig.items.length">
  <table matSort [matSortDisabled]="sortable === undefined" (matSortChange)="sortData($event)" [matSortActive]="tableConfig.sort.active" [matSortDirection]="tableConfig.sort.direction">
    <thead>
      <tr>
        <th
          *ngFor="let key of tableConfig.header"
          [mat-sort-header]="key"
          [disabled]="tableConfig.sortDisabled"
        >{{ 'table.' + key | translate }}</th>
        <th *ngIf="tableConfig.actions.length"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of tableConfig.items" [ngClass]="{'table__row--clickable': tableConfig.click !== '', 'table__row--blacklisted': item.userStatus === 'BLACKLISTED'}" (click)="doAction(tableConfig.click, item)">
        <td *ngFor="let key of tableConfig.header; let first = first;">
          <mat-icon *ngIf="first && item.userStatus === 'BLACKLISTED'" svgIcon="account-cancel"></mat-icon>
          {{ item[key] }}
        </td>
        <td class="table__actions" *ngIf="tableConfig.actions.length">
          <ng-container *ngFor="let action of tableConfig.actions">
            <button mat-icon-button *ngIf="enableAction(item, action)" (click)="doAction(action, item)">
              <mat-icon *ngIf="!isOwn(action)">{{action}}</mat-icon>
              <mat-icon *ngIf="isOwn(action)" [svgIcon]="action"></mat-icon>
            </button>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
  <app-legend [actions]="tableConfig.actions"></app-legend>
  <div *ngIf="paginator !== undefined" class="table__paginator">
    <app-paginator [config]="tableConfig.paginator" (changed)="pageChanged($event)"></app-paginator>
  </div>
</div>
