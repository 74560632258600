import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { ResponseStatus } from 'src/app/models/administration.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { NewPassword } from 'src/app/models/security.model';
import { StateModel } from 'src/app/models/store.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/register/user.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-password-view',
  templateUrl: './password-view.component.html',
  styleUrls: ['./password-view.component.scss']
})
export class PasswordViewComponent extends GarbageCollectorComponent implements OnInit {

  public newPassword: NewPassword = {
    jwToken: '',
    password: ''
  };
  public emailConfig: InputConfig = new InputConfig(InputType.EMAIL, 'input.email.label');
  public invalidEmail = true;
  public view = '';

  public passwordError = '';

  constructor(
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _router: Router,
    private _store: Store,
    private _dialog: MatDialog,
    private _userService: UserService
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this._router.url.includes('forgotten')) {
      this.addSubscription(
        this.emailConfig.control.valueChanges.subscribe(() => {
          this.invalidEmail = this.emailConfig.control.invalid;
        })
      );
      this.view = 'forgotten';
    } else {
      const token: string = this._route.snapshot.params.token;
      if (token && this._authService.checkToken(token)) {
        this.newPassword.jwToken = token;
        this._authService.getDataFromToken(token);
      } else {
        this._router.navigate(['error', '498']);
      }
      this.view = 'new';
    }
  }

  public getNewPassword(password: string): void {
    this.newPassword.password = password;
    this.passwordError = '';
  }

  public setPassword(): void {
    if (this._router.url.includes('reset')) {
      this._setNewPassword();
    } else {
      this.addSubscription(
        this._userService.setPassword(this.newPassword).subscribe((response: ResponseStatus) => {
          if (response === ResponseStatus.SUCCESS) {
            const secret = this._store.selectSnapshot((state: StateModel) => state.security.otpSecret);
            const authName = 'Fotografenregister';
            const otpauth = `otpauth://totp/${authName}?secret=${secret}`;
            this._showAuthenticator(otpauth);
          } else {
            this.passwordError = 'password.new.error';
          }
        })
      );
    }
  }

  private _showAuthenticator(secret: string): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.CODE,
      'dialog.authenticate.headline',
      ['dialog.authenticate.description1'],
      'button.done'
    );
    dialogData.values = { secret };
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
      disableClose: true
    });

    this.addSubscription(
      dialogRef.afterClosed().subscribe(() => {
        if (location.host.includes('frontend.imagesign.link')) {
          this._showFinalInformation();
        } else {
          this._doLogin();
        }
      })
    );
  }

  private _showFinalInformation(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.SUCCESS,
      'dialog.finalInformation.headline',
      ['dialog.finalInformation.description']
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData,
      disableClose: true
    });

    this.addSubscription(
      dialogRef.afterClosed().subscribe(() => {
        location.href = 'https://www.e-passfoto.de/';
      })
    );
  }

  private _doLogin(): void {
    this._router.navigate(['login']);

    // Autologin currently disabled

    /* const email: string = this._store.selectSnapshot((state: StateModel) => state.account.email);
    this.addSubscription(
      this._authService.login(email, this.newPassword.password).subscribe((success: boolean) => {
        if (success) {
          this._router.navigate(['overview']);
        } else {
          this._router.navigate(['login']);
        }
      })
    ); */
  }

  private _setNewPassword(): void {
    this.addSubscription(
      this._userService.setNewPassword(this.newPassword).subscribe((response: ResponseStatus) => {
        if (response === ResponseStatus.SUCCESS) {
          const dialogData: DialogConfig = new DialogConfig(
            DialogType.CONFIRM,
            'dialog.passwordResetted.headline',
            ['dialog.passwordResetted.description'],
            'button.ok',
            ''
          );
          const dialogRef = this._dialog.open(DialogComponent, {
            ...dialogData.layout(),
            data: dialogData
          });
          dialogRef.afterClosed().subscribe(() => {
            this._router.navigate(['overview']);
          });
        } else {
          this.passwordError = 'password.forgotten.failed';
        }
      })
    );
  }

  public requestNewPassword(): void {
    this.addSubscription(
      this._userService.resetPassword(this.emailConfig.control.value).subscribe((response: ResponseStatus) => {
        if (response === ResponseStatus.SUCCESS) {
          const dialogData: DialogConfig = new DialogConfig(
            DialogType.CONFIRM,
            'dialog.passwordReset.headline',
            ['dialog.passwordReset.description'],
            'button.ok',
            ''
          );
          const dialogRef = this._dialog.open(DialogComponent, {
            ...dialogData.layout(),
            data: dialogData
          });
          dialogRef.afterClosed().subscribe(() => {
            this._router.navigate(['overview']);
          });
        } else {
          this.passwordError = 'password.forgotten.error';
        }
      })
    );
  }

}
