import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalizationService } from '@tallence/localization';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private _snackBar: MatSnackBar,
    private _localizationService: LocalizationService
  ) { }

  public showSuccess(translationKey: string[] = ['snackbar.success']): void {
    const button = this._localizationService.getTranslation('button.ok');
    const message = this._localizationService.getTranslation(translationKey[0], translationKey.slice(1));
    this._snackBar.open(message, button, {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: 'snackbar--success',
      duration: 3000
    });
  }

  public showWarning(translationKey: string[] = ['snackbar.warning']): void {
    const button = this._localizationService.getTranslation('button.ok');
    const message = this._localizationService.getTranslation(translationKey[0], translationKey.slice(1));
    this._snackBar.open(message, button, {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: 'snackbar--warning',
      duration: 5000
    });
  }

  public showError(translationKey: string[] = ['snackbar.error']): void {
    const button = this._localizationService.getTranslation('button.ok');
    const message = this._localizationService.getTranslation(translationKey[0], translationKey.slice(1));
    this._snackBar.open(message, button, {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: 'snackbar--error',
      duration: 5000
    });
  }
}
