<div class="sidenav" [ngClass]="{'sidenav--small': small}">
  <div class="sidenav__size" (click)="toggleSize()">
    <mat-icon>{{small ? 'unfold_less' : 'unfold_more'}}</mat-icon>
  </div>

  <div class="sidenav__items">
    <div
      *ngFor="let item of avaibleItems"
      (click)="routeTo(item.route)"
      class="sidenav__item"
      [ngClass]="{'sidenav__item--active': isActive(item.route[0])}"
    >
      <div *ngIf="isActive(item.route[0])" class="sidenav__indicator"></div>
      <app-icon class="sidenav__icon" [icon]="item.icon"></app-icon>
      <div>{{ item.label | translate }}</div>
    </div>
  </div>
</div>
