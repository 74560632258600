import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HardwareProvider, User } from 'src/app/models/administration.model';
import { AutocompleteConfig } from 'src/app/models/autocomplete.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { Helper } from 'src/app/utilities/helper';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';
import { Role } from 'src/app/models/security.model';
import { UserService } from 'src/app/services/register/user.service';
import { CheckboxConfig } from 'src/app/models/checkbox.model';
import { Store } from '@ngxs/store';
import { StateModel } from 'src/app/models/store.model';

@Component({
  selector: 'app-hardware-provider-view',
  templateUrl: './hardware-provider-view.component.html',
  styleUrls: ['./hardware-provider-view.component.scss']
})
export class HardwareProviderViewComponent extends GarbageCollectorComponent implements OnInit {

  private _hardwareProvider: HardwareProvider = new HardwareProvider();
  public updateError = '';
  public isAdmin = false;

  public salutationConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.salutation.label');
  public firstNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.firstName.label');
  public lastNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.lastName.label');
  public emailConfig: InputConfig = new InputConfig(InputType.EMAIL, 'input.email.label');
  public passwordConfig: InputConfig = new InputConfig(InputType.PASSWORD, 'input.password.label');
  public phone1Config: InputConfig = new InputConfig(InputType.PHONE, 'input.phone1.label');
  public companyConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.company.label');
  public companyLegalFormConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.companyLegalForm.label');
  public addressConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.address.label');
  public zipConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.zip.label');
  public locationConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.location.label');
  public adminConfig: CheckboxConfig = new CheckboxConfig('checkbox.admin.label');

  public providerForm: FormGroup = this._fb.group({});
  public locked = true;
  private _isHardwareProviderAdmin = false;

  constructor(
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    private _router: Router,
    private _userService: UserService,
    private _store: Store
  ) {
    super();
  }

  public ngOnInit(): void {
    const email: string = this._route.snapshot.params.email;
    const hardwareProviderUser: User = new User();
    hardwareProviderUser.role = Role.HARDWARE_PROVIDER;
    hardwareProviderUser.email = email;

    this.providerForm.addControl('salutation', this.salutationConfig.control);
    this.providerForm.addControl('firstName', this.firstNameConfig.control);
    this.providerForm.addControl('lastName', this.lastNameConfig.control);
    this.providerForm.addControl('email', this.emailConfig.control);
    this.providerForm.addControl('phone1', this.phone1Config.control);
    this.providerForm.addControl('company', this.companyConfig.control);
    this.providerForm.addControl('companyLegalForm', this.companyLegalFormConfig.control);
    this.providerForm.addControl('address', this.addressConfig.control);
    this.providerForm.addControl('zip', this.zipConfig.control);
    this.providerForm.addControl('location', this.locationConfig.control);

    this.addSubscription(
      this._userService.getUser(hardwareProviderUser).subscribe((hardwareProvider: any) => {
        if (hardwareProvider) {
          Helper.mergeForm(this.providerForm, hardwareProvider);
          this._hardwareProvider = {
            ...new HardwareProvider(),
            ...this.providerForm.value
          };

          const hardware = this._store.selectSnapshot((state: StateModel) => state.administration.users)
            .items.find((user: User) => user.email === email);
          if (hardware) {
            this.isAdmin = this._store.selectSnapshot((state: StateModel) => state.security.role) === Role.HARDWARE_PROVIDER_ADMIN;
            this._isHardwareProviderAdmin = hardware.role === Role.HARDWARE_PROVIDER_ADMIN
          }
          this.adminConfig.control.setValue(this._isHardwareProviderAdmin);

        } else {
          this.back();
        }
      })
    );

    this.addSubscription(
      this.providerForm.statusChanges.subscribe(() => {
        this.locked = this.providerForm.invalid;
      })
    );
  }

  public update(): void {
    const update = Helper.diffData(this._hardwareProvider, this.providerForm.value);
    const user: User = new User();
    user.role = Role.HARDWARE_PROVIDER;
    Helper.mergeData(user, this._hardwareProvider);
    this.addSubscription(
      this._userService.updateUser(update, user).subscribe(success => {
        if (!success) {
          this.updateError = 'hardware-provider.error.update';
        }
      })
    );
    if (this.isAdmin && (this._isHardwareProviderAdmin !== this.adminConfig.control.value)) {
      const newRole: Role = this.adminConfig.control.value ? Role.HARDWARE_PROVIDER_ADMIN : Role.HARDWARE_PROVIDER;
      this.addSubscription(
        this._userService.updateHardwareProviderRole(newRole, this._hardwareProvider.email).subscribe(success => {
          if (!success) {
            this.adminConfig.control.setValue(!this.adminConfig.control.value);
          }
        })
      );
    }
  }

  public back(): void {
    this._router.navigate(['hardware-providers']);
  }

}
