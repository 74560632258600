import { Route } from '@angular/router';
import { Role } from '../models/security.model';
import { AccountViewComponent } from '../views/account-view/account-view.component';
import { AnalyticsViewComponent } from '../views/analytics-view/analytics-view.component';
import { ApplicationViewComponent } from '../views/application-view/application-view.component';
import { ApplicationsViewComponent } from '../views/applications-view/applications-view.component';
import { ArchiveViewComponent } from '../views/archive-view/archive-view.component';
import { CamerasViewComponent } from '../views/cameras-view/cameras-view.component';
import { CompaniesViewComponent } from '../views/companies-view/companies-view.component';
import { CompanyViewComponent } from '../views/company-view/company-view.component';
import { EditorViewComponent } from '../views/editor-view/editor-view.component';
import { EmployeesViewComponent } from '../views/employees-view/employees-view.component';
import { ErrorViewComponent } from '../views/error-view/error-view.component';
import { HandoutViewComponent } from '../views/handout-view/handout-view.component';
import { HardwareProviderViewComponent } from '../views/hardware-provider-view/hardware-provider-view.component';
import { HardwareProvidersViewComponent } from '../views/hardware-providers-view/hardware-providers-view.component';
import { LoginViewComponent } from '../views/login-view/login-view.component';
import { OverviewViewComponent } from '../views/overview-view/overview-view.component';
import { PasswordViewComponent } from '../views/password-view/password-view.component';
import { RegisterViewComponent } from '../views/register-view/register-view.component';
import { UigEmployeeViewComponent } from '../views/uig-employee-view/uig-employee-view.component';
import { UigEmployeesViewComponent } from '../views/uig-employees-view/uig-employees-view.component';
import { UserViewComponent } from '../views/user-view/user-view.component';
import { UsersViewComponent } from '../views/users-view/users-view.component';
import { AuthGuard } from './auth.guard';
import { EditorGuard } from './editor.guard';
import { RoleGuard } from './role.guard';

export const routes: Route[] = [
  {
    path: '',
    redirectTo: '/overview',
    pathMatch: 'full'
  },
  {
    path: 'overview',
    canActivate: [AuthGuard],
    component: OverviewViewComponent,
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    component: LoginViewComponent,
  },
  {
    path: 'password',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'new/:token',
        component: PasswordViewComponent
      },
      {
        path: 'reset/:token',
        component: PasswordViewComponent
      },
      {
        path: 'forgotten',
        component: PasswordViewComponent
      },
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'prefix'
      }
    ]
  },
  {
    path: 'register',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: RegisterViewComponent,
        data: {role: Role.PHOTOGRAPHER}
      },
      {
        path: 'employee',
        children: [
          {
            path: '',
            component: RegisterViewComponent,
            data: {role: Role.EMPLOYEE}
          },
          {
            path: ':token',
            component: RegisterViewComponent,
            data: {role: Role.EMPLOYEE}
          },
        ]
      },
    ]
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    component: AccountViewComponent,
  },
  {
    path: 'employees',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.PHOTOGRAPHER]},
    component: EmployeesViewComponent,
  },
  {
    path: 'uig-employees',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.ADMIN]},
    component: UigEmployeesViewComponent,
  },
  {
    path: 'uig-employee',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.ADMIN]},
    children: [
      {
        path: ':email',
        component: UigEmployeeViewComponent
      },
      {
        path: '',
        redirectTo: '/uig-employees',
        pathMatch: 'prefix'
      }
    ]
  },
  {
    path: 'hardware-providers',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.ADMIN, Role.HARDWARE_PROVIDER_ADMIN]},
    component: HardwareProvidersViewComponent,
  },
  {
    path: 'hardware-provider',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.ADMIN, Role.HARDWARE_PROVIDER_ADMIN]},
    children: [
      {
        path: ':email',
        component: HardwareProviderViewComponent
      },
      {
        path: '',
        redirectTo: '/hardware-providers',
        pathMatch: 'prefix'
      }
    ]
  },
  {
    path: 'users',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.ADMIN, Role.UIG]},
    component: UsersViewComponent,
  },
  {
    path: 'user',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.ADMIN, Role.UIG]},
    children: [
      {
        path: ':email',
        component: UserViewComponent
      },
      {
        path: '',
        redirectTo: '/users',
        pathMatch: 'prefix'
      }
    ]
  },
  {
    path: 'cameras',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.HARDWARE_PROVIDER, Role.HARDWARE_PROVIDER_ADMIN]},
    component: CamerasViewComponent,
  },
  {
    path: 'applications',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.ADMIN, Role.UIG]},
    component: ApplicationsViewComponent,
  },
  {
    path: 'application',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.ADMIN, Role.UIG]},
    children: [
      {
        path: ':email',
        component: ApplicationViewComponent
      },
      {
        path: '',
        redirectTo: '/applications',
        pathMatch: 'prefix'
      }
    ]
  },
  {
    path: 'editor',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.PHOTOGRAPHER, Role.EMPLOYEE]},
    children: [
      {
        path: '',
        component: EditorViewComponent,
        canDeactivate: [EditorGuard],
      },
      {
        path: ':id',
        component: EditorViewComponent,
        canDeactivate: [EditorGuard],
      }
    ]
  },
  {
    path: 'archive',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.PHOTOGRAPHER, Role.EMPLOYEE]},
    component: ArchiveViewComponent,
  },
  {
    path: 'companies',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.ADMIN, Role.UIG, Role.HARDWARE_PROVIDER, Role.HARDWARE_PROVIDER_ADMIN]},
    component: CompaniesViewComponent,
  },
  {
    path: 'company',
    children: [
      {
        path: ':email',
        component: CompanyViewComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {roles: [Role.ADMIN, Role.UIG, Role.HARDWARE_PROVIDER, Role.HARDWARE_PROVIDER_ADMIN]},
      },
      {
        path: '',
        component: CompanyViewComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {roles: [Role.PHOTOGRAPHER]},
      },
    ]
  },
  {
    path: 'error/:code',
    component: ErrorViewComponent,
  },
  {
    path: 'logout',
    canActivate: [AuthGuard],
    component: LoginViewComponent
  },
  {
    path: 'analytics',
    canActivate: [AuthGuard, RoleGuard],
    data: {roles: [Role.ADMIN, Role.UIG]},
    component: AnalyticsViewComponent
  },
  {
    path: 'handout',
    component: HandoutViewComponent
  },
  {
    path: '**',
    redirectTo: 'error/404',
  }
];
