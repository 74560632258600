<ng-container *ngIf="ready">
  <div class="main">
    <div class="row">
      <app-sidenav *ngIf="auth"></app-sidenav>
      <div class="flex">
        <app-header></app-header>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</ng-container>

<app-console></app-console>

