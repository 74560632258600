import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _data: any = null;
  private _api: BehaviorSubject<any> = new BehaviorSubject(this._data);

  constructor(
    private _http: HttpClient
  ) { }

  public loadApi(): Observable<any> {
    return this._http.get('./api.config.json').pipe(
      tap((data) => {
        this._data = data;
        this._api.next(this._data);
      })
    );
  }

  public getApi(): Observable<any> {
    return this._api.pipe(
      filter((data) => {
        return data !== null;
      })
    );
  }

}
