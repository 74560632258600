import { Role } from 'src/app/models/security.model';

const name = 'Security';
const update = `${name} Update`;

export class ResetSecurity {
  public static readonly type: string = `${name} Reset`;
  constructor() { }
}

export class UpdateAuth {
  public static readonly type: string = `${update} Auth`;
  constructor(public auth: boolean) {}
}

export class UpdateRole {
  public static readonly type: string = `${update} Role`;
  constructor(public role: Role) {}
}

export class UpdateSmallSidenav {
  public static readonly type: string = `${update} Small Sidenav`;
  constructor(public smallSidenav: boolean) {}
}

export class UpdateTimeout {
  public static readonly type: string = `${update} Timeout`;
  constructor(public timeout: number) {}
}

export class UpdateLicenseToken {
  public static readonly type: string = `${update} License Token`;
  constructor(public licenseToken: string) {}
}

export class UpdateOtpSecret {
  public static readonly type: string = `${update} Otp Secret`;
  constructor(public otpSecret: string) {}
}
