import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { LocalizationService } from '@tallence/localization';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AutocompleteConfig, AutocompleteOption } from 'src/app/models/autocomplete.model';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent extends GarbageCollectorComponent implements OnInit {

  @Input()
  public config: AutocompleteConfig = new AutocompleteConfig();
  @Input()
  public required?: string;

  public filteredOptions: Observable<AutocompleteOption[]> = of([]);

  constructor(
    private _localizationService: LocalizationService,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.config.options.length === 0) {
      const optionsKey: string = this.config.label.replace(/label$/, 'options');
      this.config.options = this._localizationService.getArrayFromKey(optionsKey).map((subkey: string) => {
        return new AutocompleteOption(`${optionsKey}.${subkey}`);
      });
    }
    this.config.options = this.config.options.map((option: AutocompleteOption) => {
      return new AutocompleteOption(
        this._localizationService.getTranslation(option.value),
        option.disabled
      );
    });
    if (this.required !== undefined) {
      this.config.control.setValidators(Validators.required);
    }
    this.filteredOptions = this.config.control.valueChanges.pipe(
      startWith(''),
      map((value: string) => {
        return this.config.options.filter((option: any) => {
          return `${option.value}`.toLowerCase().includes(value.toLowerCase());
        });
      })
    );
  }

  public getErrorMessage(): string {
    if (this.config.control.hasError('required')) {
      if (this.required) {
        return this._localizationService.getTranslation(this.required);
      } else {
        return this._localizationService.getTranslation('input.error.required', [this.config.label]);
      }
    }
    return '';
  }

}
