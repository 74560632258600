  <div class="photos-upload">
    <div *ngIf="photos.length === 0" class="photos-upload__description">
      {{ 'editor.step.upload.description' | translate }}
    </div>

    <label for="photos-upload-input" class="photos-upload__instruction" [ngClass]="{'photos-upload__instruction--small': photos.length !== 0}">
      <app-icon class="photos-upload__instruction-icon" icon="drag-and-drop"></app-icon>
      <div>
        {{ 'editor.step.upload.drag-and-drop' | translate }}
      </div>
      <input
        #inputElement
        type="file"
        id="photos-upload-input"
        class="photos-upload__input"
        multiple
        [(ngModel)]="fileInput"
        (change)="onFileChange($event)"
      />
    </label>

    <app-photos-overview [states]="uploadStates"></app-photos-overview>
  </div>

<div *ngIf="dragging" class="photos-upload__overlay">
  <div class="photos-upload__overlay-background"></div>
  <div class="photos-upload__overlay-content">
    <div class="photos-upload__overlay-box">
      <app-icon class="photos-upload__overlay-box-background" icon="account-box"></app-icon>
      <app-icon class="photos-upload__overlay-icon" icon="arrow-up-bold"></app-icon>
      <div class="photos-upload__overlay-dash"></div>
    </div>
    <div class="photos-upload__overlay-text">{{ 'editor.step.upload.overlay.drop-files' | translate }}</div>
  </div>
</div>
