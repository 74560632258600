<app-content>
  <div class="applications-view">
    <app-headline icon="text-box-check-outline">{{ 'applications.headline' | translate }}</app-headline>
    <app-card *ngIf="tableConfig.items.length > 0">
      <app-table sortable="true" paginator [config]="tableConfig" (changed)="configChanged($event)" (action)="onAction($event)"></app-table>
    </app-card>
    <app-card *ngIf="tableConfig.items.length === 0">
      {{ 'applications.empty' | translate }}
    </app-card>
  </div>
</app-content>
