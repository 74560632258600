import { Sort } from '@angular/material/sort';
import { Role } from './security.model';

export enum UserStatus {
  UNKNOWN = 'UNKNOWN',
  DELETED = 'DELETED',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  APPROVED = 'APPROVED',
  DEACTIVATED = 'DEACTIVATED',
  BLACKLISTED = 'BLACKLISTED'
}

export enum ResponseStatus {
  NONE,
  PENDING,
  SUCCESS,
  ERROR,
  CONFLICT,
  UNAUTHORIZED
}

export class Employee {
  public image = '';
  public salutation = '';
  public nationality = '';
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone1 = '';
  public phone2 = '';
  public status: UserStatus = UserStatus.UNKNOWN;
  constructor(firstName: string = '', lastName: string = '', email: string = '') {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }
}

export class Photographer {
  public image = '';
  public salutation = '';
  public firstName = '';
  public lastName = '';
  public nationality = '';
  public idCardNumber = '';
  public company = '';
  public companyLegalForm = '';
  public email = '';
  public website = '';
  public phone1 = '';
  public phone2 = '';
  public status: UserStatus = UserStatus.UNKNOWN;
  constructor(firstName: string = '', lastName: string = '', email: string = '') {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }
}

export class User {
  public role: Role = Role.NONE;
  public salutation = '';
  public firstName = '';
  public lastName = '';
  public email = '';
  public status: UserStatus = UserStatus.UNKNOWN;
}

export class Camera {
  public cameraManufacturer: string;
  public cameraModel: string;
  public cameraId: string;
  public publicKey: string;
  public licenseBeginDate: string;
  public licenseEndDate: string;
  constructor(
    cameraManufacturer: string = '',
    cameraModel: string = '',
    cameraId: string = '',
    publicKey: string = '',
    licenseBeginDate: string = '',
    licenseEndDate: string = ''
  ) {
    this.cameraManufacturer = cameraManufacturer;
    this.cameraModel = cameraModel;
    this.cameraId = cameraId;
    this.publicKey = publicKey;
    this.licenseBeginDate = licenseBeginDate;
    this.licenseEndDate = licenseEndDate;
  }
}

export class UigEmployee {
  public role: Role = Role.NONE;
  public salutation: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone1 = '';
  public status: UserStatus = UserStatus.UNKNOWN;
  constructor(salutation: string = '', firstName: string = '', lastName: string = '', email: string = '') {
    this.salutation = salutation;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }
}

export class HardwareProvider {
  public salutation: string;
  public firstName: string;
  public lastName: string;
  public company: string;
  public companyLegalForm: string;
  public address = '';
  public zip = '';
  public location = '';
  public email: string;
  public phone1 = '';
  public status: UserStatus = UserStatus.UNKNOWN;
  constructor(
    salutation: string = '',
    firstName: string = '',
    lastName: string = '',
    company: string = '',
    companyLegalForm: string = '',
    email: string = '')
  {
    this.salutation = salutation;
    this.firstName = firstName;
    this.lastName = lastName;
    this.company = company;
    this.companyLegalForm = companyLegalForm;
    this.email = email;
  }
}

export class Studio {
  public studioName = '';
  public licenseNumber = '';
  public address = '';
  public zip = '';
  public location = '';
}

export class Attachment {
  public name = '';
  public url = '';
}

export class Application extends User {
  public created = '';
  public documents: Attachment[] = [];
  public studios: Studio[] = [];
  constructor() {
    super();
  }
}

export class ApplicationReply {
  public role: Role;
  public email: string;
  public salutation: string;
  public firstName: string;
  public lastName: string;
  public company: string;
  public approvalGranted: boolean;
  public rejectReason: string;

  constructor(role: Role, email: string, salutation: string, firstName: string, lastName: string, company: string, approvalGranted: boolean, rejectReason: string = '') {
    this.role = role;
    this.email = email;
    this.salutation = salutation;
    this.firstName = firstName;
    this.lastName = lastName;
    this.company = company;
    this.approvalGranted = approvalGranted;
    this.rejectReason = rejectReason;
  }
}

export class PaginatorConfig {
  public size = localStorage.getItem('pageRows') ? Number(localStorage.getItem('pageRows')) : 10;
  public current = 1;
  public last = 1;
  public total = 0;
  public disabled = false;
}

export class SearchQuery {
  public offset = 0;
  public limit = 99999;
  public pageLimit = localStorage.getItem('pageRows') ? Number(localStorage.getItem('pageRows')) : 10;
  public roles: Role[];
  public sort: Sort = {active: '', direction: ''};
  public text = '';
  public invitor = ''; // TODO: Change to inviter when it will be fixed by the BE

  constructor(roles: Role[] = []) {
    this.roles = roles;
  }

  public params(): string[] {

    let params = `offset=${this.offset}&limit=${this.limit}`;
    if (this.sort.active !== '' && this.sort.direction !== '') {
      params += `&sortAttribute=${this.sort.active}&sortDirection=${this.sort.direction.replace('desc', 'dsc').toUpperCase()}`;
    }
    if (this.text !== '') {
      params += `&text=${this.text}`;
    }
    if (this.invitor !== '') {
      params += `&invitor=${this.invitor}`;
    }
    if (this.roles.length === 0) {
      return [params];
    } else {
      return this.roles.map((role) => {
        return params + `&role=${role}`;
      });
    }
  }
}

export class TableConfig {
  public header: string[] = [];
  public items: any[] = [];
  public actions: TableActionType[] = [];
  public click: TableActionType = TableActionType.NONE;
  public sort: Sort = {active: '', direction: ''};
  public sortDisabled = false;
  public actionId = 'email';
  public paginator: PaginatorConfig = new PaginatorConfig();
}

export class TableAction {
  public name: TableActionType;
  public id: string;
  constructor(name: TableActionType = TableActionType.NONE, id: string = '') {
    this.name = name;
    this.id = id;
  }
}

export enum TableActionType {
  NONE = '',
  EDIT = 'edit-outline',
  UPDATE = 'update-outline',
  DELETE = 'delete-outline',
  DEACTIVATE = 'block',
  REACTIVATE = 'account-reactivate-outline',
  PUBLIC_KEY = 'shield-key-outline',
  VIEW_APPLICATION = 'card-search-outline',
  DOWNLAOD = 'download-circle-outline',
  REMOVE_STUDIO = 'home-minus-outline',
  STUDIOS = 'holiday_village',
  LICENSE = 'calendar-edit',
  ADD_COMPANY = 'home-plus-outline'
}

export class Handout {
  public studio = '';
  public date = '';
  public token = '';
  public url = '';
  public id = '';
  public expiryDate = '';
  public validationResult: object = [];
  public preview = '';
  public secretKey = '';
}

export class Company {
  public company = '';
  public companyLegalForm = '';
  public email = '';
}
