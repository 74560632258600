import { NavigationItem, NavigationType, Role } from '../models/security.model';

export const navigationItems: NavigationItem[] = [
  {
    authorized: [Role.ADMIN, Role.UIG, Role.HARDWARE_PROVIDER, Role.HARDWARE_PROVIDER_ADMIN, Role.PHOTOGRAPHER, Role.EMPLOYEE, Role.NONE],
    type: [NavigationType.SIDENAV],
    icon: 'dashboard',
    label: 'navigation.overview',
    route: ['overview'],
    order: 0
  },
  {
    authorized: [Role.PHOTOGRAPHER],
    type: [NavigationType.SIDENAV],
    icon: 'group',
    label: 'navigation.employees',
    route: ['employees'],
    order: 5
  },
  {
    authorized: [Role.ADMIN],
    type: [NavigationType.SIDENAV],
    icon: 'card-account-details',
    label: 'navigation.uigEmployees',
    route: ['uig-employees'],
    order: 2
  },
  {
    authorized: [Role.ADMIN, Role.HARDWARE_PROVIDER_ADMIN],
    type: [NavigationType.SIDENAV],
    icon: 'camera-account',
    label: 'navigation.hardwareProvider',
    route: ['hardware-providers'],
    order: 3
  },
  {
    authorized: [Role.ADMIN, Role.UIG],
    type: [NavigationType.SIDENAV],
    icon: 'groups',
    label: 'navigation.users',
    route: ['users'],
    order: 4
  },
  {
    authorized: [Role.ADMIN, Role.UIG],
    type: [NavigationType.SIDENAV],
    icon: 'text-box-check-outline',
    label: 'navigation.applications',
    route: ['applications'],
    order: 6
  },
  {
    authorized: [Role.HARDWARE_PROVIDER, Role.HARDWARE_PROVIDER_ADMIN],
    type: [NavigationType.SIDENAV],
    icon: 'photo_camera',
    label: 'navigation.cameras',
    route: ['cameras'],
    order: 6
  },
  {
    authorized: [Role.PHOTOGRAPHER],
    type: [NavigationType.SIDENAV],
    icon: 'holiday_village',
    label: 'navigation.studios',
    route: ['company'],
    order: 7
  },
  {
    authorized: [Role.ADMIN, Role.UIG, Role.HARDWARE_PROVIDER, Role.HARDWARE_PROVIDER_ADMIN],
    type: [NavigationType.SIDENAV],
    icon: 'home_work',
    label: 'navigation.companies',
    route: ['companies'],
    order: 5
  },
  {
    authorized: [Role.PHOTOGRAPHER, Role.EMPLOYEE],
    type: [NavigationType.SIDENAV],
    icon: 'add_photo_alternate',
    label: 'navigation.upload',
    route: ['editor'],
    // route: ['photos', 'upload'],
    order: 4
  },
  {
    authorized: [Role.ADMIN, Role.UIG, Role.HARDWARE_PROVIDER, Role.HARDWARE_PROVIDER_ADMIN, Role.PHOTOGRAPHER, Role.EMPLOYEE],
    type: [NavigationType.MENU],
    icon: 'face',
    label: 'navigation.account',
    route: ['account'],
    order: 0
  },
  {
    authorized: [Role.ADMIN, Role.UIG, Role.HARDWARE_PROVIDER, Role.HARDWARE_PROVIDER_ADMIN, Role.PHOTOGRAPHER, Role.EMPLOYEE, Role.NONE],
    type: [NavigationType.MENU],
    icon: 'logout',
    label: 'navigation.logout',
    route: ['logout'],
    order: 1
  },
  {
    authorized: [Role.ADMIN, Role.UIG],
    type: [NavigationType.SIDENAV],
    icon: 'analytics',
    label: 'navigation.analytics',
    route: ['analytics'],
    order: 7
  },
  {
    authorized: [Role.PHOTOGRAPHER, Role.EMPLOYEE],
    type: [NavigationType.SIDENAV],
    icon: 'photo_album',
    label: 'navigation.archive',
    route: ['archive'],
    order: 4
  },
];
