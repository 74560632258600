import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { LocalizationService } from '@tallence/localization';
import { forkJoin, Observable, of } from 'rxjs';
import { tap, catchError, map, take } from 'rxjs/operators';
import {
  Application,
  ApplicationReply,
  Attachment,
  Employee,
  HardwareProvider,
  Photographer,
  ResponseStatus,
  SearchQuery,
  Studio,
  UigEmployee,
  User
} from 'src/app/models/administration.model';
import { NewPassword, Role } from 'src/app/models/security.model';
import { AccountModel, StateModel } from 'src/app/models/store.model';
import { UpdateAccount } from 'src/app/store/account/account.actions';
import { UpdateApplications, UpdateUsers } from 'src/app/store/administration/administration.actions';
import { Helper } from 'src/app/utilities/helper';
import { ApiService } from '../api.service';
import { ConfigService } from '../config.service';
import { ConsoleService } from '../console.service';
import { SnackbarService } from '../snackbar.service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { UpdateLicenseToken, UpdateOtpSecret } from 'src/app/store/security/security.actions';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _url = '';
  private _endpoint = '';
  private _role: Role = Role.NONE;

  constructor(
    private _apiService: ApiService,
    private _configService: ConfigService,
    private _http: HttpClient,
    private _store: Store,
    private _localizationService: LocalizationService,
    private _consoleService: ConsoleService,
    private _snackbarService: SnackbarService,
  ) {
    this._configService.getApi().subscribe((data: any) => {
      this._url = `${data.register.origin}${data.register.port}${data.register.version}`;
    });
    this._store.select((state: StateModel) => state.security.role).subscribe((role: Role) => {
      this._endpoint = this._getEndpointFromRole(role);
      this._role = role;
    });
  }

  /* ACCOUNT */

  public getAccount(): void {
    this._http.get(`${this._url}${this._endpoint}/me`).pipe(
      tap((account: any) => {
        this._store.dispatch(new UpdateAccount({
          ...new AccountModel(),
          ...account,
          loaded: true
        }));
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          const text: string = this._localizationService.getTranslation('account.error.failure');
          this._consoleService.add(`*${error.status}* ${text}`, '', 0);
        }
        return of(false);
      })
    ).subscribe();
  }

  public updateAccount(update: any): Observable<ResponseStatus> {
    const request = this._http.patch(`${this._url}${this._endpoint}/me`, update);

    return this._apiService.handleResponse(request);
  }

  public deleteAccount(password: string): Observable<ResponseStatus> {
    const headers: HttpHeaders = this._getBasicAuth(password);
    const request = this._http.delete(`${this._url}${this._endpoint}/me`, { headers });

    return this._apiService.handleResponse(request);
  }

  public updatePassword(oldPassword: string, newPassword: string): Observable<ResponseStatus> {
    const update: any = {
      username: this._store.selectSnapshot((state: StateModel) => state.account.email),
      password: newPassword
    };
    const headers: HttpHeaders = this._getBasicAuth(oldPassword);
    const request = this._http.patch(`${this._url}${this._endpoint}/change/password`, update, { headers });

    return this._apiService.handleResponse(request);
  }

  public setPassword(newPassword: NewPassword): Observable<ResponseStatus> {
    const request = this._http.patch(`${this._url}/user/activation`, newPassword).pipe(
      tap((secret: any) => {
        this._store.dispatch(new UpdateOtpSecret(secret.otpSecret || ''));
      })
    );

    return this._apiService.handleResponse(request);
  }

  public setNewPassword(newPassword: NewPassword): Observable<ResponseStatus> {
    const request = this._http.post(`${this._url}/user/password/forgotten/reset`, newPassword);

    return this._apiService.handleResponse(request);
  }

  public resetPassword(email: string): Observable<ResponseStatus> {
    email = encodeURIComponent(email);
    const request = this._http.get(`${this._url}/user/password/forgotten/${email}`);

    return this._apiService.handleResponse(request);
  }

  /* USERS */

  public getUsers(query: SearchQuery): void {
    forkJoin(
      query.params().map(params => {
        return this._http.get(`${this._url}/user?${params}`);
      })
    ).pipe(
      catchError(() => {
        this._snackbarService.showError();
        return of(null);
      })
    ).subscribe((results: any) => {
      if (results) {
        const items: User[] = [];
        let total = 0;
        results.forEach((result: any) => {
          items.push(...result.users.map((data: any) => {
            const user = new User();
            Helper.mergeData(user, data);
            return user;
          }));
          total += result.count;
        });
        this._store.dispatch(new UpdateUsers({
          items,
          total
        }));
      }
    });
  }

  public getUsersCsv(): void {

    const query = new SearchQuery();
    query.limit = 99999;

    this._http.get(`${this._url}/user/studiostaff?${query.params()[0]}`).pipe(
      take(1),
      catchError(() => {
        this._snackbarService.showError();
        return of(null);
      })
    ).subscribe((results: any) => {
      if (results) {

        const users: any[] = results.users || [];

        const userWithMaxStudios = users.reduce((prev, current) => {
          if ((current.studios?.length || 0) > (prev.studios?.length || 0)) {
            return current;
          } else {
            return prev;
          }
        });

        const headers = [
          'salutation',
          'firstName',
          'lastName',
          'member',
          'birthDate',
          'email',
          'phone1',
          'phone2',
          'company',
          'companyLegalForm',
          'website',
          'role',
          'status',
          ...(userWithMaxStudios?.studios || []).map((studio: any) => 'studio')
        ];

        const studioOffset: number = headers.indexOf('studio');

        let csv = '\uFEFF';
        headers.forEach(header => {
          const hedaerItem = this._localizationService.getTranslation(`table.${header}`);
          csv += `"${hedaerItem}";`;
        });
        csv = csv.replace(/;$/, '\n');
        users.forEach((user: any) => {
          headers.forEach((header: string, index: number) => {
            if (header === 'status') {
              const status: string = this._localizationService.getTranslation(`status.${user[header]}`);
              csv += `"${status || ''}";`;
            } else if (header === 'studio') {
              const i: number = index - studioOffset;
              if (user.studios) {
                csv += this._studioToExportString(user.studios[i]);
              } else {
                csv += `"";`;
              }
            } else {
              csv += `"${user[header] || ''}";`;
            }
          });
          csv = csv.replace(/;$/, '\n');
        });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        const fileName = `${this._localizationService.getTranslation('users.csvFilename')}_${moment().format('DDMMYYYY')}.csv`;
        saveAs(blob, fileName);
      }
    });

  }

  private _studioToExportString(studio?: Studio): string {
    return studio ? `"${studio.studioName}: ${studio.address}, ${studio.zip} ${studio.location}";` : `"";`;
  }

  /* USER HANDLING BY EMAIL */

  public getUser(user: User): Observable<Photographer | Employee | UigEmployee | HardwareProvider | null> {
    const email = encodeURIComponent(user.email);
    const endpoint = this._getEndpointFromRole(user.role);
    const request = this._http.get(`${this._url}${endpoint}/${email}`);

    return request.pipe(
      map((data: any) => {
        let userData: Photographer | Employee | UigEmployee | HardwareProvider = new Photographer();
        if (user.role === Role.EMPLOYEE) {
          userData = new Employee();
        }
        if (user.role === Role.UIG || user.role === Role.ADMIN) {
          userData = new UigEmployee();
        }
        if (user.role === Role.HARDWARE_PROVIDER) {
          userData = new HardwareProvider();
        }
        Helper.mergeData(userData, data);
        return userData;
      }),
      catchError(error => {
        return of(null);
      })
    );
  }

  public updateUser(update: any, user: User): Observable<boolean> {
    const email = encodeURIComponent(user.email);
    const endpoint = this._getEndpointFromRole(user.role);
    const request = this._http.patch(`${this._url}${endpoint}/${email}`, update).pipe(
      tap((data: any) => {
          this._snackbarService.showSuccess();
      })
    );

    return this._apiService.handleResponseSimple(request);
  }

  public updateRole(role: Role, email: string): Observable<boolean> {
    const update = {
      uigUserEmail: email,
      uigUserRole: role
    };
    const request = this._http.patch(`${this._url}${this._endpoint}/role`, update);

    return this._apiService.handleResponseSimple(request);
  }

  public updateHardwareProviderRole(role: Role, email: string): Observable<boolean> {
    const update = {
      hardwareProviderEmail: email,
      hardwareProviderRole: role
    };
    const request = this._http.patch(`${this._url}${this._endpoint}/role`, update);

    return this._apiService.handleResponseSimple(request);
  }

  public deleteUser(user: User): Observable<boolean> {
    const email: string = encodeURIComponent(user.email);
    const endpoint = this._getEndpointFromRole(user.role);
    let headers: HttpHeaders = new HttpHeaders();
    const basic = sessionStorage.getItem('basic') || '';
    const auth = Helper.decrypt('auth', basic);
    headers = headers.append('Authorization', `Basic ${auth}`);
    const request = this._http.delete(`${this._url}${endpoint}/${email}`, { headers });

    return this._apiService.handleResponseSimple(request);
  }

  public reactivateUser(email: string, query: SearchQuery): Observable<ResponseStatus> {
    email = encodeURIComponent(email);

    return this._apiService.handleResponse(this._http.patch(`${this._url}/user/reactivation/${email}`, {}).pipe(
      tap(() => {
        this.getUsers(query);
      })
    ));
  }

  public deactivateUser(user: User): Observable<boolean> {
    const email: string = encodeURIComponent(user.email);
    const request = this._http.patch(`${this._url}/user/deactivate/${email}`, {});

    return this._apiService.handleResponseSimple(request);
  }

  /* ADD USER */

  public addUigEmployee(employee: UigEmployee): Observable<ResponseStatus> {
    const uigEmployee: any = {...employee};
    Reflect.deleteProperty(uigEmployee, 'status');
    Reflect.deleteProperty(uigEmployee, 'role');
    const request = this._http.post(`${this._url}${this._getEndpointFromRole(employee.role)}`, uigEmployee);

    return this._apiService.handleResponse(request);
  }

  public addHardwareProvider(provider: HardwareProvider): Observable<ResponseStatus> {
    const hardwareProvider: any = {...provider};
    Reflect.deleteProperty(hardwareProvider, 'status');
    const request = this._http.post(`${this._url}${this._getEndpointFromRole(Role.HARDWARE_PROVIDER)}`, hardwareProvider);

    return this._apiService.handleResponse(request);
  }

  private _getBasicAuth(password: string): HttpHeaders {
    const email: string = this._store.selectSnapshot((state: StateModel) => state.account.email);
    const login: string = btoa(`${email}:${password}`);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', `Basic ${login}`);
    return headers;
  }

  /* APPLICATIONS */

  public getApplications(): void {
    this._http.get(`${this._url}${this._endpoint}/applications`).subscribe((response: any) => {
      const applications: Application[] = response.map((application: any) => {
        return {
          ...new Application(),
          ...application
        };
      });
      this._store.dispatch(new UpdateApplications(applications));
    });
  }

  public replyApplication(reply: ApplicationReply): Observable<ResponseStatus> {
    const request = this._http.post(`${this._url}${this._endpoint}/applications`, reply);

    return this._apiService.handleResponse(request);
  }

  public getAttachment(attachment: Attachment): void {
    this._http.get(attachment.url, {
      responseType: 'blob'
    }).subscribe((blob: Blob) => {
      saveAs(blob, `${attachment.name}`);
    });
  }

  /* HARDWARE LICENSE */

  public getHardwareLicenseToken(): void {
    if (this._role === Role.PHOTOGRAPHER || this._role === Role.EMPLOYEE) {
      const request = this._http.get(`${this._url}/license`);
      request.subscribe((response: any) => {
        this._store.dispatch(new UpdateLicenseToken(response.licenseToken || ''));
      });
    }
  }

  /* FUNCTIONS */

  private _getEndpointFromRole(role: Role): string {
    let endpoint = '';
    switch (role) {
      case Role.PHOTOGRAPHER:
        endpoint = '/photostudio/photographer';
        break;
      case Role.EMPLOYEE:
        endpoint = '/photostudio/employee';
        break;
      case Role.ADMIN:
      case Role.UIG:
        endpoint = '/uig/employee';
        break;
      case Role.HARDWARE_PROVIDER_ADMIN:
      case Role.HARDWARE_PROVIDER:
        endpoint = '/hardware/provider';
        break;
      default:
        break;
    }
    return endpoint;
  }

}
