import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';
import { PhotoState, PhotoUpload } from '../models/file-upload.model';

@Injectable({
  providedIn: 'root'
})
export class PhotosService {

  private _photoUploads: PhotoUpload[] = [];
  private _photos: BehaviorSubject<PhotoUpload[]> = new BehaviorSubject(this._photoUploads);
  private _archive: BehaviorSubject<PhotoUpload[]> = new BehaviorSubject(this._photoUploads);
  private _subscribtion: Subscription = new Subscription();
  private _beforeunload = false;
  public nextStepTrigger: Subject<void> = new Subject();

  constructor(
    private _router: Router,
  ) {
    this._photos.pipe(
      filter(photos => {
        return photos.find(photo => photo.uploaded) !== undefined;
      }),
      takeWhile(photos => {
        return photos.length === 1;
      })
    ).subscribe(() => {
      this._beforeunload = true;
      this._subscribtion.add(fromEvent(window, 'beforeunload').subscribe((e: any) => {
        if (this._beforeunload) {
          // Cancel the event
          e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
          // Chrome requires returnValue to be set
          e.returnValue = '';
        }
      }));
    });
  }

  public getPhotos(): Observable<PhotoUpload[]> {
    return this._photos.asObservable();
  }

  public getArchive(): Observable<PhotoUpload[]> {
    return this._archive.asObservable();
  }

  public getPhotosSnapshot(): PhotoUpload[] {
    return this._photoUploads;
  }

  public addPhoto(photo: PhotoUpload): void {
    this._photoUploads.push({...photo});
    this._photos.next(this._photoUploads);
  }

  public updatePhoto(photo: PhotoUpload): void {
    this._photoUploads = this._photoUploads.map(p => {
      return ((photo.id && p.id === photo.id ) || p.file.name === photo.file.name) ? {...photo} : p;
    });
    this._photos.next(this._photoUploads);
  }

  public updatePhotos(photos: PhotoUpload[]): void {
    this._photoUploads = photos;
    this._photos.next(this._photoUploads);
  }

  public removePhoto(photo: PhotoUpload): void {
    this._photoUploads = this._photoUploads.filter(p => p.file.name !== photo.file.name);
    this._photos.next(this._photoUploads);
  }

  public clearState(): void {
    const archive: PhotoUpload[] = this._photoUploads
      .filter(p => p.state !== PhotoState.IMPORTED && p.state !== PhotoState.INVALID)
      .map(p => {
        return {
          ...p,
          state: PhotoState.ARCHIVED,
          success: '',
          fail: ''
        };
      });
    this._archive.next(archive);
    this._photoUploads = this._photoUploads.filter(p => p.state === PhotoState.IMPORTED);
    this._photos.next(this._photoUploads);
  }

  public clear(): void {
    this._photoUploads = [];
    this._photos.next(this._photoUploads);
    this._beforeunload = false;
    this._subscribtion.unsubscribe();
    this._router.navigate(['logout']);
  }

}
