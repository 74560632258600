import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LocalizationService } from '@tallence/localization';
import { DatePickerConfig } from 'src/app/models/date-picker.model';
import * as moment from 'moment';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

export const CUSTOM_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS },
  ],
})
export class DatePickerComponent extends GarbageCollectorComponent implements OnInit {

  public minDate: moment.Moment | null = null;
  public maxDate: moment.Moment | null = null;

  @Input()
  public config: DatePickerConfig = new DatePickerConfig('');
  @Input()
  public required?: string;
  @Input()
  public set min(date: string | undefined){
    if (moment(date).isValid()) {
      this.minDate = moment(date);
    }
  }
  @Input()
  public set max(date: string | undefined){
    if (moment(date).isValid()) {
      this.maxDate = moment(date);
    }
  }

  public dateControl: FormControl = new FormControl('');

  constructor(
    private _localizationService: LocalizationService
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.required !== undefined) {
      this.config.control.setValidators(Validators.required);
      this.dateControl.setValidators(Validators.required);
      this.config.control.updateValueAndValidity();
    }
    this.addSubscription(
      this.dateControl.valueChanges.subscribe((value: any) => {
        if (moment(value).isValid()) {
          this.config.control.setValue(moment(value).format('YYYY-MM-DD'));
        }
      })
    );
    this.addSubscription(
      this.dateControl.statusChanges.subscribe(() => {
        this.config.control.setErrors(this.dateControl.errors);
      })
    );
    const date = this.config.control.value;
    if (moment(date).isValid()) {
      this.dateControl.setValue(moment(date));
    }
  }

  public getErrorMessage(): string {
    if (this.config.control.hasError('required')) {
      if (this.required) {
        return this._localizationService.getTranslation(this.required);
      } else {
        return this._localizationService.getTranslation('input.error.required', [this.config.label]);
      }
    }
    if (this.config.control.hasError('matDatepickerMin')) {
      return this._localizationService.getTranslation('input.error.minDate');
    }
    return '';
  }

}
