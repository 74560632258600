<div class="editor-view">
  <div class="editor-view__size" [ngClass]="{'editor-view__size--maximized': maximized}">
    <div class="row">
      <app-headline class="flex" icon="add_photo_alternate">{{ 'editor.headline' | translate }}</app-headline>
      <div class="headline-action">
        <app-button (action)="toArchive()" icon="photo_album">{{ 'button.archive' | translate }}</app-button>
      </div>
    </div>
    <app-card>
      <mat-horizontal-stepper [@.disabled]="false" (selectionChange)="stepChanged($event)" [linear]="true" #stepper>

        <!-- STEP: UPLOAD -->
        <mat-step [stepControl]="uploadControl">
          <ng-template matStepLabel>{{ 'editor.step.upload.headline' | translate }}</ng-template>

          <app-photos-upload></app-photos-upload>

          <div class="actions">
            <div class="flex"></div>
            <app-button primary [disabled]="uploadControl.invalid" (action)="stepper.next()">{{ 'button.next' | translate }}</app-button>
          </div>
        </mat-step>

        <!-- STEP: PROCESSING -->
        <mat-step [stepControl]="processingControl">
          <ng-template matStepLabel>{{ 'editor.step.processing.headline' | translate }}</ng-template>

          <app-photos-overview [states]="processingStates"></app-photos-overview>

        </mat-step>

        <!-- STEP: STORAGE -->
        <mat-step [stepControl]="storageControl">
          <ng-template matStepLabel>{{ 'editor.step.storage.headline' | translate }}</ng-template>
          <p>{{ 'editor.step.storage.description' | translate }}</p>

          <app-photos-overview [states]="storageStates"></app-photos-overview>

        </mat-step>

        <!-- STEP: HANDOUT -->
        <mat-step [stepControl]="handoutControl">
          <ng-template matStepLabel>{{ 'editor.step.handout.headline' | translate }}</ng-template>
          <p>{{ 'editor.step.handout.description' | translate }}</p>

          <app-photos-overview [states]="handoutStates"></app-photos-overview>

          <div class="actions">
            <app-button primary (action)="finishProcess()">{{ 'button.done' | translate }}</app-button>
          </div>
        </mat-step>

      </mat-horizontal-stepper>

    </app-card>
  </div>

</div>

<div *ngIf="processingMessage" class="editor-view__processing">
  <app-icon class="editor-view__processing-icon" icon="hourglass_empty"></app-icon>
  <div class="editor-view__processing-text">{{ processingMessage | translate }}</div>
</div>
