import { FormGroup } from '@angular/forms';
import * as CryptoJS from 'crypto-js';

export abstract class Helper {
  public static mergeForm(form: FormGroup, data: any): void {
    Object.keys(form.value).forEach(key => {
      if (data[key]) {
        form.controls[key].setValue(data[key]);
      }
    });
  }
  public static fillEmpty(form: FormGroup, placeholder: string = '---'): void {
    Object.keys(form.value).forEach(key => {
      if (form.controls[key].value === '') {
        form.controls[key].setValue(placeholder);
      }
    });
  }
  public static mergeData(dst: any, src: any): void {
    Object.keys(dst).forEach(key => {
      if (src[key]) {
        dst[key] = src[key];
      }
    });
  }
  public static diffData(oldObj: any, newObj: any): any {
    const diff: any = {};
    Object.keys(oldObj).forEach(key => {
      if (Reflect.has(oldObj, key) && Reflect.has(newObj, key) && oldObj[key] !== newObj[key]) {
        Reflect.set(diff, key, newObj[key]);
      }
    });
    return diff;
  }

  public static blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return b as File;
  }


  public static encrypt(secret: string, message: string): string {
    const key = CryptoJS.enc.Utf8.parse(secret);
    const iv = CryptoJS.enc.Utf8.parse(secret);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(message.toString()), key,
    {
        keySize: 128 / 8,
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  public static decrypt(secret: string, message: string): string {
    const key = CryptoJS.enc.Utf8.parse(secret);
    const iv = CryptoJS.enc.Utf8.parse(secret);
    const decrypted = CryptoJS.AES.decrypt(message, key, {
        keySize: 128 / 8,
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    try {
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      return '';
    }
  }
}
