import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ResponseStatus,
} from '../models/administration.model';
import { Role } from '../models/security.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private _api: any = {};

  constructor() { }

  public handleError(error: any): Observable<ResponseStatus> {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        return of(ResponseStatus.UNAUTHORIZED);
      }
      if (error.status === 409) {
        return of(ResponseStatus.CONFLICT);
      }
    }
    return of(ResponseStatus.ERROR);
  }

  public handleResponse(response: Observable<any>): Observable<ResponseStatus> {
    return response.pipe(
      map(() => {
        return ResponseStatus.SUCCESS;
      }),
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

  public handleResponseSimple(response: Observable<any>): Observable<boolean> {
    return response.pipe(
      map(() => {
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  public getEndpointFromRole(role: Role): string {
    let endpoint = '';
    switch (role) {
      case Role.EMPLOYEE:
        endpoint = this._api.register.endpoint.employee;
        break;
      case Role.PHOTOGRAPHER:
        endpoint = this._api.register.endpoint.photographer;
        break;
      case Role.UIG:
        endpoint = this._api.register.endpoint.uig;
        break;
      case Role.HARDWARE_PROVIDER:
        endpoint = this._api.register.endpoint.hardwareProvider;
        break;
      default:
        break;
    }
    return endpoint;
  }

  /* public getHealth(): void {
    this._http.get(`${this._url.editor}/health`).subscribe((result: any) => {
      console.log(result);
    });
  } */

}
