import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { fromEvent } from 'rxjs';
import { StateModel } from 'src/app/models/store.model';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent extends GarbageCollectorComponent implements OnInit, AfterViewInit {

  @ViewChild('dropboxElement')
  public dropboxRef!: ElementRef;

  @Output()
  public changed: EventEmitter<string> = new EventEmitter();

  public fileInput = '';
  public highlighted = false;
  public image = '';

  constructor(
    private _store: Store
  ) {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this._store.select((state: StateModel) => state.account.image).subscribe((image) => {
        if (image) {
          this.image = `url(${image})`;
        }
      })
    );
  }

  public ngAfterViewInit(): void {
    this._initDropbox();
  }

  private _initDropbox(): void {
    const dropbox: HTMLElement = this.dropboxRef.nativeElement as HTMLElement;
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.addSubscription(
        fromEvent(dropbox, eventName).subscribe((event) => {
          event.preventDefault();
          event.stopPropagation();
        })
      );
    });
    ['dragenter', 'dragover'].forEach(eventName => {
      this.addSubscription(
        fromEvent(dropbox, eventName).subscribe(() => {
          this.highlighted = true;
        })
      );
    });
    ['dragleave', 'drop'].forEach(eventName => {
      this.addSubscription(
        fromEvent(dropbox, eventName).subscribe((event) => {
          if (eventName === 'drop') {
            const data: DataTransfer | null = (event as DragEvent).dataTransfer;
            const files: FileList | undefined = data?.files;
            this._handleFiles(files);
            this.highlighted = false;
          }
        })
      );
    });
  }

  public onFileChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target?.files?.length) {
      const files: FileList = target.files;
      this._handleFiles(files);
    }
    this.fileInput = '';
    this.highlighted = false;
  }

  private _handleFiles(files: FileList | undefined): void {
    if (files && files[0]) {
      const reader = new FileReader();
      const file = files[0];

      reader.onload = (src) => {
        const source = `${src.target?.result}`;
        if (source.startsWith('data:image')) {
          const image = new Image();
          image.onload = () => {
            this._createAvatar(image);
          };
          image.src = source;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  private _createAvatar(img: HTMLImageElement): void {
    const size = 96;
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      if (img.height > img.width) {
        ctx.drawImage(img, 0, (img.height - img.width) / 2, img.width, img.width, 0, 0, size, size);
      } else {
        ctx.drawImage(img, (img.width - img.height) / 2, 0, img.height, img.height, 0, 0, size, size);
      }
    }
    const image = canvas.toDataURL('image/jpeg', 0.75);
    this.changed.emit(image);
    this.image = `url(${image})`;
  }

}
