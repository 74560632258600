import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LocalizationService } from '@tallence/localization';
import { StateModel } from './models/store.model';
import { AuthService } from './services/auth.service';
import { IconService } from './services/icon.service';
import { GarbageCollectorComponent } from './utilities/garbage-collector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends GarbageCollectorComponent {

  public ready = false;
  public auth = false;

  constructor(
    private _iconService: IconService,
    private _localizationService: LocalizationService,
    private _router: Router,
    private _store: Store,
    private _authService: AuthService,
  ) {
    super();
    this._iconService.init();
    this._initLocalization();
    this._initSecurity();
  }


  private _initLocalization(): void {
    const path = '../assets/translations/';
    const modules: string[] = [
      'general',
      'forms',
      'views'
    ];
    const languages: string[] = ['de'];
    const language = 'de';

    this.addSubscription(
      this._localizationService.init(path, modules, languages, language).subscribe((loaded: boolean) => {
        if (loaded) {
          this.ready = true;
        } else {
          this._router.navigate(['error', '404']);
        }
      })
    );
  }

  private _initSecurity(): void {
    this.addSubscription(
      this._store.select((state: StateModel) => state.security.auth).subscribe((auth: boolean) => {
        this.auth = auth;
      })
    );
    this._authService.restoreAuth();
  }


}
