import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Handout } from 'src/app/models/administration.model';

@Component({
  selector: 'app-handout-view',
  templateUrl: './handout-view.component.html',
  styleUrls: ['./handout-view.component.scss']
})
export class HandoutViewComponent implements OnInit {

  public handout: Handout = new Handout();
  public qrCode = '';
  public licenseToken = '';
  public preview = '';
  public url = '';

  constructor(
    private _router: Router
  ) { }

  public ngOnInit(): void {
    const local = localStorage.getItem('handout');
    if (local) {
      sessionStorage.setItem('handout', local);
      localStorage.removeItem('handout');
    }
    const session = sessionStorage.getItem('handout');
    if (session) {
      try {
        this.handout = JSON.parse(session);
        this.qrCode = this.getQrCode();
        this.preview = this.handout.preview;
        this.url = this.handout.url + '?imageType=register';
      } catch (error) {
        this._router.navigate(['error', '404']);
      }
    } else {
      this._router.navigate(['error', '404']);
    }
  }
  getQrCode(): string {
    if (this.handout) {
      const qr = {
        'url': this.handout.url,
        'private-key': this.handout.secretKey,
        'biometric-passed': Object.values(this.handout.validationResult).every((v) => v !== false),
        'date': this.handout.date,
        'expiry date': this.handout.expiryDate,
        'additional info': ''
      };
      return JSON.stringify(qr);
    }
    return '';
  }
}
