import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PhotoUpload, PhotoState } from 'src/app/models/file-upload.model';
import { PhotosService } from 'src/app/services/photos.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-photos-overview',
  templateUrl: './photos-overview.component.html',
  styleUrls: ['./photos-overview.component.scss']
})
export class PhotosOverviewComponent extends GarbageCollectorComponent implements OnInit {

  @Input()
  public states: PhotoState[] = [];

  public photos: PhotoUpload[] = [];

  constructor(
    private _photosService: PhotosService
  ) {
    super();
  }

  public ngOnInit(): void {

    const collection: Observable<PhotoUpload[]> = this.states.includes(PhotoState.ARCHIVED) ?
      this._photosService.getArchive() : this._photosService.getPhotos();

    this.addSubscription(
      collection.subscribe((photos: PhotoUpload[]) => {
        this.photos = photos.filter((photo: PhotoUpload) => {
          return this.states.includes(photo.state);
        });
      })
    );
  }

}
