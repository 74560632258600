import { AccountModel } from 'src/app/models/store.model';

const name = 'Account';
const update = `${name} Update`;

export class ResetAccount {
  public static readonly type: string = `${name} Reset`;
  constructor() { }
}

export class UpdateAccount {
  public static readonly type: string = `${update}`;
  constructor(public account: AccountModel) { }
}

export class UpdateAccountProperty {
  public static readonly type: string = `${update} Property`;
  constructor(public property: string, public value: string) { }
}

