<mat-form-field class="input" appearance="standard" *ngIf="config.type !== 'none'">
  <mat-label>{{ config.label | translate }}</mat-label>
  <textarea class="dialog__public-key-textarea"
            (focus)="focusState = true"
            (focusout)="focusState = false"
            matInput cdkTextareaAutosize
            [formControl]="config.control">
  </textarea>

  <div matSuffix class="dialog__public-key-icon">
    <label class="dialog__public-key-label" for="public-key-input">
      <mat-icon>note_add</mat-icon>
    </label>
    <input
      type="file"
      id="public-key-input"
      accept=".pem"
      [autocomplete]="autocomplete"
      [readonly]="readonly !== undefined"
      [formControl]="config.control"
      (change)="onPublicKeyChange($event)"
    >
  </div>
  <mat-error *ngIf="config.control.errors">
    {{ getErrorMessage() }}
  </mat-error>
  <mat-hint *ngIf="focusState">
    {{ getHintMessage() }}
  </mat-hint>

</mat-form-field>
