import { FormControl } from '@angular/forms';

export class SelectOption {
  public name: string;
  public value: string;
  public disabled: boolean;

  constructor(name: string, value: any, disabled: boolean = false) {
    this.name = name;
    this.value = `${value}`;
    this.disabled = disabled;
  }
}

export class SelectConfig {
  public control: FormControl;
  public label: string;
  public hint: string;
  public options: SelectOption[];
  public multiple: boolean;

  constructor(label: string = '', hint: string = '') {
    this.control = new FormControl();
    this.label = label;
    this.hint = hint;
    this.options = [];
    this.multiple = false;
  }
}
