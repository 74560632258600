import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Application } from 'src/app/models/administration.model';
import { Role } from 'src/app/models/security.model';
import { StateModel } from 'src/app/models/store.model';
import { UserService } from 'src/app/services/register/user.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-overview-view',
  templateUrl: './overview-view.component.html',
  styleUrls: ['./overview-view.component.scss']
})
export class OverviewViewComponent extends GarbageCollectorComponent implements OnInit {

  public cards: any[] = [];
  private _cards: any[] = [
    {
      label: 'profile',
      icon: 'account-edit',
      route: 'account',
      roles: [Role.ADMIN, Role.UIG, Role.HARDWARE_PROVIDER, Role.PHOTOGRAPHER, Role.EMPLOYEE]
    },
    {
      label: 'employees',
      icon: 'group',
      route: 'employees',
      roles: [Role.PHOTOGRAPHER]
    },
    {
      label: 'cameras',
      icon: 'photo_camera',
      route: 'cameras',
      roles: [Role.HARDWARE_PROVIDER]
    },
    {
      label: 'users',
      icon: 'groups',
      route: 'users',
      roles: [Role.ADMIN, Role.UIG]
    },
    {
      label: 'applications',
      icon: 'text-box-check-outline',
      route: 'applications',
      roles: [Role.ADMIN, Role.UIG]
    },
    {
      label: 'uig-employees',
      icon: 'card-account-details',
      route: 'uig-employees',
      roles: [Role.ADMIN]
    },
    {
      label: 'hardware-providers',
      icon: 'camera-account',
      route: 'hardware-providers',
      roles: [Role.ADMIN]
    },
    {
      label: 'photos',
      icon: 'add_photo_alternate',
      route: 'editor',
      roles: [Role.PHOTOGRAPHER, Role.EMPLOYEE]
    },
    {
      label: 'archive',
      icon: 'photo_album',
      route: 'archive',
      roles: [Role.PHOTOGRAPHER, Role.EMPLOYEE]
    },
    {
      label: 'companies',
      icon: 'home_work',
      route: 'companies',
      roles: [Role.ADMIN, Role.UIG, Role.HARDWARE_PROVIDER]
    },
    {
      label: 'studios',
      icon: 'holiday_village',
      route: 'company',
      roles: [Role.PHOTOGRAPHER]
    },
    {
      label: 'analytics',
      icon: 'analytics',
      route: 'analytics',
      roles: [Role.ADMIN, Role.UIG]
    }
  ];
  public applicationCount = 0;

  constructor(
    private _router: Router,
    private _store: Store,
    private _userService: UserService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this._store.select((state: StateModel) => state.security.role).subscribe((role: Role) => {
        this.cards = this._cards.filter(card => {
          return card.roles.includes(role);
        });
        if (role === Role.ADMIN || role === Role.UIG) {
          this._userService.getApplications();
        }
      })
    );
    this.addSubscription(
      this._store.select((state: StateModel) => state.administration.applications).subscribe((applications: Application[]) => {
        this.applicationCount = applications.length;
      })
    );
  }

  public goTo(route: string): void {
    this._router.navigate([route]);
  }

}
