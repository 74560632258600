import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss']
})
export class CodeInputComponent extends GarbageCollectorComponent implements OnInit {

  @Input()
  public size = 6;

  @Output()
  public enter: EventEmitter<string> = new EventEmitter();

  public inputForm: FormGroup = this._fb.group({});
  public inputNames: string[] = [];

  constructor(
    private _fb: FormBuilder,
    private _elRef: ElementRef
  ) {
    super();
  }

  public ngOnInit(): void {
    const el: HTMLElement = this._elRef.nativeElement as HTMLElement;
    for (let size = 0; size < this.size; size++) {
      const name = `input${size}`;
      const control = new FormControl('', Validators.required);
      this.inputForm.addControl(name, control);
      this.inputNames.push(name);
      this.addSubscription(
        control.valueChanges.subscribe((value: string) => {
          if (value) {
            if (value.length > 1) {
              control.setValue(value.charAt(0));
            } else if (value.match(/\D/)) {
              control.setValue(value.replace(/\D/g, ''));
            } else {
              const index = this.inputNames.indexOf(name);
              if ((index + 1) < this.inputNames.length) {
                el.querySelectorAll('input')[index + 1].focus();
              } else {
                el.querySelectorAll('input')[index].blur();
              }
            }
          }
        })
      );
    }
    this.addSubscription(
      this.inputForm.valueChanges.subscribe((value: string) => {
        if (this.inputForm.valid) {
          const code = Object.values(this.inputForm.value).join('');
          this.enter.emit(code);
        }
      })
    );
  }

  public getControl(name: string): FormControl {
    return this.inputForm.get(name) as FormControl || new FormControl('');
  }

  public clearInput(name: string): void {
    const index = this.inputNames.indexOf(name);
    this.inputForm.controls[name].setValue('');
  }

  public previousInput(name: string): void {
    const el: HTMLElement = this._elRef.nativeElement as HTMLElement;
    const index = this.inputNames.indexOf(name);
    el.querySelectorAll('input')[Math.max(index - 1, 0)].focus();
  }

  public pasteCode(event: ClipboardEvent): void {
    const code = event.clipboardData?.getData('text') || '';
    if (code.length >= this.size) {
      this.inputNames.forEach((name, index) => {
        this.inputForm.controls[name].setValue(code[index]);
      });
    }
    event.preventDefault();
  }

}

