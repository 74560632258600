<div class="data-row">
  <div class="data-row__label">
    <ng-container *ngFor="let config of configs; last as isLast">
      <span>{{ config.label | translate }}</span>
      <span class="data-row__spacer" *ngIf="!isLast">/</span>
    </ng-container>
    <span *ngIf="studio">{{ 'register.studio.label' | translate:[studio[1]] }}</span>
    <span *ngIf="getFileNames().length">{{ 'fileUpload.label' | translate }}</span>
  </div>
  <div class="data-row__value">
    <ng-container *ngFor="let config of configs; last as isLast">
      <span>{{ config.control.value || '-' }}</span>
      <span class="data-row__spacer" *ngIf="!isLast">/</span>
    </ng-container>
    <span *ngIf="studio">{{ getStudioValues() }}</span>
    <ng-container *ngFor="let name of getFileNames()">
      <div>{{ name }}</div>
    </ng-container>
  </div>
</div>
