import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  template: ''
})
export abstract class GarbageCollectorComponent implements OnDestroy {
  private _gc$: Subscription = new Subscription();

  constructor() {}

  public addSubscription(sub: Subscription): void {
    this._gc$.add(sub);
  }

  public ngOnDestroy(): void {
    this._gc$.unsubscribe();
  }
}
