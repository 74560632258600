import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalizationService } from '@tallence/localization';
import * as moment from 'moment';
import { Camera, HardwareProvider, ResponseStatus, SearchQuery, Studio, TableActionType, TableConfig, UigEmployee } from 'src/app/models/administration.model';
import { AutocompleteConfig } from 'src/app/models/autocomplete.model';
import { CheckboxConfig } from 'src/app/models/checkbox.model';
import { DatePickerConfig } from 'src/app/models/date-picker.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { InputConfig, InputType } from 'src/app/models/input.model';
import { Role } from 'src/app/models/security.model';
import { HardwareService } from 'src/app/services/register/hardware.service';
import { PhotostudioService } from 'src/app/services/register/photostudio.service';
import { UserService } from 'src/app/services/register/user.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';
import { Helper } from 'src/app/utilities/helper';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent extends GarbageCollectorComponent implements OnInit{

  public description?: string;
  public error?: string;

  public salutationConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.salutation.label');
  public firstNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.firstName.label');
  public lastNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.lastName.label');
  public birthDateConfig: InputConfig = new InputConfig(InputType.DATE, 'input.birthDate.label');
  public emailConfig: InputConfig = new InputConfig(InputType.EMAIL, 'input.email.label');
  public passwordConfig: InputConfig = new InputConfig(InputType.PASSWORD, 'input.password.label');
  public phone1Config: InputConfig = new InputConfig(InputType.PHONE, 'input.phone1.label');
  public companyConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.company.label');
  public companyLegalFormConfig: AutocompleteConfig = new AutocompleteConfig('autocomplete.companyLegalForm.label');
  // public licenseNumberConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.licenseNumber.label');
  public addressConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.address.label');
  public zipConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.zip.label');
  public locationConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.location.label');

  public cameraManufacturerConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.cameraManufacturer.label');
  public cameraModelConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.cameraModel.label');
  public cameraIdConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.cameraId.label');
  public publicKeyConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.publicKey.label');
  public licenseBeginDateConfig: DatePickerConfig = new DatePickerConfig('input.licenseBeginDate.label');
  public licenseEndDateConfig: DatePickerConfig = new DatePickerConfig('input.licenseEndDate.label');

  public studioNameConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.studioName.label');
  public forceSaveConfig: CheckboxConfig = new CheckboxConfig('checkbox.forceSave.label');

  public reasonControl: FormControl = new FormControl('');

  public searchConfig: InputConfig = new InputConfig(InputType.TEXT, 'input.searchCompany.label');
  public tableConfig: TableConfig = new TableConfig();
  public maxDate?: string;
  public searched = false;

  private _dialogForm: FormGroup = this._fb.group({});

  public minDate?: string;
  public confirmLocked = true;
  public loading = false;
  private _originData: DialogConfig;

  public authentificator = false;
  public clickDelay = '';

  public validationErrors: string[] = [];

  public publicKeyInput = '';

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfig,
    private _localizationService: LocalizationService,
    private _fb: FormBuilder,
    private _photostudioService: PhotostudioService,
    private _userService: UserService,
    private _hardwareService: HardwareService,
    private _router: Router
  ) {
    super();
    this._originData = this.data.clone();
  }

  public ngOnInit(): void {
    this.description = this._localizationService.getTranslation(this.data.description[0], this.data.description.slice(1));
    switch (this.data.type) {
      case DialogType.ADD_EMPLOYEE:
        this._dialogForm.addControl('firstName', this.firstNameConfig.control);
        this._dialogForm.addControl('lastName', this.lastNameConfig.control);
        this._dialogForm.addControl('email', this.emailConfig.control);
        break;
      case DialogType.DELETE_ACCOUNT:
        this._dialogForm.addControl('passsword', this.passwordConfig.control);
        break;
      case DialogType.ADD_UIG_EMPLOYEE:
        this._dialogForm.addControl('salutation', this.salutationConfig.control);
        this._dialogForm.addControl('firstName', this.firstNameConfig.control);
        this._dialogForm.addControl('lastName', this.lastNameConfig.control);
        this._dialogForm.addControl('birthDate', this.birthDateConfig.control);
        this._dialogForm.addControl('email', this.emailConfig.control);
        this._dialogForm.addControl('phone1', this.phone1Config.control);
        this.maxDate = moment().subtract(15, 'years').format('YYYY-MM-DD');
        break;
      case DialogType.ADD_HARDWARE_PROVIDER:
        this._dialogForm.addControl('salutation', this.salutationConfig.control);
        this._dialogForm.addControl('firstName', this.firstNameConfig.control);
        this._dialogForm.addControl('lastName', this.lastNameConfig.control);
        this._dialogForm.addControl('email', this.emailConfig.control);
        this._dialogForm.addControl('phone1', this.phone1Config.control);
        this._dialogForm.addControl('company', this.companyConfig.control);
        this._dialogForm.addControl('companyLegalForm', this.companyLegalFormConfig.control);
        this._dialogForm.addControl('birthDate', this.birthDateConfig.control);
        this._dialogForm.addControl('address', this.addressConfig.control);
        this._dialogForm.addControl('zip', this.zipConfig.control);
        this._dialogForm.addControl('location', this.locationConfig.control);
        this.maxDate = moment().subtract(15, 'years').format('YYYY-MM-DD');
        break;
      case DialogType.ADD_CAMERA:
        this._dialogForm.addControl('cameraManufacturer', this.cameraManufacturerConfig.control);
        this._dialogForm.addControl('cameraModel', this.cameraModelConfig.control);
        this._dialogForm.addControl('cameraId', this.cameraIdConfig.control);
        this._dialogForm.addControl('publicKey', this.publicKeyConfig.control);
        this._dialogForm.addControl('licenseBeginDate', this.licenseBeginDateConfig.control);
        this._dialogForm.addControl('licenseEndDate', this.licenseEndDateConfig.control);
        this.addSubscription(
          this.licenseBeginDateConfig.control.valueChanges.subscribe((value: any) => {
            this.minDate = moment(value).add(1, 'days').format('YYYY-MM-DD');
          })
        );
        this.licenseBeginDateConfig.control.setValue(new Date());
        break;
      case DialogType.UPDATE_CAMERA:
        this._dialogForm.addControl('licenseBeginDate', this.licenseBeginDateConfig.control);
        this._dialogForm.addControl('licenseEndDate', this.licenseEndDateConfig.control);
        this.addSubscription(
          this.licenseBeginDateConfig.control.valueChanges.subscribe((value: any) => {
            this.minDate = moment(value).add(2, 'days').format('YYYY-MM-DD');
          })
        );
        const camera: Camera = {
          ...new Camera(),
          ...this.data.values
        };
        this.licenseBeginDateConfig.control.setValue(moment(camera.licenseBeginDate, 'DD.MM.YYYY').toDate());
        this.licenseEndDateConfig.control.setValue(moment(camera.licenseEndDate, 'DD.MM.YYYY').toDate());
        break;
      case DialogType.UPDATE_STUDIO:
        this._dialogForm.addControl('studioName', this.studioNameConfig.control);
        // this._dialogForm.addControl('licenseNumber', this.licenseNumberConfig.control);
        this._dialogForm.addControl('address', this.addressConfig.control);
        this._dialogForm.addControl('zip', this.zipConfig.control);
        this._dialogForm.addControl('location', this.locationConfig.control);
        Helper.mergeForm(this._dialogForm, this.data.values);
        break;
      case DialogType.ADD_STUDIO:
        this._dialogForm.addControl('studioName', this.studioNameConfig.control);
        // this._dialogForm.addControl('licenseNumber', this.licenseNumberConfig.control);
        this._dialogForm.addControl('address', this.addressConfig.control);
        this._dialogForm.addControl('zip', this.zipConfig.control);
        this._dialogForm.addControl('location', this.locationConfig.control);
        break;
      case DialogType.REJECT_APPLICATION:
        this.reasonControl.setValidators(Validators.required);
        this._dialogForm.addControl('reason', this.reasonControl);
        break;
      case DialogType.SEARCH_COMPANY:
        this._dialogForm.addControl('search', this.searchConfig.control);
        this.tableConfig.header = ['company', 'companyLegalForm'];
        this.tableConfig.actionId = 'email';
        break;
      case DialogType.CODE:
        this.authentificator = this.data.values.secret !== undefined;
        this.error = this.data.values.error;
        let delay = 15;
        this.clickDelay = ` (${delay})`;
        const timer = setInterval(() => {
          if (delay > 0) {
            delay--;
            this.clickDelay = ` (${delay})`;
          } else {
            this.clickDelay = '';
            clearInterval(timer);
          }
        }, 1000);
        break;
      case DialogType.FORCE_SAVE:
        this._dialogForm.addControl('forceSave', this.forceSaveConfig.control);
        break;
      case DialogType.VALIDATION_ERRORS:
        this.validationErrors = (this.data.values || []).map((error: string) => `editor.validationErrors.${error}`);;
        break;
      case DialogType.PASSWORD:
        this._dialogForm.addControl('password', this.passwordConfig.control);
        break;
      default:
        break;
    }
    this.addSubscription(
      this._dialogForm.statusChanges.subscribe(() => {
        this.confirmLocked = this._dialogForm.invalid;
      })
    );
  }

  public confirm(): void {
    this.dialogRef.close(true);
  }

  public inviteEmployee(): void {
    this.addSubscription(
      this._photostudioService.inviteEmployee(this._dialogForm.value).subscribe((response: ResponseStatus) => {
        this.confirmLocked = false;
        const email = `${this._dialogForm.value.email}`;
        if (response === ResponseStatus.SUCCESS) {
          this._showSuccess(['dialog.inviteEmployee.success', email]);
        }
        if (response === ResponseStatus.CONFLICT) {
          this._showConflict(['dialog.inviteEmployee.conflict', email]);
        }
        if (response === ResponseStatus.ERROR) {
          this._showError(['dialog.inviteEmployee.error']);
        }
      })
    );
  }

  public deleteAccount(): void {
    this.loading = true;
    this.dialogRef.disableClose = true;
    this.addSubscription(
      this._userService.deleteAccount(this.passwordConfig.control.value).subscribe((response: ResponseStatus) => {
        this.confirmLocked = false;
        this.loading = false;
        if (response === ResponseStatus.SUCCESS) {
          this.data.type = DialogType.ACCOUNT_DELETED;
          this.data.okButton = 'button.ok';
          this.description = this._localizationService.getTranslation('dialog.deleteAccount.success');
        } else {
          this.passwordConfig.control.setErrors({incorrectPassword: true});
          this.dialogRef.disableClose = false;
        }
      })
    );
  }

  public addUigEmployee(): void {
    this.dialogRef.disableClose = true;
    this.addSubscription(
      this._userService.addUigEmployee({
        ...new UigEmployee(),
        role: Role.UIG,
        ...this._dialogForm.value
      }).subscribe((response: ResponseStatus) => {
        this.confirmLocked = false;
        const name = `${this._dialogForm.value.firstName} ${this._dialogForm.value.lastName}`;
        const email = `${this._dialogForm.value.email}`;
        if (response === ResponseStatus.SUCCESS) {
          this._showSuccess(['dialog.addUigEmployee.success', name]);
        }
        if (response === ResponseStatus.CONFLICT) {
          this._showConflict(['dialog.addUigEmployee.conflict', email]);
        }
        if (response === ResponseStatus.ERROR) {
          this._showError(['dialog.addUigEmployee.error', name]);
        }
      })
    );
  }

  public addHardwareProvider(): void {
    this.dialogRef.disableClose = true;
    this.addSubscription(
      this._userService.addHardwareProvider({
        ...new HardwareProvider(),
        ...this._dialogForm.value
      }).subscribe((response: ResponseStatus) => {
        this.confirmLocked = false;
        const name = `${this._dialogForm.value.firstName} ${this._dialogForm.value.lastName}`;
        const email = `${this._dialogForm.value.email}`;
        if (response === ResponseStatus.SUCCESS) {
          this._showSuccess(['dialog.addHardwareProvider.success', name]);
        }
        if (response === ResponseStatus.CONFLICT) {
          this._showConflict(['dialog.addHardwareProvider.conflict', email]);
        }
        if (response === ResponseStatus.ERROR) {
          this._showError(['dialog.addUigEmployee.error']);
        }
      })
    );
  }

  public addCamera(): void {
    this.dialogRef.disableClose = true;
    const camera: Camera = {
      ...new Camera(),
      ...this._dialogForm.value,
      publicKey: btoa(this._dialogForm.value.publicKey),
    };
    this.addSubscription(
      this._hardwareService.addCamera(camera).subscribe((response: ResponseStatus) => {
        this.confirmLocked = false;
        const name = `${this._dialogForm.value.cameraManufacturer}: ${this._dialogForm.value.cameraModel}`;
        if (response === ResponseStatus.SUCCESS) {
          this._showSuccess(['dialog.addCamera.success', name]);
        }
        if (response === ResponseStatus.CONFLICT) {
          this._showConflict(['dialog.addCamera.conflict', this._dialogForm.value.cameraId]);
        }
        if (response === ResponseStatus.ERROR) {
          this._showError(['dialog.addCamera.error']);
        }
      })
    );
  }

  public updateCamera(): void {
    this.dialogRef.disableClose = true;
    const update: any = {
      cameraId: this.data.values.cameraId,
      cameraManufacturer: this.data.values.cameraManufacturer,
      ...this._dialogForm.value
    };
    this.addSubscription(
      this._hardwareService.updateCamera(update).subscribe((response: ResponseStatus) => {
        this.confirmLocked = false;
        if (response === ResponseStatus.SUCCESS) {
          this._showSuccess(['dialog.updateCamera.success']);
        }
        if (response === ResponseStatus.ERROR) {
          this._showError(['dialog.updateCamera.error']);
        }
      })
    );
  }

  public addStudio(): void {
    this.dialogRef.close({
      ...new Studio(),
      ...this._dialogForm.value
    });
  }

  public updateStudio(): void {
    this.dialogRef.close({
      ...new Studio(),
      ...this._dialogForm.value
    });
  }

  public rejectApplication(): void {
    this.dialogRef.close(this.reasonControl.value);
  }

  public searchCompany(): void {
    const search: SearchQuery = new SearchQuery();
    search.text = this._dialogForm.value.search;
    this.addSubscription(
      this._photostudioService.searchCompany(search).subscribe(
        (companies: any[]) =>
      {
        this.searched = true;
        this.tableConfig.items = companies;
        this.tableConfig.actions = [TableActionType.ADD_COMPANY];
        this.tableConfig.click = TableActionType.ADD_COMPANY;
        this.tableConfig.paginator.size = 5;
        this.tableConfig.paginator.total = companies.length;
        this.tableConfig = {...this.tableConfig};
      })
    );
  }

  public addCompany(company: any): void {
    this._photostudioService.addCompany(company.id).subscribe((response: ResponseStatus) => {
      this.dialogRef.close(response === ResponseStatus.SUCCESS);
    });
  }

  private _showSuccess(description: string[]): void {
    this.dialogRef.updateSize(DialogConfig.smallSize());
    this.dialogRef.disableClose = false;
    this.data.type = DialogType.SUCCESS;
    this.data.okButton = 'button.ok';
    this.description = this._localizationService.getTranslation(description[0], description.slice(1));
  }

  private _showConflict(description: string[]): void {
    this.dialogRef.updateSize(DialogConfig.smallSize());
    this.data.type = DialogType.CONFLICT;
    this.data.okButton = 'button.ok';
    this.description = this._localizationService.getTranslation(description[0], description.slice(1));
  }

  private _showError(description: string[]): void {
    this.dialogRef.updateSize(DialogConfig.smallSize());
    this.data.type = DialogType.FAIL;
    this.data.okButton = 'button.ok';
    this.description = this._localizationService.getTranslation(description[0], description.slice(1));
  }

  public backToForm(): void {
    this.dialogRef.updateSize(DialogConfig.largeSize());
    this.data = this._originData.clone();
    this.description = this._localizationService.getTranslation(this.data.description[0], this.data.description.slice(1));
    this.dialogRef.disableClose = false;
  }

  public codeEntered(code: string): void {
    this.dialogRef.close(code);
  }

  public submitPassword(): void {
    if (this.passwordConfig.control.valid) {
      this.dialogRef.close(this.passwordConfig.control.value);
    }
  }

  public showRequiredHint(): boolean {
    return [
      DialogType.ADD_CAMERA,
      DialogType.ADD_EMPLOYEE,
      DialogType.ADD_HARDWARE_PROVIDER,
      DialogType.ADD_STUDIO,
      DialogType.ADD_UIG_EMPLOYEE,
      DialogType.UPDATE_CAMERA,
      DialogType.UPDATE_STUDIO
    ].includes(this.data.type);
  }

}
