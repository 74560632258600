<header class="header">
  <div class="row" *ngIf="auth">
    <div class="flex"></div>
    <div *ngIf="timeout > 0 && timeout < warningTime" class="header__timeout" (click)="extendTime()">
      <mat-icon>running_with_errors</mat-icon>
      <div class="header__name">{{ formattedTime }}</div>
    </div>
    <div class="header__account" (click)="goTo('account')">
      <button class="header__button" mat-icon-button [ngStyle]="{'background-image': image}">
        <mat-icon *ngIf="!image" svgIcon="account-edit"></mat-icon>
      </button>
      <div class="header__name">{{name}}</div>
    </div>
    <button class="header__button header__lock" mat-icon-button (click)="lock()">
      <mat-icon>lock</mat-icon>
    </button>
    <button class="header__button" mat-icon-button (click)="logout()">
      <mat-icon>logout</mat-icon>
    </button>
  </div>
  <app-button *ngIf="showPrint" primary icon="print" (action)="print()">{{ 'button.print' | translate }}</app-button>
</header>
