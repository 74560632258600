import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigService } from 'src/app/services/config.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-analytics-view',
  templateUrl: './analytics-view.component.html',
  styleUrls: ['./analytics-view.component.scss']
})
export class AnalyticsViewComponent extends GarbageCollectorComponent implements OnInit {

  public url?: SafeResourceUrl;

  constructor(
    private _configService: ConfigService,
    private _domSanitizer: DomSanitizer
  ) {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this._configService.getApi().subscribe((data: any) => {
        this.url = this._domSanitizer.bypassSecurityTrustResourceUrl(data.kibana.origin + data.kibana.port + data.kibana.path);
      })
    );
  }

}
