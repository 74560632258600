import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-error-view',
  templateUrl: './error-view.component.html',
  styleUrls: ['./error-view.component.scss']
})
export class ErrorViewComponent implements OnInit {

  public errorText = '';

  constructor(
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  public ngOnInit(): void {
    const code: string = this._route.snapshot.params.code;
    this.errorText = `error.${code}`;
  }

  public goBack(): void {
    this._router.navigate(['overview']);
  }

}
