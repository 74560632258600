<app-content>
  <div *ngIf="application" class="application-view">
    <app-headline icon="text-box-check-outline">{{ 'application.headline' | translate: [name] }}</app-headline>
    <app-card>
      <div class="application-view__section">
        <div class="row">
          <app-input class="flex" readonly [config]="salutationConfig"></app-input>
          <app-input class="flex" readonly [config]="roleConfig"></app-input>
        </div>
        <div class="row">
          <app-input class="flex" readonly [config]="firstNameConfig"></app-input>
          <app-input class="flex" readonly [config]="lastNameConfig"></app-input>
        </div>
        <div *ngIf="application.role === 'PHOTOGRAPHER'" class="row">
          <app-input class="flex" readonly [config]="companyConfig"></app-input>
          <app-input class="flex" readonly [config]="companyLegalFormConfig"></app-input>
        </div>
        <div class="row">
          <app-input class="flex" readonly [config]="emailConfig"></app-input>
          <app-input class="flex" *ngIf="application.role === 'PHOTOGRAPHER'" readonly [config]="websiteConfig"></app-input>
          <div class="flex" *ngIf="application.role !== 'PHOTOGRAPHER'"></div>
        </div>
        <div class="row">
          <app-input class="flex" readonly [config]="phone1Config"></app-input>
          <app-input class="flex" readonly [config]="phone2Config"></app-input>
        </div>
        <div *ngIf="application.role === 'PHOTOGRAPHER'" class="row">
          <app-input class="flex" readonly [config]="memberConfig"></app-input>
          <div class="flex"></div>
        </div>
      </div>
      <div *ngIf="application.documents.length" class="application-view__section">
        <app-table sortable [config]="attachmentsConfig" (action)="onAction($event)"></app-table>
      </div>
      <div *ngIf="application.studios.length" class="application-view__section">
        <app-table sortable [config]="studiosConfig" (action)="onAction($event)"></app-table>
      </div>
      <div class="actions">
        <app-button secondary (action)="back()">{{ 'button.back' | translate }}</app-button>
        <div class="flex"></div>
        <app-button primary="error" (action)="reject()">{{ 'button.reject' | translate }}</app-button>
        <app-button primary="success" (action)="accept()">{{ 'button.accept' | translate }}</app-button>
      </div>
    </app-card>
  </div>
</app-content>
