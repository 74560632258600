import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, takeWhile } from 'rxjs/operators';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { ResponseStatus } from 'src/app/models/administration.model';
import { DialogConfig, DialogType } from 'src/app/models/dialog.model';
import { PhotoState, PhotoUpload } from 'src/app/models/file-upload.model';
import { EditorService } from 'src/app/services/editor/editor.service';
import { PhotosService } from 'src/app/services/photos.service';
import { GarbageCollectorComponent } from 'src/app/utilities/garbage-collector';

@Component({
  selector: 'app-archive-view',
  templateUrl: './archive-view.component.html',
  styleUrls: ['./archive-view.component.scss']
})
export class ArchiveViewComponent extends GarbageCollectorComponent implements OnInit {

  public locked = true;
  public archiveStates: PhotoState[] = [PhotoState.ARCHIVED];
  public available = false;

  constructor(
    private _photosService: PhotosService,
    private _editorService: EditorService,
    private _dialog: MatDialog,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.addSubscription(
      this._photosService.getArchive().subscribe((photos: PhotoUpload[]) => {
        this.available = photos.length > 0;
      })
    );
  }

  public unlock(): void {
    const dialogData: DialogConfig = new DialogConfig(
      DialogType.PASSWORD,
      'dialog.password.headline',
      ['dialog.password.description'],
    );
    const dialogRef = this._dialog.open(DialogComponent, {
      ...dialogData.layout(),
      data: dialogData
    });

    this.addSubscription(
      dialogRef.afterClosed().pipe(
        takeWhile(password => password),
        switchMap((password: string) => {
          return this._editorService.loadArchive(password);
        })
      ).subscribe((response: ResponseStatus) => {
        if (response === ResponseStatus.SUCCESS) {
          this.locked = false;
        }
      })
    );
  }

}
