import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss']
})
export class HeadlineComponent {

  @Input()
  public headline?: string;
  @Input()
  public icon?: string;

  constructor() { }

}
