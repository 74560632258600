import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AuthService } from '../services/auth.service';
import { StateModel } from '../models/store.model';
import { ConsoleService } from '../services/console.service';
import { LocalizationService } from '@tallence/localization';
import { ConfigService } from '../services/config.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private _api: any = { register: {}, editor: {} };
  private _auth = false;
  private _wildcards: string[] = [
    'token',
    '.json'
  ];

  constructor(
    private _store: Store,
    private _authService: AuthService,
    private _consoleService: ConsoleService,
    private _localizationService: LocalizationService,
    private _configService: ConfigService
  ) {
    this._configService.getApi().subscribe((data: any) => {
      this._api = data;
    });
    this._store.select((state: StateModel) => state.security.auth).subscribe((auth: boolean) => {
      this._auth = auth;
    });
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const registerRegex = new RegExp(`^.+\\${this._api.register.version}/`);
    const editorRegex = new RegExp(`^.+\\${this._api.editor.version}/`);
    const passthrough: boolean = this._wildcards.filter(wildcard => {
      return req.url.includes(wildcard);
    }).length !== 0;

    if (
      this._auth &&
      registerRegex.test(req.url) &&
      editorRegex.test(req.url) &&
      !passthrough &&
      !this._isBasic(req)
    ) {
      return this._handleRequest(req, next);
    }

    return next.handle(req);
  }

  private _handleRequest(req: HttpRequest<any>, next: HttpHandler, retry: boolean = false): Observable<HttpEvent<any>> {

    const token: string | null = this._authService.getAccessToken();
    const request: HttpRequest<any> = req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });

    return next.handle(request).pipe(
      map((response: HttpEvent<any>) => {
        this._authService.resetAutoLogout();
        return response;
      }),
      catchError(error => {
        return this._handleError(req, next, error, retry);
      })
    );
  }

  private _refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._authService.refreshToken().pipe(
      switchMap(success => {
        if (success) {
          return this._handleRequest(req, next, true);
        }
        return throwError('');
      })
    );
  }

  private _handleError(req: HttpRequest<any>, next: HttpHandler, error: any, retry: boolean = false): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 401:
          if (this._auth && !this._isBasic(req) && !retry) {
            return this._refreshToken(req, next);
          }
          break;
        case 404:
          break;
        case 500:
          this._consoleService.add(
            `*${req.method}* ${req.url}`,
            this._localizationService.getTranslation('console.500'),
            0
          );
          break;
        default:
          break;
      }
    }
    return throwError(error);
  }

  private _isBasic(req: HttpRequest<any>): boolean {
    return `${req.headers.get('Authorization')}`.includes('Basic');
  }

}
