import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig } from 'src/app/models/administration.model';
import {SelectConfig, SelectOption} from '../../models/select.model';
import {LocalizationService} from '@tallence/localization';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {

  public paginator!: PaginatorConfig;
  public templateConfig: SelectConfig = new SelectConfig('pageRows.template.label');

  @Input()
  public set config(cfg: PaginatorConfig) {
    this.paginator = cfg;
    this.paginator.last = Math.max(Math.ceil(cfg.total / cfg.size), 1);
    if (cfg.current > cfg.last) {
      this.paginator.current = cfg.last;
    }
    if (cfg.current < 1) {
      this.paginator.current = 1;
    }
    this._localizationService.getArrayFromKey('pageRows.template.options').forEach(key => {
      this.templateConfig.options.push(new SelectOption(`pageRows.template.options.${key}`, key));
    });
    this.templateConfig.control.setValue(cfg.size.toString());
    this.templateConfig.control.valueChanges.subscribe(() => {
      if (cfg.size.toString() !== this.templateConfig.control.value) {
        localStorage.setItem('pageRows', this.templateConfig.control.value);
        window.location.reload();
      }
    });
  }

  @Output()
  public changed: EventEmitter<PaginatorConfig> = new EventEmitter();

  constructor(
    private _localizationService: LocalizationService
  ) { }

  public changePage(page: number): void {
    this.paginator.current = page;
    this.changed.emit(this.paginator);
  }

}
