<app-content>
  <div class="hardware-provider-view">
    <app-headline icon="camera-account">{{ 'hardware-provider.headline' | translate }}</app-headline>
    <app-card>
      <div class="row">
        <app-autocomplete class="flex" required [config]="salutationConfig"></app-autocomplete>
        <div class="flex"></div>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="firstNameConfig"></app-input>
        <app-input class="flex" required [config]="lastNameConfig"></app-input>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="companyConfig"></app-input>
        <app-autocomplete class="flex" required [config]="companyLegalFormConfig"></app-autocomplete>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="addressConfig"></app-input>
      </div>
      <div class="row">
        <app-input class="flex" required [config]="zipConfig"></app-input>
        <app-input class="flex" required [config]="locationConfig"></app-input>
      </div>
      <div class="row">
        <app-input class="flex" required readonly [config]="emailConfig"></app-input>
        <app-input class="flex" [config]="phone1Config"></app-input>
      </div>
      <div *ngIf="isAdmin" class="row employee-view__admin">
        <app-checkbox [config]="adminConfig"></app-checkbox>
      </div>
      <mat-error *ngIf="updateError !== ''">{{ updateError | translate }}</mat-error>
      <div class="actions">
        <i class="flex">{{ 'required-fields' | translate }}</i>
        <app-button secondary (action)="back()">{{ 'button.back' | translate }}</app-button>
        <app-button primary [disabled]="locked" (action)="update()">{{ 'button.applyChanges' | translate }}</app-button>
      </div>
    </app-card>
  </div>
</app-content>
